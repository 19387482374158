import React, { useState, useEffect } from "react";
import {
  Input,
  Dropdown,
  Button,
  Space,
  Table,
  Tag,
  Image,
  Flex,
  Skeleton,
} from "antd";
import { DownOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import nodata from "../../assets/images/nodatacontact.png";
// import { AiOutlineCheckCircle, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import MyContactsDrawer from "./MyContactsDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../../store/actions/contacts";
import FileUploadModal from "./FileUploadModal";
import { getAdditionalInfo } from "../../store/actions/additionalinfo";
import EditContactDrawer from "./EditContactDrawer";
import config from "../../config";
import axios from "axios";
import { getAddress } from "../../store/actions/address";
import { getSpouse } from "../../store/actions/spouse";
// import { selectCalendarEvents, selectEmails, selectContacts } from '../../store/reducers/allAccountDetailsByGoogle';
const Contacts = () => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.list);
  const Address = useSelector((state) => state.address.list);
  const AdditionalInfo = useSelector((state) => state.additionalinfo.list);
  const Spouse = useSelector((state) => state.spouse.list);

  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [loadingAdditionalInfo, setLoadingAdditionalInfo] = useState(false); // Unique loading state
  const [errorFetchingAdditionalInfo, setErrorFetchingAdditionalInfo] =
    useState(null); // Unique error state

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contactEditDrawerOpen, setContactEditDrawerOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const additionalInfo = useSelector((state) => state.fetchAdditionalInfo.list);
  const [searchValue, setSearchValue] = useState(""); // For search input
  const [filterStatus, setFilterStatus] = useState("All"); // For dropdown filter

  // Accessing the specific parts of the state

  useEffect(() => {
    const fetchAdditionalInfo = async () => {
      try {
        await dispatch(getAdditionalInfo());
      } catch (err) {
        setLoading(false);
        setError("Failed to fetch additional info.");
      }
    };

    fetchAdditionalInfo();
  }, [dispatch]);

  const getAdditionalInfoData = async () => {
    try {
      const token = localStorage.getItem("Afb");

      const res = await axios.get(`${config.API_URL}/additionalinfo/`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      // Make the API request with the token
      // const res = await axios.get("contacts", config);
      // console.log("additional info data:", res.data);
    } catch (error) {
      console.error("Error fetching data:", error.message); // Log any errors
      // Optionally, handle the error (e.g., display an error message in the UI)
    }
  };

  // Merging data
  const mergedData = contacts.map((record) => {
    const additionalRecord = AdditionalInfo.find(
      (item) => item.contact === record.id
    );

    const locationRecord = Address.find((item) => item.contact === record.id);

    return {
      ...record,
      status: additionalRecord ? additionalRecord.status : "Prospective",
      location: locationRecord ? locationRecord.location : "-",
    };
  });

  // Filter tasks based on search input and dropdown
  const filteredTasks = mergedData.filter((task) => {
    const matchesSearch =
      task.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      task.email?.toLowerCase().includes(searchValue.toLowerCase());

    const matchesStatus =
      filterStatus === "All" || task.status === filterStatus;

    return matchesSearch && matchesStatus;
  });

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setFilterStatus(e.key);
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        await dispatch(getContacts());
        dispatch(getAdditionalInfo());
        dispatch(getAddress());
        dispatch(getSpouse());
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to fetch contacts.");
      }
    };

    fetchContacts();
    getAdditionalInfoData();
  }, [dispatch]);

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const items = [
    { label: "Active", key: "1", icon: <UserOutlined /> },
    { label: "Inactive", key: "2", icon: <UserOutlined /> },
    { label: "Prospective", key: "3", icon: <UserOutlined /> },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleEditContact = (record) => {
    setSelectedContact(record);
    setContactEditDrawerOpen(true);
  };

  const columns = [
    {
      title: "Contact Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <span
          style={{ textDecoration: "underline" }}
          onClick={() => handleEditContact(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return text ? text : "-";
      },
    },
    {
      title: "Phone",
      dataIndex: "home_phone",
      key: "home_phone",
      render: (text) => {
        if (text === null || text === "")
          return "-";

        if (text[0] === "+")
          return text.split(" ").join("");

        return `+1${text}`
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const color =
          text === "Inactive"
            ? "red"
            : text === "Active"
            ? "green"
            : text === "Prospective"
            ? "gold"
            : "default";

        return (
          <Tag
            style={{
              borderRadius: "2vh",
              border: "none",
              fontWeight: "bold",
              padding: "5px 15px",
              fontSize: "13px",
            }}
            color={color}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Ranking",
      dataIndex: "ranking",
      key: "ranking",
      render: (text) => {
        const color = text === "Follow-up" ? "red" : "#F3F3F3";
        return (
          <Tag
            style={{
              borderRadius: "100vh",
              color: "black",
              padding: "6px 12px",
              fontWeight: "bold",
              fontSize: "13px",
            }}
            color="#F3F3F3"
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "State",
      dataIndex: "location",
      key: "location",
      render: (text) => {
        return text ? text : "-";
      },
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: "createddate",
      render: (date) => {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const formattedDate = new Date(date).toLocaleDateString(
          "en-US",
          options
        );
        return formattedDate.split("/").reverse().join("."); // Reorder to MM.DD.YYYY
      },
    },
    {
      title: "Last Modified",
      dataIndex: "last_interaction",
      key: "last_interaction",
      render: (date) => {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const formattedDate = new Date(date).toLocaleDateString(
          "en-US",
          options
        );
        return formattedDate.split("/").reverse().join("."); // Reorder to MM.DD.YYYY
      },
    },
  ];

  return (
    <div>
      <Flex justify="space-between" align="flex-start">
        <div>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search Contacts"
            style={{ width: "320px" }}
            value={searchValue}
            onChange={handleSearchChange} // Capture search input changes
          />
          <Dropdown
            menu={{
              items: [
                { label: "All", key: "All" },
                { label: "Active", key: "Active" },
                { label: "Inactive", key: "Inactive" },
                { label: "Prospective", key: "Prospective" },
                // { label: "Overdue", key: "Overdue" },
              ],
              onClick: handleDropdownChange, // Handle dropdown changes
            }}
          >
            <Button style={{ marginLeft: 10 }}>
              <Space>
                {`${filterStatus}`}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div>
          <Button
            type="primary"
            ghost
            style={{ marginRight: 10 }}
            onClick={() => setModalVisible(true)}
          >
            Upload Contact
          </Button>
          <Button type="primary" onClick={() => setDrawerOpen(true)}>
            Create Contact
          </Button>
        </div>
      </Flex>

      {loading ? (
        <Skeleton />
      ) : error ? (
        <div>Something went wrong</div>
      ) : filteredTasks.length == 0 ? (
        <Flex justify="center" align="center" style={{ height: "80vh" }}>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>You haven’t added any contacts yet!</strong>
            </div>
            <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
          </div>
        </Flex>
      ) : (
        <Table
          style={{ cursor: "pointer" }}
          className="mt-4"
          dataSource={filteredTasks}
          columns={columns}
          rowKey="id"
          bordered
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                // Call a function and pass the record value
                handleEditContact(record);
              },
            };
          }}
        />
      )}
      <MyContactsDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      <EditContactDrawer
        open={contactEditDrawerOpen}
        onClose={() => setContactEditDrawerOpen(false)}
        selectedContact={selectedContact}
      />
      <FileUploadModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default Contacts;
