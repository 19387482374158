import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Checkbox,
  Flex,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import logolight from "../../assets/images/realestatecrm.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "../../App";
import config from "../../config";
import { sendEmailVerification, updateProfile } from "firebase/auth";

const { Title, Text } = Typography;

const Register = () => {
  document.title = "Register | ProperlyAI";
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+1"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number input value
  const { Option } = Select;

  const selectBefore = (
    <Select
      defaultValue={countryCode}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode(value)} // Update country code
    >
      <Option value="+1">+1</Option>
      <Option value="+91">+91</Option>
      <Option value="+44">+44</Option>
      <Option value="+61">+61</Option>
      <Option value="+81">+81</Option>
      <Option value="+49">+49</Option>
      <Option value="+33">+33</Option>
      <Option value="+39">+39</Option>
      <Option value="+86">+86</Option>
      <Option value="+82">+82</Option>
      <Option value="+55">+55</Option>
      <Option value="+34">+34</Option>
      <Option value="+7">+7</Option>
      <Option value="+27">+27</Option>
      <Option value="+64">+64</Option>
      <Option value="+65">+65</Option>
      <Option value="+60">+60</Option>
      <Option value="+971">+971</Option>
      <Option value="+62">+62</Option>
      <Option value="+98">+98</Option>
      <Option value="+52">+52</Option>
      <Option value="+20">+20</Option>
      {/* Add more country codes as needed */}
    </Select>
  );

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please input your first name!"),
      last_name: Yup.string().required("Please input your last name!"),
      email: Yup.string()
        .email("The input is not valid E-mail!")
        .required("Please input your email!"),
      phoneNumber: Yup.string().required("Please input your phone number!"),
      password: Yup.string().required("Please input your password!"),
    }),
    onSubmit: async (values) => {
      try {
        // Register user with Firebase
        // console.log(values.phoneNumber);
        // console.log(countryCode);
        const FinalPhone = `${countryCode}${values.phoneNumber}`;
        // console.log(`phoneNumber ---->`, FinalPhone);
        const { email, password, first_name, last_name, phoneNumber } = values;

        const userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        const idToken = await user.getIdToken();

        await sendEmailVerification(user);
        await updateProfile(user, {
          displayName: first_name || "User", // Set 'displayName' or a default value
        });

        // Save additional user information to Firestore REPLACE THE COLLECTION NAME

        // await firebase.firestore().collection("users").doc(user.uid).set({
        //   firstName: first_name,
        //   lastName: last_name,
        //   phoneNumber: phoneNumber,
        //   uid: userCredential.user.uid,
        //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // });

        // Send the token to your backend API
        // const response = await fetch(`${config.API_URL}/api/user/register/`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({ ...values, idToken }),
        // });

        toast.success(
          "Registration successful! A verification email has been sent to your email address."
        );
        // localStorage.setItem("idtoken", idToken);
        setTimeout(() => navigate("/login"), 1500);

        // if (response.ok) {
        //   toast.success(
        //     "Registration successful! A verification email has been sent to your email address."
        //   );
        //   // localStorage.setItem("idtoken", idToken);
        //   setTimeout(() => navigate("/login"), 1500); // Redirect after showing the toast
        // } else {
        //   const errorData = await response.json();
        //   toast.error(
        //     `Registration failed: ${errorData.detail || "Unknown error"}`
        //   );
        // }
      } catch (error) {
        toast.error("An error occurred: " + error.message);
      }
    },
  });

  return (
    <>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "#183244", height: "100%" }}>
            <Image src={logolight} preview={false} />
            <Text
              style={{
                backgroundColor: "#183244",
                fontSize: "12px",
                marginBottom: "4px",
                marginLeft: 60,
                paddingLeft: 60,
                paddingRight: 100,
                color: "white",
              }}
            >
              To know more, contact us at support@properlyai.co
            </Text>
          </div>
        </Col>
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div style={styles.container}>
              <div style={styles.formContainer}>
                <Title level={2}>Register Account</Title>
                <Text color="#667085">Register now to get 15 days trial.</Text>
                <Form
                  layout="vertical"
                  style={styles.form}
                  onFinish={formik.handleSubmit}
                >
                  <Form.Item
                    label="First Name"
                    help={
                      formik.errors.first_name && formik.touched.first_name
                        ? formik.errors.first_name
                        : null
                    }
                  >
                    <Input
                      name="first_name"
                      placeholder="Enter your first name"
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    help={
                      formik.errors.last_name && formik.touched.last_name
                        ? formik.errors.last_name
                        : null
                    }
                  >
                    <Input
                      name="last_name"
                      placeholder="Enter your last name"
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    help={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : null
                    }
                  >
                    <Input
                      name="email"
                      placeholder="Enter your email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Phone Number"
                    help={
                      formik.errors.phoneNumber && formik.touched.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  >
                    <Input
                      addonBefore={selectBefore}
                      placeholder="Enter your phone number"
                      type="text" // Keep it as text to allow flexibility
                      value={formik.values.phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Allow only numbers
                        if (/^\d*$/.test(value) || value === "") {
                          formik.setFieldValue("phoneNumber", value);
                        }
                      }}
                      onKeyPress={(e) => {
                        // Prevent entering anything that's not a number
                        if (!/\d/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    help={
                      formik.errors.password && formik.touched.password
                        ? formik.errors.password
                        : null
                    }
                  >
                    <Input.Password
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Flex justify="space-between" align="center">
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox>Remember for 30 days</Checkbox>
                      </Form.Item>
                      <a href="forgotpassword" style={{ color: "#1A4866" }}>
                        Forgot password?
                      </a>
                    </Flex>
                  </Form.Item> */}

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      style={{
                        width: "100%",
                        background: "#101828",
                        color: "white",
                      }}
                    >
                      Register
                    </Button>
                  </Form.Item>
                </Form>
                <Row justify="center" align="middle">
                  <Col>
                    <Row gutter={8}>
                      <Col>
                        <Text style={{ color: "#1A4866" }}>
                          Already have an account?
                        </Text>
                      </Col>
                      <Col>
                        <strong>
                          <a href="/login" style={{ color: "#1A4866" }}>
                            Login
                          </a>
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    padding: "20px",
  },
  formContainer: {
    maxWidth: "400px",
    width: "100%",
    padding: "20px",
    paddingTop: "35%",
  },
  form: {
    maxWidth: "100%",
    paddingTop: "10%",
  },
};

export default Register;
