import { ADD_EMAIL, DELETE_EMAIL, GET_EMAIL, UPDATE_EMAIL } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAIL:
      return { ...state, list: payload };

    case UPDATE_EMAIL:
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case ADD_EMAIL:
      return {
        ...state,
        list: [payload, ...state.list],
      };

    case DELETE_EMAIL:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== payload.id),
      };

    default:
      return state;
  }
};

export default reducer;
