import axios from "axios";
import config from "../config";

// Set default base URL and content type for axios
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Intercept response to capture errors and manage response data
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    let message;
    // Use error.response.status to access the HTTP status code
    switch (error.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! The data you are looking for could not be found";
        break;
      default:
        message = error.message || "An unknown error occurred";
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization token
 * @param {*} token
 */
const setAuthorization = (token) => {
  // Store the token in localStorage and set axios default headers
  localStorage.setItem("Afb", JSON.stringify({ token }));
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, { params });
  };

  /**
   * Post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete data
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("Afb");
  if (!user) {
    return null;
  }
  try {
    return { user };
  } catch (e) {
    console.error("Failed to parse auth data:", e);
    return null;
  }
};

export { APIClient, setAuthorization, getLoggedinUser };

// import axios from "axios";
// import config from "../config";

// // default
// axios.defaults.baseURL = config.API_URL;

// // content type
// axios.defaults.headers.post["Content-Type"] = "application/json";

// // intercepting to capture errors
// axios.interceptors.response.use(
//   function (response) {
//     return response.data ? response.data : response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     let message;
//     switch (error.status) {
//       case 500:
//         message = "Internal Server Error";
//         break;
//       case 401:
//         message = "Invalid credentials";
//         break;
//       case 404:
//         message = "Sorry! the data you are looking for could not be found";
//         break;
//       default:
//         message = error.message || error;
//     }
//     return Promise.reject(message);
//   }
// );

// /**
//  * Sets the default authorization
//  * @param {*} token
//  */
// const setAuthorization = (token) => {
//   // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//   localStorage.getItem('auth', token);
//   setAuthorization(token);
// };

// class APIClient {
//   /**
//    * Fetches data from given url
//    */
//   get = (url, params) => {
//     return axios.get(url, params);
//   };

//   /**
//    * post given data to url
//    */
//   create = (url, data) => {
//     return axios.post(url, data);
//   };

//   /**
//    * Updates data
//    */
//   update = (url, data) => {
//     return axios.put(url, data);
//   };

//   /**
//    * Delete
//    */
//   delete = (url, config) => {
//     return axios.delete(url, { ...config });
//   };
// }

// const getLoggedinUser = () => {
//   const user = localStorage.getItem("Afb");
//   if (!user) {
//     return null;
//   } else {
//     return JSON.parse(user);
//   }
// };

// export { APIClient, setAuthorization, getLoggedinUser };
