import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Calendar
import calendar from "./calendar/reducer";
// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";

//Items
import item from "./reducers/item";
import salesman from "./reducers/salesman";
import visit from "./reducers/visit";
import contacts from "./reducers/contacts";
import address from "./reducers/address";
import additionalinfo from "./reducers/additionalinfo";
import spouse from "./reducers/spouse";
import emails from "./reducers/emails";
import meetings from "./reducers/meetings";
import tasks from "./reducers/tasks";
import emailtemp from "./reducers/emailtemp";
import emailfolder from "./reducers/emailfolder";
import googleAccount from "./reducers/googleAccount";
import notes from "./reducers/notes";
import {dataReducer} from "./reducers/allAccountDetailsByGoogle";
const rootReducer = combineReducers({
  // public
  Layout,
  calendar,
  forgetPassword,
  login,
  profile,
  account,
  item,
  salesman,
  visit,
  contacts,
  address,
  additionalinfo,
  spouse,
  emails,
  meetings,
  tasks,
  emailtemp,
  emailfolder,
  googleAccount,
  notes,
  dataReducer,
});

export default rootReducer;
