import React, { useEffect, useState } from "react";
import { Button, Table, Card, message, Skeleton } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { getGoogle } from "../../store/actions/googleAccount";
import axios from "../../axios";
// import { auth } from "../../App";
// import { updateTokensOnBackend } from "../../utils/auth";
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Connect from "../../components/Connect";
// import { useNavigate } from "react-router-dom";
const EmailManagement = () => {
  // const dispatch = useDispatch();
  const [connect, setConnect] = useState(false);

  const useremail = localStorage.getItem("useremail");
  const [error, setError] = useState("");
  const [isSynced, setIsSynced] = useState(false);
  const [gapiToken, setGapiToken] = useState(null);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const [googleToken, setGoogleToken] = useState(localStorage.getItem("Gp_TK"));

  useEffect(() => {
    // useNavigate("/communications");
  }, [googleToken]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const checkSyncStatus = async () => {
  //     try {
  //       const response = await axios.get("/user_sync_status/", {
  //         withCredentials: true,
  //       });
  //       setConnect(response.data.synced);
  //     } catch (error) {
  //       console.error("Error checking sync status:", error);
  //     }
  //   };

  //   checkSyncStatus();
  // }, []);

  // const handleConnect = async () => {
  //   try {
  //     const provider = new GoogleAuthProvider();
  //     provider.addScope("https://www.googleapis.com/auth/gmail.readonly");
  //     provider.setCustomParameters({
  //       access_type: "offline",
  //       prompt: "consent",
  //     });

  //     dispatch(getGoogle());

  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     const token = await user.getIdToken();
  //     const accessToken = result._tokenResponse?.accessToken;
  //     const refreshToken = result._tokenResponse?.refreshToken;
  //     const oauthEmail = user.email;

  //     const data = await updateTokensOnBackend(
  //       accessToken,
  //       refreshToken,
  //       token,
  //       oauthEmail
  //     );

  //     if (data.status === "success") {
  //       setConnect(true);
  //       alert(`Account connected successfully.`);
  //     } else {
  //       alert(`Error: ${data.message}`);
  //     }
  //   } catch (error) {
  //     alert("Error: " + error.message);
  //   }
  // };

  //   useEffect(() => {
  //     const checkSyncStatus = async () => {
  //         try {
  //             const response = await axios.get('/user_sync_status/', { withCredentials: true });
  //             setConnect(response.data.synced);
  //         } catch (error) {
  //             console.error("Error checking sync status:", error);
  //         }
  //     };

  //     checkSyncStatus();
  // }, []);

  // const handleConnect = async () => {
  //     try {
  //         const provider = new auth.GoogleAuthProvider();
  //         provider.addScope('https://www.googleapis.com/auth/gmail.readonly');
  //         provider.setCustomParameters({
  //             access_type: 'offline',
  //             prompt: 'consent',
  //         });

  //         dispatch(getGoogle());

  //         const result = await auth.signInWithPopup(provider);
  //         const user = result.user;
  //         const token = await user.getIdToken();
  //         const accessToken = result._tokenResponse?.accessToken; // This might be undefined in newer SDKs
  //         const refreshToken = result._tokenResponse?.refreshToken;  // Note: This might not be available
  //         const oauthEmail = user.email;

  //         const data = await updateTokensOnBackend(accessToken, refreshToken, token, oauthEmail);

  //         if (data.status === 'success') {
  //             setConnect(true);
  //             alert(`Account connected successfully.`);
  //         } else {
  //             alert(`Error: ${data.message}`);
  //         }
  //     } catch (error) {
  //         alert("Error: " + error.message);
  //     }
  // };

  // const handleGoogleConnect = async () => {
  //   try {
  //     await dispatch(getGoogle());
  //   } catch (error) {
  //     console.error("Failed to connect Google account", error);
  //   }
  // };

  const revokeToken = async (token) => {
    try {
      console.log("token for logout: ", token);
      const response = await fetch(
        `https://oauth2.googleapis.com/revoke?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      try {
        localStorage.removeItem("Gp_TK");
      } catch (error) {
        console.log(error);
      }
      if (response.ok) {
        localStorage.removeItem("Gp_TK");
        console.log("Token revoked successfully.");
        message.info("Google account is disconnected.");
        navigate("/");

        setIsSynced(false);
      } else {
        console.error("Failed to revoke token:", response.statusText);
      }
      navigate("/");
    } catch (error) {
      console.error("Error during token revocation:", error);
    }
  };

  useEffect(() => {
    setGoogleToken(localStorage.getItem("Gp_TK"));
  }, [revokeToken]);

  const handleLogout = async (token) => {
    try {
      revokeToken(token);
      console.log("token sent for logout:", token);
    } catch {
      console.log(error);
    }
  };

  const dataSource = [
    {
      key: "1",
      email: useremail,
      syncemail: "right",
      synccal: "right",
      synccontact: "right",
    },
  ];

  const columns = [
    {
      title: "Email Account",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Sync Emails",
      dataIndex: "syncemail",
      key: "syncemail",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Sync Calendar",
      dataIndex: "synccal",
      key: "synccal",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Sync Contact",
      dataIndex: "synccontact",
      key: "synccontact",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Disconnect",
      dataIndex: "disconnect",
      key: "disconnect",
      render: (text, record) => (
        <Button
          type="default"
          style={{ backgroundColor: "black", color: "white" }}
          onClick={() => handleLogout(googleToken)}
        >
          Disconnect
        </Button>
      ),
    },
  ];

  return (
    <div>
      {/* Conditionally render centered content or table */}
      {/* <Button
        type="primary"
        className="mt-4"
        onClick={connect ? null : handleConnect}
      >
        {connect ? "Synced" : "Connect"} Your Google Account
      </Button> */}
      <Card bordered={false} className="mb-2">
        {googleToken ? (
          <Table
            bordered
            className="mt-4"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        ) : (
          <>
            {loadingGoogle ? (
              <Skeleton active />
            ) : (
              <Connect
                loading={loadingGoogle}
                setLoading={setLoadingGoogle}
                isSynced={isSynced}
                setIsSynced={setIsSynced}
                gapiToken={gapiToken}
                setGapiToken={setGapiToken}
              />
            )}
          </>
        )}
        {/* {isSynced ? (
          <Table bordered
            className="mt-4"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        ) : (
          <Connect
            isSynced={isSynced}
            setIsSynced={setIsSynced}
            gapiToken={gapiToken}
            setGapiToken={setGapiToken}
          />
        )} */}
      </Card>
      {/* <Table bordered className="mt-4" dataSource={dataSource} columns={columns} />; */}
    </div>
  );
};

export default EmailManagement;
