import axios from "../../axios";
import {login, logout} from "../../utils/auth";
import {AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT} from "../types";

export const authLogin = (data, history) => async (dispatch) => {
    try {
        const res = await axios.post('/login', data);

        if (res.data.code === 1) {
            let user = {access_token: res.data.access_token, refresh_token: res.data.refresh_token};
            login(user);
            dispatch({type: AUTH_LOGIN, payload: user});
            history("/dashboard")

        } else {
            dispatch({type: AUTH_ERROR, payload: res.data.message});
            // dispatch(setAlert(res.data.message, 'warning'));
        }
    } catch (err) {
        dispatch({type: AUTH_ERROR, payload: err.message});
        // dispatch(setAlert(err.message, 'danger'));
    }
};


// User Logout
export const authLogout = () => async (dispatch) => {
    try {
        logout();
        dispatch({type: AUTH_LOGOUT, payload: ''});
        // dispatch(setAlert('Logout done', 'success'));
    } catch (err) {
        dispatch({type: AUTH_ERROR, payload: err.message});
        // dispatch(setAlert(err.message, 'danger'));
    }
};
