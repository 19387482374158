import React from "react";
import { Collapse, Flex } from "antd";
import { FolderFilled, RightOutlined } from "@ant-design/icons";
import "./CollapseHeader.css"; 

const CustomCollapseHeader = ({ isActive, onClick, title }) => (
    <div className="custom-collapse-header" onClick={onClick}>
      
        <FolderFilled className="header-icon" />
        <span className="header-title">{title}</span>
      
      <div className="arrow-container" style={{right:15, position:"absolute"}}>
        <span className={`header-arrow ${isActive ? "active" : ""}`}><RightOutlined /></span>
      </div>
    </div>
  );

const CustomCollapse = (props) => (
  <Collapse
    {...props}
    expandIcon={({ isActive }) => (
      <CustomCollapseHeader isActive={isActive} title={props.title}  />
    )}
  />
);

export default CustomCollapse;
