import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoSm from "../../assets/images/Properly.png";
import logoDark from "../../assets/images/Properly.png";
import logoProperlyAI from "../../assets/figmaAssets/sidebarLogo.svg";
import logoLight from "../../assets/images/Properly.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "antd";
// import { Link } from "react-router-dom";
// import AppsDropdown from "../../components/Common/TopbarDropdown/AppsDropdown";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const auth = getAuth();

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Retrieve the displayName from the logged-in user
        setUserName(user.displayName || null);
      } else {
        // Handle if no user is logged in (redirect or show a message)
        console.log("No user is logged in");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const handleCompleteClick = () => {};
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div
              className="navbar-brand-box text-center"
              style={{ background: "#183244" }}
            >
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="28" />
                </span>
                <span className="logo-lg">
                  <img src={logoProperlyAI} alt="logo-dark" height="40" />
                </span>
              </Link>

              <Link
                to="/"
                className="logo logo-light"
                style={{ background: "#343a40" }}
              >
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-light" height="28" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="logo-light" height="30" />
                </span>
              </Link>
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button> */}

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="ri-search-line"></span>
              </div>
            </form> */}
            {userName !== null ? (
              <div style={{ marginTop: 25, marginLeft: 20 }} className="ml-2">
                Welcome back, {userName}
              </div>
            ) : (
              <div style={{ marginTop: 25, marginLeft: 20 }} className="ml-2">
                Complete your profile to unlock personalized features!
                <Button
                  style={{ marginLeft: "14px" }}
                  onClick={handleCompleteClick}
                >
                  <Link to={"/userprofile"}>Go to profile setup</Link>
                </Button>
              </div>
            )}
          </div>
          {/* Report a Bug Text */}
          <div style={{ marginLeft: 'auto'}}>
          <a 
            href="https://forms.gle/sy1CNUvruMN9bLra8" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{
              color: '#B8B8B8', // Light gray color
              textDecoration: 'none', // No underline
              cursor: 'pointer', // Pointer cursor on hover
              transition: 'color 0.3s', // Smooth color transition
              fontWeight: 'normal', // Normal weight
            }}
            onMouseOver={(e) => e.currentTarget.style.color = '#a9a9a9'} // Slightly darker gray on hover
            onMouseOut={(e) => e.currentTarget.style.color = 'B8B8B8'} // Back to original color
          >
            Report a Bug
          </a>
        </div>

          <div className="d-flex">
          
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="ri-search-line" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="ri-search-line" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {/*<LanguageDropdown />*/}
            {/*<AppsDropdown />*/}

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line" />
              </button>
            </div>

            <NotificationDropdown /> */}
            {/* Report a Bug Text */}
        
            <ProfileMenu />
            

            {/* <div
              className="dropdown d-inline-block"
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog"></i>
              </button>
            </div> */}
          </div>
          
        </div>
        
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
