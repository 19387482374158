import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  FolderFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import {
  Button,
  Col,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
  message,
  Typography,
} from "antd";
import { addTask, getTasks } from "../../store/actions/tasks"; // Import the action creator
import moment from "moment"; // Import moment.js for date and time manipulation
import CustomCollapse from "./CustomCollapse";
import left from "../../assets/figmaAssets/left.svg";

import { getEmailFolders } from "../../store/actions/emailfolder";
import axios from "axios";
import Richtext from "../../components/Richtext";
// import { Text } from "ckeditor5";
import { getAuth } from "firebase/auth";
import {
  addEmailTemp,
  getEmailTemps,
  updateEmailTemps,
} from "../../store/actions/emailtemp";

const { Text } = Typography;

const ViewTemplateDrawer = ({
  open,
  onClose,
  templateInfo,
  setTemplateInfo,
  selectedFolder,
  setSelectedFolder,
  useTemplate,
  setUseTemplate,
}) => {
  const templateDetail = templateInfo || null;
  const folder = selectedFolder || null;
  // console.log("template detail from view: ", templateDetail);
  // console.log("template folder from view: ", folder);
  const dispatch = useDispatch(); // Initialize the dispatch function
  const emailFolder = useSelector((state) => state.emailfolder.list);
  const [options, setOptions] = useState([]);
  // console.log("template info body --> ", templateInfo.body);
  const [bodyText, setBodyText] = useState(templateInfo.body || "");
  const [form] = Form.useForm();

  useEffect(() => {
    if (templateDetail) {
      form.setFieldsValue({
        name: templateDetail.name,
        subject: templateDetail.subject,
        body: templateDetail.body,
      });
      setBodyText(templateDetail.body);
      // console.log("=>", templateDetail.body);
      // console.log("=>", bodyText);
    }
  }, [templateDetail, form]);

  // const options = [];
  useEffect(() => {
    // Populate the options array based on the contacts list
    if (emailFolder && emailFolder.length > 0) {
      const folderOptions = emailFolder.map((contact) => ({
        value: contact.id, // Use email as the value for the options
        label: contact.name,
        // label: `${contact.firstName} ${contact.lastName} (${contact.email})`, // Display name and email
      }));
      setOptions(folderOptions);
    }
  }, [emailFolder]); // Update options whenever contacts change

  const [email, setEmail] = useState("");
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);

  const { Option } = Select;

  const googleToken = localStorage.getItem("Gp_TK");
  const token = localStorage.getItem("Afb");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toEmail, setToEmail] = useState(null);

  const [size, setSize] = useState("Default");
  const handleChange = (value) => {
    // console.log(`Selected: ${value}`);
  };

  // Function to check Google token availability
  const checkGoogleToken = () => {
    if (!googleToken) {
      message.info("Connect with your Google Account to send emails");
      return false;
    }
    return true;
  };
  const handleInputChange = () => {
    return;
  };

  const handleUseTemplate = (templateData) => {
    // console.log("Use this template", templateData);
    setUseTemplate(templateData);
    onClose();
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        folder: values.folderTemp,
        name: values.name,
        subject: values.subject,
        body: bodyText ? bodyText : "Updated",
      };
      // console.log("Email-template payload:", payload);
      await dispatch(updateEmailTemps(payload, templateDetail.id)); // Dispatch the action with the formatted payload
      await dispatch(getEmailTemps());

      form.resetFields();
      message.success("Template Updated Successfully!");
      // setSelectedFolder(null);
      // setTemplateInfo(null);
      onClose();
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleBCC = () => {
    setBCC(!bcc);
  };

  const handleCC = () => {
    setCC(!cc);
  };
  const auth = getAuth();

  useEffect(() => {
    // Get the currently logged-in user from Firebase
    const user = auth.currentUser;

    if (user) {
      // User is logged in, set the email
      setEmail(user.email);
    } else {
      // No user is logged in, handle accordingly
      console.log("No user is logged in");
    }
  }, []);

  const checkGmailAuth = () => {
    message.info("Connect with Google via Settings page to send emails");
  };

  useEffect(() => {
    const token = localStorage.getItem("Gp_TK");
    setIsButtonDisabled(!token);
  }, []);

  useEffect(() => {
    const fetchEmailFolder = async () => {
      await dispatch(getEmailFolders());
    };
    fetchEmailFolder();
  }, [dispatch]);

  useEffect(() => {
    if (templateInfo) {
      form.setFieldsValue({
        name: templateDetail.name,
        from: email,
        to: "",
        // folderTemp: folder.name,
        subject: templateDetail.subject,
        body: bodyText,

        // Set other fields here as needed
      });
    }
  }, [templateInfo, form]);

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title={`Edit Template - ${templateInfo.name}`}
      width={720}
      // onClose={onClose}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        </>
      }
    >
      <Form
        name="emailForm"
        onFinish={onFinish}
        form={form}
        layout="vertical" // Vertical layout for labels above fields
        style={{ maxWidth: "100%" }} // Ensure the form takes the full width
      >
        {/* From Field */}
        <Form.Item
          label="Name"
          name="name"
          initialValue=""
          rules={[
            {
              message: "Please enter the name of template!",
            },
          ]}
        >
          <Input placeholder={"Name the template"} />
        </Form.Item>

        {/* To Field */}
        {/* <Form.Item
            label="To"
            name="to"
            // style={{ marginBottom: 20 }}
            onChange={handleInputChange}
            rules={[
              {
                required: true,
                message: "Please enter the recipient's email address!",
              },
            ]}
          >
            <Input placeholder="Enter recipient's email address" />
          </Form.Item> */}

        <Form.Item
          name="folderTemp"
          label="Template Folder"
          rules={[
            {
              required: true,
              message: "Select the template folder!",
            },
          ]}
        >
          <Select
            size={size}
            placeholder="Add or Select the template folder"
            // defaultValue="a1"
            defaultValue={selectedFolder?.name}
            onChange={handleChange}
            style={{
              width: 200,
            }}
            options={options}
          />
        </Form.Item>

        {/* Subject Field */}
        <Form.Item
          label="Subject"
          name="subject"
          onChange={handleInputChange}
          rules={[
            {
              required: true,
              message: "Please enter the subject of the email!",
            },
          ]}
        >
          <Input placeholder="Enter the subject of the email" />
        </Form.Item>

        {/* Email Body Field */}
        <Form.Item
          label="Email Body"
          name="body"
          // rules={[
          //   {
          //     required: true,
          //    message: "Please enter the body of the email!",
          //   },
          // ]}
        >
          <Richtext
            bodyText={bodyText}
            setBodyText={setBodyText}
            toEmail={toEmail}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item
          style={{
            marginTop: "20px",
            textAlign: "right",
            // display: "flex",
            // flexDirection: "row",
          }}
        >
          <Button
            type="primary"
            // disabled={isButtonDisabled}
            htmlType="submit"
          >
            Update Template
          </Button>{" "}
          {/* <Button
            onClick={() => handleUseTemplate(templateDetail)}
            // disabled={isButtonDisabled}
            // onClick={checkGmailAuth}
            style={{ marginLeft: "10px" }}
          >
            Use Template
          </Button> */}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ViewTemplateDrawer;
