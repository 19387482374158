import home from "../../assets/figmaAssets/home.svg";
import scheduling from "../../assets/figmaAssets/scheduling.svg";
import contacts from "../../assets/figmaAssets/contacts.svg";
import contactus from "../../assets/figmaAssets/contactus.svg";
import notes from "../../assets/figmaAssets/notes.svg";
import settings from "../../assets/figmaAssets/settings.svg";
import mail from "../../assets/figmaAssets/mail.svg";

const SidebarData = [
  {
    label: "Dashboard",
    icon: home,
    url: "/dashboard",
    isHasArrow: true,
  },
  {
    label: "Communication",
    icon: mail,
    url: "/communication",
    isHasArrow: true,
  },
  {
    label: "Scheduling",
    icon: scheduling,
    url: "/scheduling",
    isHasArrow: true,
  },
  {
    label: "Contacts",
    icon: contacts,
    url: "/contacts",
    isHasArrow: true,
  },
  {
    label: "Notes",
    icon: notes,
    url: "/notes",
    isHasArrow: true,
  },
];

const SidebarDataBottom = [
  {
    label: "Contact Us",
    icon: contactus,
    url: "https://www.properlyai.co/#contact-us",
    isHasArrow: true,
  },
  {
    label: "Settings",
    icon: settings,
    url: "/settings",
    isHasArrow: true,
    className: "sidebar-settings",
  },
];

export { SidebarData, SidebarDataBottom };
