import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { refreshAccessToken, updateTokensOnBackend } from "./utils/auth";
import config from "./config";

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
});

// Function to get a CSRF token from cookies
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Set CSRF token
const csrfToken = getCookie("csrftoken");
if (csrfToken) {
  axiosInstance.defaults.headers.common["X-CSRFToken"] = csrfToken;
}

// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      // console.log("user :", user);
      // console.log(
      //   "idTokenResult.expirationTime :",
      //   idTokenResult.expirationTime
      // );

      if (Date.now() >= idTokenResult.expirationTime) {
        // Token has expired, refresh it
        const { newAccessToken, refreshToken } = await refreshAccessToken();
        await updateTokensOnBackend(
          newAccessToken,
          refreshToken,
          idTokenResult.token,
          user.email
        );
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
        config.headers["Authorization"] = `${newAccessToken}`;
        // console.log("token log idTokenResult.token 1 :", idTokenResult.token);
      } else {
        config.headers["Authorization"] = `Bearer ${idTokenResult.token}`;
        config.headers["Authorization"] = `${idTokenResult.token}`;
        // console.log("token log idTokenResult.token :", idTokenResult.token);
      }
    }

    // Ensure CSRF token is set for each request
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // if (error.response && error.response.status === 403) {
    if (error.response.status === 403) {
      // Redirect to login page
      // window.location.href = '/logout';
      // localStorage.removeItem('idtoken')
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

// import axios from 'axios';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import { refreshAccessToken, updateTokensOnBackend } from './utils/auth';
// import config from './config';

// const axiosInstance = axios.create({
//   baseURL: config.API_URL,
//   withCredentials: true
// });

// // Function to get a CSRF token from cookies
// function getCookie(name) {
//   let cookieValue = null;
//   if (document.cookie && document.cookie !== '') {
//     const cookies = document.cookie.split(';');
//     for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i].trim();
//       if (cookie.substring(0, name.length + 1) === (name + '=')) {
//         cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//         break;
//       }
//     }
//   }
//   return cookieValue;
// }

// // Set CSRF token
// const csrfToken = getCookie('csrftoken');
// if (csrfToken) {
//   axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;
// }

// // Request Interceptor
// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const user = firebase.auth().currentUser;
//     if (user) {
//       const idTokenResult = await user.getIdTokenResult();
//       console.log("user :", user);
//       console.log("idTokenResult.expirationTime :", idTokenResult.expirationTime);

//       if (Date.now() >= idTokenResult.expirationTime) {
//         // Token has expired, refresh it
//         const { newAccessToken, refreshToken } = await refreshAccessToken();
//         await updateTokensOnBackend(newAccessToken, refreshToken, idTokenResult.token, user.email);
//         config.headers['Authorization'] = `Bearer ${newAccessToken}`;
//         config.headers['Authorization'] = `${newAccessToken}`;
//         // console.log("token log idTokenResult.token 1 :", idTokenResult.token);
//       } else {
//         config.headers['Authorization'] = `Bearer ${idTokenResult.token}`;
//         config.headers['Authorization'] = `${idTokenResult.token}`;
//         // console.log("token log idTokenResult.token :", idTokenResult.token);

//       }
//     }

//     // Ensure CSRF token is set for each request
//     if (csrfToken) {
//       config.headers['X-CSRFToken'] = csrfToken;
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// // Response Interceptor
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     // if (error.response && error.response.status === 403) {
//     if (error.response.status === 403) {
//       // Redirect to login page
//       window.location.href = '/logout';
//       localStorage.removeItem('idtoken')
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

// import config from "./config";
// import axios from "axios";
// import { message } from "antd";

// const baseURL = config.API_URL;

// const axiosInstance = axios.create({
//     baseURL,
//     headers: {
//         "Content-Type": "application/json",
//     },
// });

// // Request interceptor
// axiosInstance.interceptors.request.use(
//     (config) => {
//         // Extract the token from localStorage
//         const token = JSON.parse(localStorage.getItem('auth'))?.access;

//         if (config.url.includes("token")) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         } else if (config.url.includes("token/refresh/")) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         } else if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }

//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// // Response interceptor
// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async (error) => {
//         const originalRequest = error.config;

//         // Handle token refresh if 401 Unauthorized
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;

//             if (originalRequest.url.includes("token/refresh/")) {
//                 // If refresh token fails, clear auth and reload
//                 localStorage.removeItem("auth");
//                 window.location.reload();
//                 return Promise.reject(error);
//             }

//             try {
//                 // Get refresh token from localStorage
//                 const refreshToken = JSON.parse(localStorage.getItem('auth'))?.refresh;

//                 // Request a new access token using the refresh token
//                 const res = await axiosInstance.post(`token/refresh/`, {
//                     refresh: refreshToken,
//                 });

//                 // Update the localStorage with the new access token
//                 const newAuth = {
//                     ...JSON.parse(localStorage.getItem('auth')),
//                     access: res.data.access
//                 };
//                 localStorage.setItem("Afb", JSON.stringify(newAuth));

//                 // Set the new access token in axios defaults
//                 axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${res.data.access}`;

//                 // Retry the original request with the new token
//                 return axiosInstance(originalRequest);
//             } catch (err) {
//                 // Handle errors from the refresh token request
//                 localStorage.removeItem("auth");
//                 window.location.reload();
//                 return Promise.reject(err);
//             }
//         }

//         // Show error message if response error is encountered
//         message.error(error.response?.data || 'An error occurred');
//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;

// import config from "./config";
// import axios from "axios";
// import {message} from "antd";
// const baseURL =config.API_URL;

// const axiosInstance = axios.create({
//     baseURL,
//     headers: {
//         "Content-Type": "application/json",
//     },
// });

// axiosInstance.interceptors.request.use(
//     (config) => {
//         if (["token",].includes(config.url)) {
//             config.headers["authorization"] = "Bearer essential";
//         } else if (["token/refresh/"].includes(config.url)) {
//             config.headers["authorization"] = "Bearer refresh";
//         } else {
//             const accessToken = JSON.parse(localStorage.getItem('auth'));
//             if (accessToken) {
//                 config.headers['authorization'] = `Bearer ${accessToken}`;
//             }
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (
//             error.response.status === 401 &&
//             originalRequest.url === `token/refresh/`
//         ) {
//             localStorage.removeItem("auth");
//             window.location.reload();
//             return Promise.reject(error);
//         }

//         // Access Token was expired
//         console.log({LL: originalRequest.url});
//         console.log(error.response);
//         message.error(error.response.data);
//         if (
//             error.response.status === 401 &&
//             !originalRequest._retry &&
//             originalRequest.url !== `api/token`
//         ) {
//             originalRequest._retry = true;
//             try {
//                 const refreshToken = JSON.parse(localStorage.getItem('auth'));
//                 const res = await axiosInstance.post(`refresh/access`, {
//                     token: refreshToken,
//                 });

//                 let auth = JSON.parse(localStorage.getItem('auth'));
//                 auth = {...auth, access: res.data.access};
//                 localStorage.setItem("Afb", JSON.stringify(auth));
//                 axiosInstance.defaults.headers.common["authorization"] = res.data.access;
//                 // axiosInstance.defaults.headers.common["x-access-token"] = res.data.access_token;

//                 return axiosInstance(originalRequest);
//             } catch (error) {
//                 return Promise.reject(error);
//             }
//         }
//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;
