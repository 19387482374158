import { ADD_CONTACTS, GET_CONTACTS } from "../types";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_CONTACTS:
            return {...state, list: payload};

        // case UPDATE_COMPANY:
        //     return {
        //         ...state,
        //         list: state.list.map((item) =>
        //             item.id === payload.id ? payload : item
        //         ),
        //     };

        case ADD_CONTACTS:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        // case DELETE_COMPANY:
        //     return {
        //         ...state,
        //         list: state.list.filter((item) => item.id !== payload.id),
        //     };

        default:
            return state;
    }
};

export default reducer;
