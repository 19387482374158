import React, { useState } from "react";
import { Modal, Button, Upload, message, Flex, Row } from "antd";
import { ArrowDownOutlined, UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import config from "../../config";
// import Papa from "papaparse";
import axios from "axios";
import { getContacts } from "../../store/actions/contacts";
import { useDispatch } from "react-redux";
import { getAdditionalInfo } from "../../store/actions/additionalinfo";
import { getAddress } from "../../store/actions/address";
import { getSpouse } from "../../store/actions/spouse";
import sampleCsv from "../../assets/ProperylAI Contact Sample CSV.csv";

const FileUploadModal = ({ visible, onClose }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Handle file change event
  const handleFileChange = (info) => {
    console.log(info);
    console.log(info.file.originFileObj);
    if (info.file.status !== "uploading") {
      setFile(info.file); // Store the file for uploading
    }
  };

  // Handle the file upload process
  const handleUpload = async () => {
    if (!file) {
      message.error("Please select a CSV file to upload!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Append the file to the form data

    const token = localStorage.getItem("Afb");

    try {
      setLoading(true);
      // Make the POST request to the backend API
      const response = await axios.post(
        `${config.API_URL}/upload-csv/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`, // Include the user's token if needed
          },
        }
      );

      // Handle successful upload
      message.success("File uploaded successfully");
      setFile(null);
      await dispatch(getContacts());
      dispatch(getAdditionalInfo());
      dispatch(getAddress());
      dispatch(getSpouse());
      onClose();
      setLoading(false);
    } catch (error) {
      // Handle errors
      if (error.response) {
        setFile(null);
        setLoading(false);
        message.error(`Upload failed: ${error.response.data.error}`);
      } else {
        setFile(null);
        message.error("Invalid file, Try again.");
        setLoading(false);
      }
    }
  };
  return (
    <Modal
      title="Upload Contact"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
    >
      <div style={{ color: "rgba(102, 112, 133, 1)", marginBottom: 18 }}>
        Upload your CSV file in this order (Name, Email, Phone, Ranking, State,
        City)
      </div>

      {/* Upload Component */}
      <Flex>
        <Upload
          className="m-2"
          beforeUpload={() => false} // Prevent automatic upload
          onChange={handleFileChange} // Call handleFileChange when a file is selected
          accept=".csv" // Restrict to .csv files
        >
          <Button icon={<UploadOutlined />}>Select CSV File</Button>
        </Upload>
        {file == null && (
          <Row style={{ marginTop: "8px" }}>
            <a href={sampleCsv} download="ProperylAI Contact Sample CSV.csv">
              <Button
                type="secondary"
                icon={<ArrowDownOutlined />}
                style={{ fontSize: "12px" }}
              >
                Download Sample CSV
              </Button>
            </a>
          </Row>
        )}
      </Flex>

      {/* <Flex>
        <Row>
          <Row icon={<ArrowDownOutlined />} style={{ fontSize: "12px" }}>
            Download Sample CSV
          </Row>
        </Row>
      </Flex> */}

      {/* Buttons for Cancel and Upload */}
      <Flex className="mt-2">
        <Button className="m-2" style={{ width: "90%" }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="m-2"
          style={{ width: "90%" }}
          type="primary"
          onClick={handleUpload}
          loading={loading} // Show loading spinner when uploading
        >
          Upload
        </Button>
      </Flex>
    </Modal>
  );
};

export default FileUploadModal;
