// Connect.js
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Flex, message } from "antd";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getGoogle } from "../store/actions/googleAccount";
import { updateTokensOnBackend, getAuthorizationURL } from "./../utils/auth";
// import config from "../config";
import axios from "../axios";
import { toast } from "react-toastify";
import { auth, googleAuthProvider } from "../App";
// import useGoogleApi from "../utils/useGoogleApi";
import { gapi } from "gapi-script";

import {
  setContacts,
  setEmails,
  setCalendarEvents,
} from "../store/actions/getGoogleDetails";
import { useNavigate } from "react-router-dom";
const Connect = ({
  isSynced,
  setIsSynced,
  gapiToken,
  setGapiToken,
  loading,
  setLoading,
}) => {
  const [connect, setConnect] = useState(false);
  const useremail = localStorage.getItem("useremail");
  const dispatch = useDispatch();
  // const googleAPI = useSelector((state) => state.googleAccount.list);
  const navigate = useNavigate();

  const [gapiLoaded, setGapiLoaded] = useState(false);
  const [authInstance, setAuthInstance] = useState(null);
  const [user, setUser] = useState(null);

  // Calendar Test

  const createCalendarEvent = async (accessToken, eventDetails) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        const event = await response.json();
        // console.log("Event created successfully:", event);
      } else {
        const error = await response.json();
        console.error("Failed to create event:", error);
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
    }
  };

  // Sample event details payload
  const eventDetails = {
    summary: "Meeting with Client",
    location: "1234 Main St, City, Country",
    description: "Discuss project updates and timelines.",
    start: {
      dateTime: "2024-09-15T09:00:00-07:00", // Format: YYYY-MM-DDTHH:MM:SS+HH:MM
      timeZone: "America/Los_Angeles",
    },
    end: {
      dateTime: "2024-09-15T10:00:00-07:00",
      timeZone: "America/Los_Angeles",
    },
    attendees: [
      { email: "attendee1@example.com" },
      { email: "attendee2@example.com" },
    ],
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 }, // 24 hours before
        { method: "popup", minutes: 10 }, // 10 minutes before
      ],
    },
  };

  const revokeToken = async (token) => {
    try {
      // console.log("token for logout: ", token);
      const response = await fetch(
        `https://oauth2.googleapis.com/revoke?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.ok) {
        console.log("Token revoked successfully.");
        setIsSynced(false);
        localStorage.removeItem("Gp_TK");
      } else {
        console.error("Failed to revoke token:", response.statusText);
      }
    } catch (error) {
      console.error("Error during token revocation:", error);
    }
  };

  // // Sign-in with Google
  // const signInWithGoogle = async () => {
  //     if (gapiLoaded && authInstance) {
  //         try {
  //             const googleUser = await authInstance.signIn();
  //             const profile = googleUser.getBasicProfile();
  //             setUser(profile);
  //         } catch (error) {
  //             console.error("Error signing in with Google: ", error);
  //         }
  //     }
  // };

  // Revoke access to Google account

  function fetchEmailById(messageId, token) {
    fetch(
      `https://www.googleapis.com/gmail/v1/users/me/messages/${messageId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((message) => {
        // Parse the email data (e.g., subject, from, to, body, etc.)
        const headers = message.payload.headers;
        const subject = headers.find(
          (header) => header.name === "Subject"
        )?.value;
        const from = headers.find((header) => header.name === "From")?.value;

        // console.log(`Email from: ${from}, Subject: ${subject}`);
      })
      .catch((error) => {
        console.error("Error fetching email by ID:", error);
      });
  }

  // Fetch Calendar Events
  const fetchCalendarEvents = async (token) => {
    // console.log(token);
    fetch("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // data.items contains the list of calendar events
        data.items.forEach((event) => {
          // console.log(
          //   `Event: ${event.summary}, Start: ${event.start.dateTime}, End: ${event.end.dateTime}`
          // );
          dispatch(
            setCalendarEvents(
              `Event: ${event.summary}, Start: ${event.start.dateTime}, End: ${event.end.dateTime}`
            )
          );
        });
      })
      .catch((error) => {
        console.error("Error fetching calendar events:", error);
      });
  };

  const fetchUnreadEmailCount = async (token) => {
    // try {
    //   const response = await fetch(
    //     "https://www.googleapis.com/gmail/v1/users/me/messages?q=is:unread",
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   // The number of unread emails is in the `resultSizeEstimate` field
    //   const unreadCount = data.resultSizeEstimate || 0;
    //   console.log(`Number of unread emails: ${unreadCount}`);
    //   return unreadCount;
    // } catch (error) {
    //   console.error("Error fetching unread email count:", error);
    // }

    let unreadCount = 0;
    let nextPageToken = null;

    try {
      do {
        // Construct the API URL with query parameters
        let url = `https://www.googleapis.com/gmail/v1/users/me/messages?q=is:unread&maxResults=500`;
        if (nextPageToken) {
          url += `&pageToken=${nextPageToken}`;
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        // Add the count of messages in the current response
        if (data.messages) {
          unreadCount += data.messages.length;
          dispatch(setEmails(unreadCount)); // Store emails in Redux
        }

        // Check if there's a next page
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);

      localStorage.setItem("Ur_GPt", unreadCount);

      // console.log("Total unread emails count:", unreadCount);
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  // Fetch Gmail Data
  const fetchGmailData = async (token) => {
    fetch("https://www.googleapis.com/gmail/v1/users/me/messages", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // data.messages contains the list of message IDs
        const messageIds = data.messages;
        // console.log(data);
        // console.log(messageIds);
        fetchUnreadEmailCount(token);
        dispatch(setContacts(data)); // Store contacts in Redux

        // Fetch each email by message ID

        // messageIds.forEach((message) => {
        //   fetchEmailById(message.id, token);
        // });
      })
      .catch((error) => {
        console.error("Error fetching emails:", error);
      });
  };

  // Fetch Contacts
  const fetchContacts = async (token) => {
    try {
      // You can now use this token to call the Google People API to access contacts.
      await fetch(
        "https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,phoneNumbers",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("Google Contacts:", data);
        })
        .catch((error) => {
          console.error("Error fetching contacts:", error);
        });
    } catch (error) {
      console.error("Error fetching contacts: ", error);
    }
  };

  useEffect(() => {
    // console.log("GAPI_CID:", process.env.REACT_APP_GAPI_CID);

    const loadGapi = () => {
      window.gapi.load("client:auth2", () => {
        if (!window.gapi.auth2.getAuthInstance()) {
          // Initialize only if auth instance does not already exist
          window.gapi.client
            .init({
              clientId: process.env.REACT_APP_GAPI_CID,
              scope:
                "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send",
              prompt: "consent", // Forces consent screen
              access_type: "offline", // Ensures a refresh token is provided
            })
            .then(() => {
              const auth = window.gapi.auth2.getAuthInstance();
              // console.log("auth auth auth auth auth auth auth auth auth auth auth");
              // console.log(auth);
              setAuthInstance(auth);
              setGapiLoaded(true);
            })
            .catch((error) => {
              console.error("Error during gapi initialization:", error);
            });
        } else {
          // If already initialized, just use the existing instance
          const auth = window.gapi.auth2.getAuthInstance();
          setAuthInstance(auth);
          setGapiLoaded(true);
        }
      });
    };

    loadGapi();
  }, []);

  const handleConnect = async () => {
    if (gapiLoaded && authInstance) {
      // try {
      //   console.log("grantOfflineAccess grantOfflineAccess")
      //   authInstance.grantOfflineAccess()
      //         .then((res) => {
      //             console.log(res);
      //             this.data.refreshToken = res.code;
      //         });
      //   console.log("grantOfflineAccess")

      //   // Sign in and request offline access
      //   const googleUser = await authInstance.signIn({
      //     scope:
      //       "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/gmail.send",
      //     prompt: "consent", // Forces consent screen
      //     access_type: "offline", // Ensures a refresh token is provided
      //     include_granted_scopes: true, // Ensures only newly requested scopes are prompted,
      //   });

      //   // authInstance.grantOfflineAccess()
      //   // .then((res) => {
      //   //   console.log("authInstance");
      //   //   console.log(res);
      //   // });

      //   const profile = googleUser.getBasicProfile();
      //   const authResponse = googleUser.getAuthResponse(true); // Get full auth response

      //   console.log("User Profile:", profile.getName(), profile.getEmail());
      //   console.log("Access Token:", authResponse.access_token);
      //   console.log("Google Data", authResponse); // Check for refresh token here

      //   // Retrieve the refresh token
      //   const refreshToken = authResponse.refresh_token;
      //   if (refreshToken) {
      //     console.log("Refresh Token:", refreshToken);
      //     localStorage.setItem("Gp_RT", refreshToken); // Store refresh token securely
      //   } else {
      //     console.warn("No refresh token received. Check your settings.");
      //   }

      //   if (useremail === profile.getEmail()) {
      //     setLoading(true);
      //     await fetchContacts(authResponse.access_token);
      //     await fetchGmailData(authResponse.access_token);
      //     await fetchCalendarEvents(authResponse.access_token);
      //     await updateTokensOnBackend(
      //       authResponse.access_token, refreshToken,
      //       authResponse.id_token, profile.getEmail()
      //     );

      //     setGapiToken(authResponse.access_token);
      //     setIsSynced(true);
      //     setLoading(false);

      //     const expiresIn = authResponse.expires_in; // In seconds
      //     const expirationTime = Date.now() + expiresIn * 1000; // Calculate expiration time in milliseconds

      //     // Store access token and expiration in local storage
      //     localStorage.setItem("Gp_TK", authResponse.access_token);
      //     localStorage.setItem("_GPlf", expirationTime.toString());

      //     message.success("Connected with Google successfully");
      //     navigate("/");
      //   } else {
      //     revokeToken(authResponse.access_token);
      //     localStorage.removeItem("Gp_TK");
      //     message.error(
      //       "The email address used for Google login does not match the one used for normal login."
      //     );
      //   }

      //   return;
      // } catch (error) {
      //   console.error("Error during Google sign-in:", error);
      // }


      const googleConnect = await getAuthorizationURL();

      // console.log(googleConnect);


      // const GOOGLE_CLIENT_ID = '957293407781-oasq8chbjnbubbospcgkbb3us8jgjccm.apps.googleusercontent.com'; // Replace with your Google client ID
      // const REDIRECT_URI = 'http://127.0.0.1:8000/google-connect/callback/'; // Change to your redirect URI
      // const SCOPE = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/gmail.send';
      
      // const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&access_type=offline&prompt=consent&access_type=offline`;
      // console.log(authUrl);

      // navigate(authUrl);
      window.location.href = googleConnect.authorization_url;
    }
  };

  return (
    <Flex justify="space-between" align="flex-start">
      <div>
        <h5>Email Management</h5>
        <div>
          Connect a Gmail and use it to send and receive emails. You can send up
          to 500 emails.
        </div>
        <div>
          <strong>{useremail}</strong>
        </div>
      </div>
      <div>
        <Button
          type="primary"
          // onClick={connect ? null : handleConnect}
          onClick={handleConnect}
          style={{ width: 85 }}
        >
          {connect ? "Synced" : "Connect"}
        </Button>
      </div>
    </Flex>
  );
};

export default Connect;
