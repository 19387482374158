import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  FolderFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import left from "../../assets/figmaAssets/left.svg";

import { FiSearch } from "react-icons/fi";
import {
  Button,
  Col,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
  message,
  Typography,
} from "antd";
import { addTask, getTasks } from "../../store/actions/tasks"; // Import the action creator
import moment from "moment"; // Import moment.js for date and time manipulation
import CustomCollapse from "./CustomCollapse";
import { getEmailFolders } from "../../store/actions/emailfolder";
import axios from "axios";
import Richtext from "../../components/Richtext";
// import { Text } from "ckeditor5";
import { getAuth } from "firebase/auth";
import { addEmailTemp, getEmailTemps } from "../../store/actions/emailtemp";

const { Text } = Typography;

const NewTemplateDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch(); // Initialize the dispatch function
  const emailFolder = useSelector((state) => state.emailfolder.list);
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();

  // const options = [];
  useEffect(() => {
    // Populate the options array based on the contacts list
    if (emailFolder && emailFolder.length > 0) {
      const folderOptions = emailFolder.map((contact) => ({
        value: contact.id, // Use email as the value for the options
        label: contact.name,
        // label: `${contact.firstName} ${contact.lastName} (${contact.email})`, // Display name and email
      }));
      setOptions(folderOptions);
    }
  }, [emailFolder]); // Update options whenever contacts change

  const [email, setEmail] = useState("");
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);
  const [bodyText, setBodyText] = useState("");

  const { Option } = Select;

  const googleToken = localStorage.getItem("Gp_TK");
  const token = localStorage.getItem("Afb");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toEmail, setToEmail] = useState(null);

  const [size, setSize] = useState("Default");
  const handleChange = (value) => {
    // console.log(`Selected: ${value}`);
  };

  for (let i = 10; i < 36; i++) {
    // options.push({
    //   value: i.toString(36) + i,
    //   label: i.toString(36) + i,
    // });
  }

  // Function to check Google token availability
  const checkGoogleToken = () => {
    if (!googleToken) {
      message.info("Connect with your Google Account to send emails");
      return false;
    }
    return true;
  };
  const handleInputChange = () => {
    // checkGoogleToken();
    return;
  };
  // const onFinish = async (values) => {
  //   console.log("Received values of form: ", values);

  //   const completions = await getChatCompletion(
  //     client,
  //     "Write mail to HR for Seek leave for 2 days"
  //   );
  //   console.log("completions", completions);
  // };

  // onFinish function that will send the email
  const onFinish = async (values) => {
    try {
      if (googleToken) {
        const payload = {
          folder: values.folderTemp,
          name: values.name,
          subject: values.subject,
          body: bodyText,
        };
        // console.log("Email-template payload:", payload);
        await dispatch(addEmailTemp(payload)); // Dispatch the action with the formatted payload
        dispatch(getEmailTemps());
        dispatch(getEmailFolders());
        form.resetFields();
        setBodyText("");
        message.success("New Template has been created!");
        onClose();
      } else {
        message.info("Connect with google to create templates");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const handleBCC = () => {
    setBCC(!bcc);
  };

  const handleCC = () => {
    setCC(!cc);
  };
  const auth = getAuth();

  useEffect(() => {
    // Get the currently logged-in user from Firebase
    const user = auth.currentUser;

    if (user) {
      // User is logged in, set the email
      setEmail(user.email);
    } else {
      // No user is logged in, handle accordingly
      console.log("No user is logged in");
    }
  }, []);

  const checkGmailAuth = () => {
    message.info("Connect with Google via Settings page to send emails");
  };

  useEffect(() => {
    const token = localStorage.getItem("Gp_TK");
    setIsButtonDisabled(!token);
  }, []);

  // const emailFolder = useSelector((state)=> state.emailfolder);

  useEffect(() => {
    const fetchEmailFolder = async () => {
      await dispatch(getEmailFolders());
    };
    fetchEmailFolder();
  }, [dispatch]);

  // console.log("emailFolder comp:", emailFolder);
  const onChange = (key) => {
    // console.log(key);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const text =
    "firstname, Here's a quick home selling tip: Master Bedrooms are a dsfds...";
  const maxLength = 90;

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Create New Template"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        </>
      }
    >
      <Form
        name="emailForm"
        onFinish={onFinish}
        form={form}
        // initialValues={{
        //   name: "",
        //   from: email,
        //   to: "",
        //   subject: "",
        //   body: bodyText,
        // }}
        layout="vertical" // Vertical layout for labels above fields
        style={{ maxWidth: "100%" }} // Ensure the form takes the full width
      >
        {/* From Field */}
        <Form.Item
          label="Name"
          name="name"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please enter the name of template!",
            },
          ]}
        >
          <Input placeholder={"Name the template"} />
        </Form.Item>

        {/* To Field */}
        {/* <Form.Item
            label="To"
            name="to"
            // style={{ marginBottom: 20 }}
            onChange={handleInputChange}
            rules={[
              {
                required: true,
                message: "Please enter the recipient's email address!",
              },
            ]}
          >
            <Input placeholder="Enter recipient's email address" />
          </Form.Item> */}

        <Form.Item
          name="folderTemp"
          label="Template Folder"
          rules={[
            {
              required: true,
              message: "Select the template folder!",
            },
          ]}
        >
          <Select
            size={size}
            placeholder="Add or Select the template folder"
            // defaultValue="a1"
            onChange={handleChange}
            style={{
              width: 200,
            }}
            options={options}
          />
        </Form.Item>

        {/* Subject Field */}
        <Form.Item
          label="Subject"
          name="subject"
          onChange={handleInputChange}
          rules={[
            {
              required: true,
              message: "Please enter the subject of the email!",
            },
          ]}
        >
          <Input placeholder="Enter the subject of the email" />
        </Form.Item>

        {/* Email Body Field */}
        <Form.Item
          label="Email Body"
          name="body"
          // rules={[
          //   {
          //     required: true,
          //    message: "Please enter the body of the email!",
          //   },
          // ]}
        >
          <Richtext
            bodyText={bodyText}
            setBodyText={setBodyText}
            toEmail={toEmail}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            type="primary"
            // disabled={isButtonDisabled}
            htmlType="submit"
            // onClick={checkGmailAuth}
          >
            Save Template
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewTemplateDrawer;
