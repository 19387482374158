import React from "react";
import {
  Card,
} from "antd";

import ContactsComp from "./Contacts";


const Contacts = () => {
  document.title = "Contact";
  return (
    <div className="page-content">
    <Card title="Contact" >
    <ContactsComp />
    </Card>
    </div>
  );
};

export default Contacts;
