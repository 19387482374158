import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Table,
  Flex,
  Card,
  Form,
  Row,
  Col,
  message,
  Modal,
  Skeleton,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FaCheckCircle } from "react-icons/fa";
import Connect from "../../components/Connect";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { auth } from "../../App";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import config from "../../config";
import { googleConnectCallback } from "../../utils/auth";

const Settings = () => {
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const useremail = localStorage.getItem("useremail");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSynced, setIsSynced] = useState(false);
  const [gapiToken, setGapiToken] = useState(null);

  const [googleToken, setGoogleToken] = useState(localStorage.getItem("Gp_TK"));
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  // const router = useRouter();

  // const searchParams = useSearchParams();

  useEffect(async () => {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');  // Capture the 'code' parameter
    const state = urlParams.get('state');
 

    if (code) {
        console.log('Authorization Code:', code);
        console.log('State:', state);

        googleConnectCallback(state, code)
        .then(data => {
          localStorage.setItem("Gp_TK", data.access_token);
          console.log(data.access_token);
          setGoogleToken(data.access_token);
        })
        .catch(error => console.error('Error:', error));
    }
  }, []);

  const revokeToken = async (token) => {
    try {
      console.log("token for logout: ", token);
      const response = await fetch(
        `https://oauth2.googleapis.com/revoke?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      try {
        localStorage.removeItem("Gp_TK");
      } catch (error) {
        console.log(error);
      }
      if (response.ok) {
        localStorage.removeItem("Gp_TK");
        console.log("Token revoked successfully.");
        message.info("Google disconnected successfully.");
        navigate("/");
        setIsSynced(false);
      } else {
        console.error("Failed to revoke token:", response.statusText);
      }
      navigate("/");
    } catch (error) {
      console.error("Error during token revocation:", error);
    }
  };

  // function revokeAccess() {
  //   const user = auth.currentUser;

  //   if (user) {
  //     user.getIdToken(true).then((idToken) => {
  //       const revokeUrl = https://oauth2.googleapis.com/revoke?token=${idToken};

  //       // Make a request to Google's revocation endpoint
  //       fetch(revokeUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-type": "application/x-www-form-urlencoded",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             console.log("Access successfully revoked.");

  //             // Optionally, sign out the user from Firebase
  //             auth.signOut();
  //           } else {
  //             console.error("Failed to revoke access:", response);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error revoking token:", error);
  //         });
  //     });
  //   } else {
  //     console.error("No user is currently signed in.");
  //   }
  // }
  const handleLogout = async (token) => {
    try {
      revokeToken(token);
      console.log("token sent for logout:", token);
    } catch {
      console.log(error);
    }
  };

  // const handleReset = async () => {
  //   const ans = prompt(
  //     "Are you sure you want to reset all information? Type 'yes' to confirm"
  //   );
  //   if (ans !== "yes") {
  //     return;
  //   } else {
  //     axios
  //       .post("/reset-account/", { withCredentials: true })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           alert("All information reset successfully");
  //           navigate("/");
  //         }
  //       })
  //       .catch((error) => {
  //         alert("Error resetting information");
  //       });
  //   }
  // };

  // const handleDelete = async () => {
  //   const ans = prompt(
  //     "Are you sure you want to delete your account? Type 'yes' to confirm"
  //   );
  //   if (ans !== "yes") {
  //     return;
  //   } else {
  //     axios
  //       .post("/delete-account/", { withCredentials: true })
  //       .then((response) => {
  //         if (response.status === 200) {
  //           alert("Account deleted successfully");
  //           localStorage.clear();
  //           navigate("/login");
  //         }
  //       })
  //       .catch((error) => {
  //         alert("Error deleting account");
  //       });
  //   }
  // };

  const deleteFirebaseAccount = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        await user.delete();
        message.success("Your account has been deleted successfully.");
        // Optional: Clear local storage and redirect to login page
        localStorage.clear();
        navigate("/login");
      } catch (error) {
        // Handle errors (e.g., if the user needs to re-authenticate)
        if (error.code === "auth/requires-recent-login") {
          message.error(
            "This operation is sensitive and requires recent authentication. Please log in again and try again."
          );
        } else {
          message.error(`Failed to delete account: ${error.message}`);
        }
      }
    } else {
      message.error("No user is currently signed in.");
    }
  };

  const handleReset = async () => {
    setResetModalVisible(false); // Close the modal
    const token = localStorage.getItem("Afb");
    const gapiToken = localStorage.getItem("Gp_TK");
    try {
      if (gapiToken) {
        await revokeToken(gapiToken);
      }
      const response = await axios.post(`${config.API_URL}/reset-account/`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if (response.status === 200) {
        localStorage.removeItem("Gp_TK");
        localStorage.removeItem("emailSignature");
        localStorage.removeItem("Ur_GPt");
        message.success("All information reset successfully");
        navigate("/");
      }
    } catch (error) {
      message.error("Error resetting information");
    }
  };

  const handleDelete = async () => {
    setDeleteModalVisible(false); // Close the modal
    try {
      const response = await axios.post("/delete-account/", {
        withCredentials: true,
      });
      if (response.status === 200) {
        deleteFirebaseAccount();
        // message.success("Account deleted successfully");
        // localStorage.clear();
        // navigate("/login");
      }
    } catch (error) {
      message.error("Error deleting account");
    }
  };
  // ____________________________________________________

  // const handleChangePassword = async (values) => {
  //   setLoading(true);
  //   setError("");

  //   const { oldPassword, newPassword, confirmNewPassword } = values;

  //   if (newPassword !== confirmNewPassword) {
  //     setError("New password and confirmation do not match.");
  //     setLoading(false);
  //     return;
  //   }

  //   const user = auth.currentUser;

  //   if (!user) {
  //     setError("No user is currently logged in.");
  //     setLoading(false);
  //     return;
  //   }

  //   try {
  //     // Re-authenticate the user
  //     console.log("user email", user.email);

  //     const credential = EmailAuthProvider.credential(user.email, oldPassword);

  //     await user.reauthenticateWithCredential(user, credential);

  //     // Update password
  //     await updatePassword(user, newPassword);
  //     alert("Password changed successfully!");
  //   } catch (error) {
  //     setError(error.message || JSON.stringify(error));
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handlePasswordReset = async (values) => {
    setError("");

    setLoading(true);

    const { oldPassword, newPassword, confirmNewPassword } = values;

    if (newPassword !== confirmNewPassword) {
      console.error("New password and confirm password do not match.");
      setError("New password and confirmation do not match.");
      setLoading(false);
      return;
    }

    if (newPassword == oldPassword) {
      console.error("Old password and New password cannot be same");
      setError("Old password and New password cannot be same.");
      setLoading(false);
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    try {
      // Re-authenticate the user with the old password
      await reauthenticateWithCredential(user, credential);
      console.log(user);
      console.log(credential);
      // Update password to the new one
      await updatePassword(user, newPassword);
      console.log("Password updated successfully.");
      message.success("Password updated successfully");
      form.resetFields();

      setError("");
      setLoading(false);
    } catch (error) {
      console.error("Error updating password:", error);
      setError("Invalid Credentials");
      setLoading(false);
    }
  };

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const dataSource = [
    {
      key: "1",
      email: useremail,
      syncemail: "right",
      synccal: "right",
      synccontact: "right",
    },
  ];

  const columns = [
    {
      title: "Email Account",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Sync Emails",
      dataIndex: "syncemail",
      key: "syncemail",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Sync Calendar",
      dataIndex: "synccal",
      key: "synccal",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Sync Contact",
      dataIndex: "synccontact",
      key: "synccontact",
      render: (text) =>
        text ? <FaCheckCircle style={{ color: "green" }} /> : null,
    },
    {
      title: "Disconnect",
      dataIndex: "disconnect",
      key: "disconnect",
      render: (text, record) => (
        <Button
          type="default"
          style={{ backgroundColor: "black", color: "white" }}
          onClick={() => handleLogout(googleToken)}
        >
          Disconnect
        </Button>
      ),
    },
  ];

  // const hasData = dataSource && dataSource.length > 0;

  return (
    <div>
      <Card bordered={false} className="mb-2">
        {googleToken ? (
          <Table
            bordered
            className="mt-4"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        ) : (
          <>
            {loadingGoogle ? (
              <Skeleton active />
            ) : (
              <Connect
                loading={loadingGoogle}
                setLoading={setLoadingGoogle}
                isSynced={isSynced}
                setIsSynced={setIsSynced}
                gapiToken={gapiToken}
                setGapiToken={setGapiToken}
              />
            )}
          </>
        )}
      </Card>

      <Card bordered={false}>
        <Flex justify="space-between" align="flex-start">
          <div>
            <h5>Reset all Information</h5>
            <div>
              Please note that this would delete all contact cards and
              associated notifications for all contacts, and cannot be reversed
              later.
            </div>
          </div>
          <div>
            <Button
              type="primary"
              style={{ width: 85 }}
              onClick={() => setResetModalVisible(true)}
            >
              Reset
            </Button>
          </div>
        </Flex>
      </Card>

      <Card bordered={false} style={{ marginTop: 10 }}>
        <Flex justify="space-between" align="flex-start">
          <div>
            <h5>Delete Account</h5>
            <div>
              Please note that this would delete all data stored on this
              account, including contact cards, notifications and notes, and
              cannot be reversed later.
            </div>
          </div>
          <div>
            <Button
              type="primary"
              style={{ width: 85 }}
              onClick={() => setDeleteModalVisible(true)}
            >
              Delete
            </Button>
          </div>
        </Flex>
      </Card>

      {/* Reset Confirmation Modal */}
      <Modal
        title="Confirm Reset"
        visible={resetModalVisible}
        onOk={handleReset}
        onCancel={() => setResetModalVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        Are you sure you want to reset all information? This action cannot be
        undone.
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        Are you sure you want to delete your account? This action cannot be
        undone.
      </Modal>

      <Card bordered={false} style={{ marginTop: 10 }}>
        <Flex justify="space-between" align="flex-start">
          <div>
            <h5>Change Password</h5>
            <div>Secure your password here</div>
          </div>
          <div>
            <Button
              type="primary"
              style={{ width: 85 }}
              onClick={() => form.submit()}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Flex>
        <Form
          layout="vertical"
          className="mt-4"
          hideRequiredMark
          form={form}
          onFinish={handlePasswordReset}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="oldPassword"
                label="Current Password"
                rules={[{ required: true, message: "Enter Current Password" }]}
              >
                <Input.Password
                  placeholder="Please enter Current Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[{ required: true, message: "Enter New Password" }]}
              >
                <Input.Password
                  placeholder="Please enter New Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirmNewPassword"
                label="Confirm New Password"
                rules={[
                  { required: true, message: "Enter Confirm New Password" },
                ]}
              >
                <Input.Password
                  placeholder="Please enter Confirm New Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {error && <div style={{ color: "red" }}>{error}</div>}
        </Form>
      </Card>
    </div>
  );
};

export default Settings;
