import React from "react";
import {
  Card,
  Tabs,
} from "antd";
import EmailManagement from "./EmailManagement";
import ComposeEmail from "./ComposeEmail";


const Communication = () => {
  document.title = "Communication";

  const onChange = (key) => {
    // console.log(key);
  };
  
  const items = [
    {
      key: '1',
      label: 'Compose an Email',
      children: <ComposeEmail />,
    },
    {
      key: '2',
      label: 'Email Management',
      children: <EmailManagement />,
    }
  ];

  return (
    <div className="page-content">
    <Card title="Communication" >
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Card>
    </div>
  );
};

export default Communication;