import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import api from "../config";
import { auth } from "../App";
import { message } from "antd";
import axios from "../axios";
import config from "../config";

export const login = (user) => {
  localStorage.setItem("Afb", JSON.stringify(user));
};

// LOGOUT
export const logout = () => {
  try {
    auth.signOut();
    localStorage.removeItem("Afb");
    localStorage.clear();
    message.success("User logged out sucuessfully.");
  } catch (error) {
    message.error("Something went wrong.");
  }
};

// LOGIN STATUS
export const isLogin = () => {
  var myObj = JSON.parse(localStorage.getItem("Afb"));
  var size = myObj ? Object.keys(myObj).length : 0;
  if (size > 0) return true;
  return false;
};

// GET LOGIN DATA
export const getLoginData = () => {
  return JSON.parse(localStorage.getItem("Afb"));
};

export const refreshAccessToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    const idTokenResult = await user.getIdTokenResult(true); // Forces a token refresh
    const newAccessToken = idTokenResult.token;
    const refreshToken = user.refreshToken;

    return { newAccessToken, refreshToken };
  } else {
    throw new Error("No user is signed in.");
  }
};

export const updateTokensOnBackend = async (
  newAccessToken,
  refreshToken,
  idToken,
  oauthEmail
) => {

  // const response = await api.post(
  //   "/api/scrape-emails/",
  //   {
  //     accessToken: newAccessToken,
  //     refreshToken: refreshToken,
  //     email: oauthEmail,
  //     token: idToken,
  //     tokenExpirySeconds: 3600, // Typically 1 hour for Google tokens
  //   },
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     withCredentials: true,
  //   }
  // );

  const token = localStorage.getItem("Afb");

  const response = await axios.post(`${config.API_URL}/scrape-emails/`, {
    accessToken: newAccessToken,
    refreshToken: "refreshToken",
    email: oauthEmail,
    token: idToken,
    tokenExpirySeconds: 3600,
    },
    {
      headers: {
        Authorization: `${token}`,
      }
    }
  );

  return response.data;
};


export const getAuthorizationURL = async () => {

  const token = localStorage.getItem("Afb");

  const response = await axios.get(`${config.API_URL}/google-connect/`,
    {
      headers: {
        Authorization: `${token}`,
      }
    }
  );

  return response.data;
};


export const googleConnectCallback = async (
  state,
  code,
) => {
  const token = localStorage.getItem("Afb");

  const response = await axios.get(`${config.API_URL}/google-connect/callback/?state=${state}&code=${code}`,
    {
      headers: {
        Authorization: `${token}`,
      }
    }
  );

  return response.data;
};


export const getGoogleAccessToken = async () => {

  const token = localStorage.getItem("Afb");

  const response = await axios.get(
    `${config.API_URL}/current-user-id/`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );

  return response.data;
};
