import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  FolderFilled,
  SearchOutlined,
} from "@ant-design/icons";
import edit1 from "../../assets/figmaAssets/edit.svg";
import delete1 from "../../assets/figmaAssets/delete.svg";
import tick1 from "../../assets/figmaAssets/tick.svg";

import { MdEmail } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import left from "../../assets/figmaAssets/left.svg";

import {
  Button,
  Col,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import { addTask, getTasks } from "../../store/actions/tasks"; // Import the action creator
import moment from "moment"; // Import moment.js for date and time manipulation
import CustomCollapse from "./CustomCollapse";
import {
  addEmailFolder,
  getEmailFolders,
} from "../../store/actions/emailfolder";
import NewFolderDrawer from "./NewFolderDrawer";
import NewTemplateDrawer from "./NewTemplateDrawer";
import axios from "axios";
import { deleteEmailTemp, getEmailTemps } from "../../store/actions/emailtemp";
import ViewTemplateDrawer from "./ViewTemplateDrawer";

const CommunicationDrawer = ({
  open,
  onClose,
  useTemplate,
  setUseTemplate,
  bodyText,
  setBodyText,
  setSubject,
}) => {
  const dispatch = useDispatch(); // Initialize the dispatch function
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTemplateOpen, setDrawerTemplateOpen] = useState(false);
  const [ViewTemplateOpen, setViewTemplateOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [templates, setTemplates] = useState([]);
  const emailFolder = useSelector((state) => state.emailfolder.list);
  const emailTemp = useSelector((state) => state.emailtemp.list);
  const [templateInfo, setTemplateInfo] = useState({});
  const [selectedFolder, setSelectedFolder] = useState(null);
  // State for search input
  const [searchTerm, setSearchTerm] = useState("");

  // console.log("emailFolder -->", emailFolder);
  // console.log("emailTemplate -->", emailTemp);
  // console.log("email usestate folder -->", folders);
  // console.log("email usestate temp -->", templates);

  useEffect(() => {
    const fetchEmailData = async () => {
      // Fetch email folders first
      await dispatch(getEmailFolders());

      // Check if there are any folders
      const hasAnyFolders = emailFolder.length > 0;

      // Check if "Default" folder already exists
      const defaultFolderExists = emailFolder.some(
        (folder) => folder.name === "Default"
      );

      // Only create the Default folder if there are no other folders
      if (!hasAnyFolders && !defaultFolderExists) {
        // If no other folder exists, create the Default folder
        const defaultFolder = {
          name: "Default",
          descrioption: "This is your default folder", // Optional description
        };

        // Create the default folder
        await dispatch(addEmailFolder(defaultFolder));

        // Fetch the folders again after creating the default folder
        await dispatch(getEmailFolders());
      }

      // Fetch email templates
      await dispatch(getEmailTemps());

      // Set folders and templates in local state
      setFolders(emailFolder);
      setTemplates(emailTemp);
    };

    // Call the function to fetch folders and templates
    fetchEmailData();
  }, []);

  useEffect(() => {
    const fetchEmailFolder = async () => {
      await dispatch(getEmailFolders());
    };

    const fetchEmailTemplates = async () => {
      await dispatch(getEmailTemps());
    };

    fetchEmailFolder();
    fetchEmailTemplates();
    setFolders(emailFolder);
    setTemplates(emailTemp);
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteEmailTemp(id));
      await dispatch(getEmailFolders());
      await dispatch(getEmailTemps());
      message.success("Template has been deleted successfully");
    } catch (error) {
      message.info(
        "Something went wrong while deleting the template. Try again !"
      );
    }
  };

  const handleViewTemplate = (templateDetail, folder) => {
    // console.log("clicked view template button.");
    // console.log("template detail: ", templateDetail);
    setTemplateInfo(templateDetail);
    setSelectedFolder(folder);
    // console.log("Template Info: ", templateInfo);
    onClose();
    setViewTemplateOpen(true);
  };

  // useEffect(() => {}, [dispatch]);

  const filteredTemplates = emailTemp.filter(
    (template) =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.subject.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUseTemplate = (templateData) => {
    // console.log("Use this template", templateData);
    setSubject(templateData.subject);
    setBodyText(templateData.body);
    // setUseTemplate(templateData);
    onClose();
  };

  // const filteredFolders = folders.filter((folder) =>
  //   folder.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Group templates by their folder ID
  const getTemplatesForFolder = (folderId) => {
    return filteredTemplates.filter((template) => template.folder === folderId);
  };

  // Map folders data to the items array
  const items = emailFolder.map((folder) => {
    const folderTemplates = getTemplatesForFolder(folder.id);
    return {
      key: folder.id.toString(),
      label: (
        <>
          <strong>{folder.name}</strong>
        </>
      ),
      children: (
        <>
          {folderTemplates.map((template) => (
            <Row
              key={template.id}
              gutter={16}
              style={{
                background: "rgba(228, 231, 236, 1)",
                padding: "12px",
                borderRadius: 4,
                marginBottom: "8px",
                cursor: "pointer",
              }}
            >
              {/* Icon Column */}
              <Col
                flex="none"
                onClick={() => handleUseTemplate(template)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <MdEmail size={20} />
              </Col>

              {/* Content Column */}
              <Col
                flex="1"
                style={{ display: "flex", flexDirection: "column" }}
                onClick={() => handleUseTemplate(template)}
              >
                <Row>
                  <Col>{template.name}</Col>
                </Row>
                <Row>
                  <Col>{template.subject}</Col>
                </Row>
              </Col>

              {/* Action Icons Columns */}
              <Col
                flex="none"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  style={{ color: "red", fontSize: "20px", cursor: "pointer" }}
                  src={delete1}
                  onClick={() => {
                    handleDelete(template.id);
                  }}
                />
                {/* <DeleteOutlined /> */}
              </Col>
              <Col
                flex="none"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  style={{ fontSize: "20px", cursor: "pointer" }}
                  src={edit1}
                  onClick={() => handleViewTemplate(template, folder)}
                />
                {/* <EyeFilled /> */}
              </Col>
            </Row>
          ))}
        </>
      ),
    };
  });

  // console.log("emailFolder comp:", emailFolder);

  useEffect(() => {
    dispatch(getEmailTemps());
    dispatch(getEmailFolders());
    setFolders(emailFolder);
    setTemplates(emailTemp);
  }, [dispatch]);

  const text =
    "firstname, Here's a quick home selling tip: Master Bedrooms are a dsfds...";
  const maxLength = 90;

  const handleNewFolder = () => {
    onClose();
    setDrawerOpen(true);
  };

  const handleNewTemplate = () => {
    onClose();
    setDrawerTemplateOpen(true);
  };

  return (
    <>
      <Drawer
        closeIcon={<img src={left} />}
        title="Choose a Template"
        width={720}
        onClose={onClose}
        open={open}
        zIndex={1003}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <>
            <Space>
              <CloseOutlined onClick={onClose} />
            </Space>
          </>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name">
                <Input
                  prefix={<FiSearch />}
                  value={searchTerm}
                  placeholder="Search for templates"
                  type="search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Flex align="center" justify="end">
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={handleNewTemplate}
                >
                  New Template
                </Button>
                <Button
                  style={{ color: "rgba(32, 160, 236, 1)" }}
                  onClick={handleNewFolder}
                >
                  New Folder
                </Button>
              </Flex>
            </Col>
          </Row>
          <CustomCollapse items={items} defaultActiveKey={["1"]} />
        </Form>
      </Drawer>
      <NewFolderDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <NewTemplateDrawer
        open={drawerTemplateOpen}
        onClose={() => setDrawerTemplateOpen(false)}
      />
      <ViewTemplateDrawer
        open={ViewTemplateOpen}
        setTemplateInfo={setTemplateInfo}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        templateInfo={templateInfo}
        onClose={() => setViewTemplateOpen(false)}
        useTemplate={useTemplate}
        setUseTemplate={setUseTemplate}
      />
    </>
  );
};

export default CommunicationDrawer;
