import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  FolderFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import left from "../../assets/figmaAssets/left.svg";

import {
  Button,
  Col,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import { addTask, getTasks } from "../../store/actions/tasks"; // Import the action creator
import moment from "moment"; // Import moment.js for date and time manipulation
import CustomCollapse from "./CustomCollapse";
import {
  addEmailFolder,
  getEmailFolders,
} from "../../store/actions/emailfolder";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { getEmailTemps } from "../../store/actions/emailtemp";

const NewFolderDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch(); // Initialize the dispatch function
  const emailFolder = useSelector((state) => state.emailfolder);
  const [form] = Form.useForm();

  const googleToken = localStorage.getItem("Gp_TK");

  // const emailFolder = useSelector((state)=> state.emailfolder);

  useEffect(() => {
    const fetchEmailFolder = async () => {
      await dispatch(getEmailFolders());
    };
    // console.log("EmailFolder", emailFolder);
    fetchEmailFolder();
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchEmailFolder = async () => {
  //     await dispatch(getEmailFolders());
  //   };
  //   console.log("EmailFolder", emailFolder);
  //   fetchEmailFolder();
  // }, []);

  // console.log("emailFolder comp:", emailFolder);
  const onChange = (key) => {
    // console.log(key);
  };
  const token = localStorage.getItem("Gp_TK");

  useEffect(() => {
    const token = localStorage.getItem("Gp_TK");
  }, []);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const text =
    "firstname, Here's a quick home selling tip: Master Bedrooms are a dsfds...";
  const maxLength = 90;

  const onFinish = async (values) => {
    try {
      if (googleToken) {
        const payload = {
          name: values.name,
          descrioption: values.description,
        };
        // console.log("Email-folder payload:", payload);
        await dispatch(addEmailFolder(payload)); // Dispatch the action with the formatted payload

        form.resetFields();
        // setBodyText("");
        message.success("New Folder has been created!");
        dispatch(getEmailTemps());
        dispatch(getEmailFolders());
        onClose();
      } else {
        message.info("Connect with google to create new folders.");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  const handleCreateTask = async (values) => {
    // Transform date and time values into ISO 8601 format
    const payload = {
      name: values.name,
      category: values.category,
      notes: values.notes,
      start_date: moment(values.start_date).format("YYYY-MM-DD"), // Start of the day
      end_date: moment(values.end_date).format("YYYY-MM-DD"), // End of the day
      start_time: moment(values.start_time).format("HH:mm"), // Specific time
      end_time: moment(values.end_time).format("HH:mm"), // Specific time
      reminder_date: moment(values.reminder_date).format("YYYY-MM-DD"), // Reminder date
      reminder_time: moment(values.reminder_time).format("HH:mm"), // Reminder time
      remind_via: values.reminder_via,
    };

    // await dispatch(addTask(payload)); // Dispatch the action with the formatted payload
    await dispatch(getEmailFolders());
    onClose(); // Close the drawer after dispatching the action
  };

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Create New Folder"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Space>
            <CloseOutlined onClick={onClose} />
          </Space>
        </>
      }
    >
      <Form
        name="emailForm"
        onFinish={onFinish}
        form={form}
        layout="vertical" // Vertical layout for labels above fields
        style={{ maxWidth: "100%" }} // Ensure the form takes the full width
      >
        {/* From Field */}
        <Form.Item
          label="Folder Name"
          name="name"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please enter the name of folder!",
            },
          ]}
        >
          <Input placeholder={"Name the Folder"} />
        </Form.Item>

        {/* To Field */}
        {/* <Form.Item
            label="To"
            name="to"
            // style={{ marginBottom: 20 }}
            onChange={handleInputChange}
            rules={[
              {
                required: true,
                message: "Please enter the recipient's email address!",
              },
            ]}
          >
            <Input placeholder="Enter recipient's email address" />
          </Form.Item> */}

        <Form.Item
          label="Description"
          name="description"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please enter the description of folder!",
            },
          ]}
        >
          <TextArea
            rows={12}
            placeholder="Write Note description"
            // onChange={(e) => form.setFieldsValue({ content: e.target.value })} // Update form value
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            type="primary"
            // disabled={isButtonDisabled}
            htmlType="submit"
            // onClick={checkGmailAuth}
          >
            Save Folder
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewFolderDrawer;
