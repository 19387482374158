import React from "react";
import { Card } from "antd";

import SettingsComp from "./SettingsComp";

const Settings = () => {
  document.title = "Settings";

  return (
    <div className="page-content">
      <Card title="Project Setting">
        <SettingsComp />
      </Card>
    </div>
  );
};

export default Settings;
