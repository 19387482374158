import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Collapse,
  TimePicker,
  message,
} from "antd";
import { addTask, getTasks } from "../../store/actions/tasks"; // Import the action creator
import moment from "moment"; // Import moment.js for date and time manipulation
import left from "../../assets/figmaAssets/left.svg";

const { Option } = Select;
const { TextArea } = Input;

const MyTaskDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch(); // Initialize the dispatch function
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const tasks = useSelector((state) => state.tasks.list);

  useEffect(() => {
    // Extract unique categories
    const categorySet = new Set(); // To store unique categories

    tasks.forEach((task) => {
      const userID = localStorage.getItem("properly_UI");
      if (task.user == userID) {
        // Filter based on user (example user ID is 6)
        const categories = task.category.split(","); // Split by commas
        categories.forEach((category) => categorySet.add(category.trim())); // Add to Set (unique)
      }
    });

    // Convert Set to Array and format as Select options
    const uniqueCategories = Array.from(categorySet).map((category) => ({
      label: category,
      value: category,
    }));
    setOptions(uniqueCategories);
  }, [tasks]);

  const [size, setSize] = useState("Default");
  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  for (let i = 10; i < 36; i++) {
    // options.push({
    //   value: i.toString(36) + i,
    //   label: i.toString(36) + i,
    // });
  }

  const onChange = (key) => {
    console.log(key);
  };

  const createCalendarEvent = async (accessToken, eventDetails) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        const event = await response.json();
        message.success("Synced with your calendar successfully");
      } else {
        const error = await response.json();
        console.error("Failed to create event:", error);
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
    }
  };

  // Call the function with an access token and event details
  const syncGapiCalendar = (token, event) => {
    createCalendarEvent(token, event);
  };

  // Helper functions
  const formatDateToYYYYMMDD = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

  const formatTimeToHHMM = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const hours = String(date.getHours()).padStart(2, "0"); // Ensures 2 digits for hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensures 2 digits for minutes
    return `${hours}:${minutes}`; // Format as HH:mm
  };

  const handleCreateTask = async (values) => {
    // Transform date and time values into ISO 8601 format

    const categories = values.category;
    const commaSeparatedString = categories ? categories.join(",") : "";

    // Format dates and times
    const startDate = formatDateToYYYYMMDD(values["start_date"]);

    const reminderDate = formatDateToYYYYMMDD(values["reminder_date"]);

    const startTime = formatTimeToHHMM(values["start_time"]);
    const endTime = formatTimeToHHMM(values["end_time"]);
    const reminderTime = formatTimeToHHMM(values["reminder_time"]);

    // Not functional in frontend ( Removed by demand )
    // const endDate = moment(values.end_date).format("YYYY-MM-DD");
    // const endTime = moment(values.end_time).format("HH:mm");

    const endDate = startDate; // Assuming end date is the same as start date
    try {
      const payload = {
        name: values.name,
        category: commaSeparatedString,
        notes: values.notes || "",
        start_date: startDate || null, // Default to null if the date is invalid
        end_date: endDate || null,
        start_time: startTime || null, // Default to null if the time is invalid
        end_time: endTime || null,
        reminder_date: reminderDate || null,
        reminder_time: reminderTime || null,
        remind_via: values.reminder_via || null,
      };

      const calendarPayload = {
        name: values.name,
        category: commaSeparatedString || "",
        notes: values.notes || "",
        // start_date: moment(values.start_date).format("YYYY-MM-DD") || null, // Start of the day
        start_date: values.start_date || null, // Start of the day
        end_date: moment(values.end_date).format("YYYY-MM-DD") || null, // End of the day
        start_time: moment(values.start_time).format("HH:mm") || null, // Specific time
        end_time: moment(values.end_time).format("HH:mm") || null, // Specific time
        reminder_date:
          moment(values.reminder_date).format("YYYY-MM-DD") || null, // Reminder date
        reminder_time: moment(values.reminder_time).format("HH:mm") || null, // Reminder time
        remind_via: values.reminder_via || null,
      };

      const googleToken = localStorage.getItem("Gp_TK");
      // console.log("Task payload", payload);
      // console.log("calendar task payload", calendarPayload);

      if (googleToken) {
        // Check if the start and end date, time, and reminder fields are not null
        if (
          payload.start_date &&
          payload.end_date &&
          payload.start_time &&
          payload.end_time &&
          payload.reminder_date &&
          payload.reminder_time &&
          payload.remind_via
        ) {
          // Combine start date and time into a Date object for calculation
          const eventStartDateTime = new Date(
            `${payload.start_date}T${payload.start_time}:00`
          );
          const reminderDateTime = new Date(
            `${payload.reminder_date}T${payload.reminder_time}:00`
          );

          // Calculate the difference in minutes between the event start and reminder time
          const timeDifferenceInMs = eventStartDateTime - reminderDateTime;
          const timeDifferenceInMinutes = Math.floor(
            timeDifferenceInMs / 60000
          ); // Convert milliseconds to minutes

          if (timeDifferenceInMinutes < 0) {
            message.error("Reminder time must be before the event start time.");
            return;
          }

          // Get the user's timezone
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Build the event details object for Google Calendar sync
          const eventDetails = {
            summary: payload.name,
            description: payload.category,
            start: {
              dateTime: `${payload.start_date}T${payload.start_time}:00`, // Combine date and time for Google Calendar
              timeZone: userTimeZone, // Modify to your desired timezone
            },
            end: {
              dateTime: `${payload.end_date}T${payload.end_time}:00`,
              timeZone: userTimeZone,
            },
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: timeDifferenceInMinutes }, // User-defined email reminder
                { method: "popup", minutes: 10 }, // 10 minutes before for popup reminder
              ],
            },
          };

          // Sync with Google Calendar
          syncGapiCalendar(googleToken, eventDetails);
        }
        // Close the drawer after dispatching the action
      } else {
        message.info("Connect with Google to sync the event!");
      }

      try {
        await dispatch(addTask(payload)); // Dispatch the action with the formatted payload
        await dispatch(getTasks());
        message.success("Tasks created !");
        onClose();
        form.resetFields();
        // console.log(payload.start_date);
      } catch (error) {
        message.error("Something went wrong while creating new task.");
      }
    } catch (error) {
      console.log("==>", error);
      message.error("Something went wrong !");
    }
  };

  const items = [
    {
      key: "1",
      label: <strong>Sync Calendar</strong>,
      children: (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="start_date" label="Start date">
              <DatePicker
                placeholder="Choose start date"
                style={{ width: "100%" }}
                format="MM/DD/YYYY" // Format for month, day, and year
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="start_time" label="Start time">
              <TimePicker
                use12Hours
                format="h:mm a"
                placeholder="Choose start time"
                style={{ width: "100%" }}
                renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                onSelect={() => document.querySelector(".ant-picker").blur()} // Closes picker on selection
                onChange={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="end_time" label="End time">
              <TimePicker
                use12Hours
                format="h:mm a"
                placeholder="Choose end time"
                style={{ width: "100%" }}
                renderExtraFooter={() => null}
                onChange={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const itemstwo = [
    {
      key: "1",
      label: <strong>Set Reminder</strong>,
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="reminder_time" label="Reminder time">
              <TimePicker
                use12Hours
                format="h:mm a"
                placeholder="Choose Reminder Time"
                style={{ width: "100%" }}
                renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                onSelect={() => document.querySelector(".ant-picker").blur()} // Closes picker on selection
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="reminder_date" label="Reminder date">
              <DatePicker
                use12Hours
                placeholder="Choose Reminder date"
                style={{ width: "100%" }}
                format="MM/DD/YYYY" // Format for month, day, and year
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="reminder_via" label="Remind Via">
              <Select placeholder="Select a Remind via">
                <Option value="SMS & Email" disabled>
                  SMS & Email
                </Option>
                <Option value="Email">Email</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Create Task"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <CloseOutlined onClick={onClose} />
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleCreateTask}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Task Name"
              rules={[
                {
                  required: true,
                  message: "Enter task name",
                },
              ]}
            >
              <Input placeholder="Please enter Task name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              // rules={[
              //   {
              //     required: true,
              //     message: "Enter the category",
              //   },
              // ]}
            >
              <Select
                mode="tags"
                size={size}
                placeholder="Please select"
                defaultValue={[]}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                options={options}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="notes"
              label="Notes"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter task notes",
              //   },
              // ]}
            >
              <TextArea rows={4} placeholder="Please enter Task notes" />
            </Form.Item>
          </Col>
        </Row>
        <Collapse items={items} defaultActiveKey={["1"]} onChange={onChange} />
        <Collapse
          style={{ marginTop: 20 }}
          items={itemstwo}
          defaultActiveKey={["1"]}
          onChange={onChange}
        />
        <Flex className="mt-4" justify="flex-end" align="center">
          <Button onClick={onClose}>Cancel</Button>
          <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
            Create Task
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default MyTaskDrawer;
