import axios from "../../axios";
import config from "../../config";
import {
  ADD_TASK,
  DELETE_TASK,
  GET_TASK,
  UPDATE_TASK,
  update_Pending_Task,
} from "../types";
import { setAlert } from "./alert";

export const addTask = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");

    const res = await axios.post(`${config.API_URL}/tasks/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    // const res = await axios.post("tasks/", formData);
    dispatch({ type: ADD_TASK, payload: res.data });
    dispatch(getTasks());
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getTasks = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/tasks/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // Make the API request with the token
    // const res = await axios.get("contacts", config);
    // console.log("contacts data:", res.data);
    res.data.reverse();
    await dispatch({ type: GET_TASK, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateTasks = (formData, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");

    const res = await axios.patch(`${config.API_URL}/tasks/${id}/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    // const res = await axios.patch("tasks", formData);
    dispatch({ type: UPDATE_TASK, payload: res.data });
    dispatch(getTasks());
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteTask = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const token = localStorage.getItem("Afb");

    const res = await axios.delete(`tasks/${id}/`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: DELETE_TASK, payload: { id } });
    dispatch(getTasks());

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

// update pending via approved
export const updatePendingTask = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const token = localStorage.getItem("Afb");

    const res = await axios.patch(`tasks/${id}/`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: update_Pending_Task, payload: { id } });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
