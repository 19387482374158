import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Checkbox,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import logolight from "../../assets/images/realestatecrm.png";
// import realestatecrm from '../../assets/images/realestatecrm.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../App";
import { getAuth, sendEmailVerification } from "firebase/auth";
// import { google } from "googleapis";
import config from "../../config";
import axios from "axios";

const { Title, Text } = Typography;
let refreshTokenInterval; // Store the interval ID globally to control it
let autoLogoutTimeout; // Store the timeout ID for auto logout

// const startTokenRefresh = (isRemembered, navigate) => {
//   const auth = getAuth();
//   const user = auth.currentUser;

//   if (user) {
//     // Clear any existing interval to prevent multiple intervals
//     if (refreshTokenInterval) {
//       clearInterval(refreshTokenInterval);
//     }

//     // Set refresh interval (50 minutes for normal, 29 days for "Remember me")
//     const refreshInterval = 50 * 60 * 1000;

//     refreshTokenInterval = setInterval(async () => {
//       try {
//         const newToken = await user.getIdToken(true); // Force refresh token
//         localStorage.setItem("Afb", newToken);
//         console.log("Token refreshed!");
//       } catch (error) {
//         console.error("Error refreshing token:", error);
//         // Optional: Handle token refresh failure
//       }
//     }, refreshInterval);

//     // If not "Remember me", set an auto logout after 24 hours
//     if (!isRemembered) {
//       if (autoLogoutTimeout) {
//         clearTimeout(autoLogoutTimeout);
//       }
//       autoLogoutTimeout = setTimeout(() => {
//         console.log("Auto logout after 24 hours.");
//         auth.signOut();
//         localStorage.removeItem("auth");
//         navigate("/login");
//         toast.info("Session expired. Please log in again.");
//       }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
//     }
//   }
// };

const Login = () => {
  document.title = "Login | ProperlyAI";
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("The input is not a valid email!")
        .required("Please input your email!"),
      password: Yup.string().required("Please input your password!"),
    }),
    onSubmit: async (values) => {
      try {
        await auth.signInWithEmailAndPassword(values.username, values.password);
        const user = auth.currentUser;
        // await sendEmailVerification(user);

        // console.log("User here: ", user);
        if (user.emailVerified) {
          const token = await user.getIdToken();
          localStorage.setItem("useremail", values.username);
          localStorage.setItem("Afb", token);
          const expiryTime = Date.now() + 3600000; // 1 hour = 3600000 ms
          localStorage.setItem("_expTM", expiryTime.toString());
          const userName = user.displayName;
          // console.log(user.refreshToken);

          try {
            const response = await axios.get(
              `${config.API_URL}/current-user-id/`,
              {
                headers: {
                  Authorization: `${token}`,
                },
              }
            );

            // console.log("User id ------->", response.user_id);
            localStorage.setItem("properly_UI", response.user_id);

            // localStorage.setItem("properly_UI", response.user_id);
            if (response.google_access_token === null)
              localStorage.removeItem("Gp_TK");
            else
              localStorage.setItem("Gp_TK", response.google_access_token);
          } catch (error) {
            console.error("Error fetching user ID:", error);
            toast.error("Failed to fetch user ID. Please try again.");
          }

          // localStorage.setItem("properly_UI", response.data.user_id);
          toast.success(
            `Welcome back, ${
              userName ? userName : "User"
            }! Redirecting to dashboard...`
          );
          setTimeout(() => navigate("/dashboard"), 2000);
        } else {
          toast.info("Please verify your email before logging in.");
        }
      } catch (error) {
        toast.error(
          "Invalid credentials! Make sure to register & verify your email."
        );
      }
    },
  });
  // Clean up intervals on component unmount (optional, for better resource management)
  // useEffect(() => {
  //   return () => {
  //     if (refreshTokenInterval) {
  //       clearInterval(refreshTokenInterval);
  //     }
  //     if (autoLogoutTimeout) {
  //       clearTimeout(autoLogoutTimeout);
  //     }
  //   };
  // }, []);
  return (
    <>
      <Row gutter={16} style={{ margin: 0 }}>
        {/* <Image src={realestatecrm} style={{position:'absolute', zIndex:2}} preview={false} /> */}
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "#183244", height: "100%" }}>
            <Image src={logolight} preview={false} />
            <Text
              style={{
                backgroundColor: "#183244",
                marginLeft: 60,
                paddinLeft: 60,
                fontSize: "12px",
                paddingRight: 100,
                color: "white",
              }}
            >
              To know more, contact us at support@properlyai.co
            </Text>
          </div>
        </Col>
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div style={styles.container}>
              <div style={styles.formContainer}>
                <Title level={2}>Login</Title>
                <Form
                  layout="vertical"
                  style={styles.form}
                  onFinish={formik.handleSubmit}
                >
                  <Form.Item label="Email">
                    <Input
                      name="username"
                      placeholder="Enter your email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Form.Item>

                  <Form.Item label="Password">
                    <Input.Password
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Flex justify="space-between" align="center">
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox
                          name="remember"
                          checked={formik.values.remember} // Use formik values for controlled state
                          onChange={formik.handleChange} // Use formik handleChange to update state
                        >
                          Remember for 30 days
                        </Checkbox>
                      </Form.Item>
                      <a href="forgotpassword" style={{ color: "#1A4866" }}>
                        Forgot password?
                      </a>
                    </Flex>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%", color: "white" }}
                    >
                      Log In
                    </Button>
                  </Form.Item>
                </Form>
                <Row justify="center" align="middle">
                  <Col>
                    <Row gutter={8}>
                      <Col>
                        <Text style={{ color: "#1A4866" }}>
                          Don’t have an account?
                        </Text>
                      </Col>
                      <Col>
                        <strong>
                          <a href="/register" style={{ color: "#1A4866" }}>
                            Register Now!
                          </a>
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    padding: "20px",
  },
  formContainer: {
    maxWidth: "400px",
    width: "100%",
    padding: "20px",
    paddingTop: "50%",
  },
  form: {
    maxWidth: "100%",
    paddingTop: "10%",
  },
};

export default Login;
