import { ADD_SPOUSE, DELETE_SPOUSE, GET_SPOUSE, UPDATE_SPOUSE } from "../types";
  
  const initialState = { list: [], single: null };
  
  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_SPOUSE:
        return { ...state, list: payload };
  
      case UPDATE_SPOUSE:
        return {
          ...state,
          list: state.list.map((item) =>
            item.id === payload.id ? payload : item
          ),
        };
  
      case ADD_SPOUSE:
        return {
          ...state,
          list: [payload, ...state.list],
        };
  
      case DELETE_SPOUSE:
        return {
          ...state,
          list: state.list.filter((item) => item.id !== payload.id),
        };
  
      default:
        return state;
    }
  };
  
  export default reducer;
  