import React from "react";
import {
  Card,
  Tabs,
} from "antd";

import Mytasks from "./Mytasks";
import Mymeeting from "./Mymeeting";

// const { Title } = Typography;
// const { Option } = Select;

const Company = () => {
  document.title = "Scheduling";

  const onChange = (key) => {
    console.log(key);
  };
  
  const items = [
    {
      key: '1',
      label: 'My Tasks',
      children: <Mytasks />,
    },
    {
      key: '2',
      label: 'My Meetings',
      children: <Mymeeting />,
    }
  ];

  return (
    <div className="page-content">
    <Card title="Tasks" >
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Card>
    </div>
  );
};

export default Company;

