import React, { useEffect } from "react";
import Routes from "./Routes/index";
import firebase from "firebase/compat/app";
import "./index.css";
import "firebase/compat/firestore"; // Import Firestore
import "firebase/compat/auth"; // Import Auth

// Import Scss
import "./assets/scss/theme.scss";

// Fake Backend
// import fakeBackend from "./helpers/AuthType/fakeBackend";
import { ToastContainer } from "react-toastify";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { authLogout } from "./store/actions/authentication";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"
// Activating fake backend
// fakeBackend();

// Firebase

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
// initFirebaseBackend(firebaseConfig)
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize Firebase

// Initialize Firestore
export const firestore = firebase.firestore();

// export const firestore = firebase.firestore();
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebaseApp.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider(); 
googleAuthProvider.addScope("https://www.googleapis.com/auth/gmail.readonly");
googleAuthProvider.addScope("https://www.googleapis.com/auth/calendar");

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FbTKExpiration = async () => {
    const _FbExpTM = localStorage.getItem("_expTM");

    if (!_FbExpTM) {
      return; // No expiration time stored, do nothing
    }

    const currentTime = Date.now();
    const expiryTime = parseInt(_FbExpTM, 10);

    if (currentTime > expiryTime) {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          // Get the access token (Firebase will refresh if expired)
          const token = await user.getIdToken();
          localStorage.setItem("Afb", token);
          const expiryTime = Date.now() + 3600000; // 1 hour = 3600000 ms
          localStorage.setItem("_expTM", expiryTime.toString());
          // console.log("New Access Token:", token);
        } catch (error) {
          console.error("Error getting token:", error);
        }
      }
    }
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("Gp_TK");
    const expirationTime = localStorage.getItem("_GPlf");

    // Check if token and expirationTime exist
    if (!token || !expirationTime) {
      return; // User hasn't connected to Google, so do nothing
    }

    const currentTime = Date.now(); // Get current time in milliseconds
    const expiryTime = parseInt(expirationTime, 10); // Convert to integer

    // Check if the current time is greater than the stored expiration time
    if (currentTime > expiryTime) {
      // Token is expired
      message.info(
        "Google token is expired, please reconnect to sync fresh data."
      );
      localStorage.removeItem("Gp_TK");
      localStorage.removeItem("_GPlf");
    }
  };

  useEffect(() => {
    // Check token expiration on initial load
    checkTokenExpiration();
    FbTKExpiration();

    // Handle user activity (mouse movement or keypress)
    const handleUserActivity = () => {
      checkTokenExpiration();
      FbTKExpiration();
    };

    // Add event listeners for user interaction
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);
  return (
    <React.Fragment>
      <Routes />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
