import React from "react";
import {
  Card,
} from "antd";
import NotesComp from "./Notes";

const Notes = () => {
  document.title = "Notes";

  return (
    <div className="page-content">
    <Card title="Notes" >
    <NotesComp />
    </Card>
    </div>
  )};
export default Notes;