module.exports = {
  google: {
    API_KEY: process.env.REACT_APP_APIKEY,
    CLIENT_ID: process.env.CLIENT_ID,
    SECRET: process.env.SECRET,
  },
  facebook: {
    APP_ID: "",
  },
  API_URL: process.env.REACT_APP_URI, //Change it to production URL in production deply
};
