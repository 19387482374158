import React, { useEffect, useState } from "react";
import { Input, Button, Table, Tag, Image, Flex } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import nodata from "../../assets/images/Nodata-cuate1.png";

import MyNotesDrawer from "./MyNotesDrawer";
import GetNotesDrawer from "./getNotesDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getNote } from "../../store/actions/notes";
import moment from "moment";

const Notes = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notesDrawer, setNotesDrawer] = useState(false);
  const [collectData, setCollectData] = useState([]);
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.list);
  const [searchValue, setSearchValue] = useState(""); // For search input
  const [filterStatus, setFilterStatus] = useState("All"); // For dropdown filter
  const contacts = useSelector((state) => state.contacts.list);

  // console.log("note comp:", notes);

  // Merging data
  const mergedData = notes.map((record) => {
    const additionalRecord = contacts.find(
      (item) => item.id === record.contactId
    );

    return {
      ...record,
      assigneeEmail: additionalRecord ? additionalRecord.email : "Unknown",
    };
  });

  // Filter tasks based on search input and dropdown
  const filteredTasks = mergedData.filter((task) => {
    const matchesSearch = task.title
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    return matchesSearch;
  });

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setFilterStatus(e.key);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      await dispatch(getNote());
    };
    fetchNotes();
  }, [dispatch]);
  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const handleRowClick = (record) => {
    setCollectData(record); // Collect all data of the clicked row
    setNotesDrawer(true); // Open the drawer with the collected data
  };

  // console.log("collectData", collectData);
  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columns = [
    {
      title: "Note Title",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <span style={{ textDecoration: "underline", cursor: "pointer" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "content",
      key: "content",
      render: (text) => {
        // State to track whether text is expanded or not
        const maxLength = 40; // Set your desired maximum length for truncation

        // Check if text is a valid string, otherwise return an empty string or default message
        if (!text) {
          return "No description available"; // You can customize this message
        }

        // Display text based on the expanded state
        return (
          <span>
            {" "}
            {text.length > maxLength
              ? `${text.substring(0, maxLength)}...`
              : text}
          </span>
        );
      },
    },

    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (text) => {
        // Split the text into an array by commas and trim whitespace
        const categories = text
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean);
        const primaryCategory = categories[0]; // First category
        const additionalCount = categories.length - 1; // Count of additional categories

        const color = primaryCategory === "Follow-up" ? "#F3F3F3" : "#F3F3F3"; // You can customize this logic as needed

        return (
          <span>
            <Tag
              style={{
                borderRadius: "2vh",
                color: "black",
                padding: "5px 15px",
                fontSize: "13px",
              }}
              color={color}
            >
              {primaryCategory}
            </Tag>
            {additionalCount > 0 && (
              <Tag
                style={{
                  borderRadius: "2vh",
                  color: "black",
                  padding: "5px 15px",
                  fontSize: "13px",
                }}
                color={color}
              >
                +{additionalCount}
              </Tag>
            )}
          </span>
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeEmail",
      key: "assigneeEmail",
    },
    {
      title: "Created date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const formattedDate = new Date(date).toLocaleDateString(
          "en-US",
          options
        );
        return formattedDate.split("/").reverse().join("."); // Reorder to MM.DD.YYYY
      },
    },
    {
      title: "Last Modified date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        const formattedDate = new Date(date).toLocaleDateString(
          "en-US",
          options
        );
        return formattedDate.split("/").reverse().join("."); // Reorder to MM.DD.YYYY
      },
    },
  ];

  const hasData = notes && notes.length > 0;

  return (
    <div>
      <Flex justify="space-between" align="flex-start">
        <div>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search Notes"
            style={{ width: "320px" }}
            value={searchValue}
            onChange={handleSearchChange} // Capture search input changes
          />
        </div>
        <div>
          <Button type="primary" onClick={() => setDrawerOpen(true)}>
            Create Notes
          </Button>
        </div>
      </Flex>

      {/* Conditionally render centered content or table */}
      {filteredTasks.length == 0 ? (
        <Flex
          justify="center" // Center horizontally
          align="center" // Center vertically
          style={{ height: "80vh" }} // Ensure it takes full viewport height
        >
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>You haven’t added any Notes yet!</strong>
            </div>
            <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
          </div>
        </Flex>
      ) : (
        <Table
          bordered
          style={{ cursor: "pointer" }}
          className="mt-4"
          dataSource={filteredTasks}
          columns={columns}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Handle row click to collect data
          })}
        />
      )}

      <MyNotesDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <GetNotesDrawer
        notesData={collectData}
        open={notesDrawer}
        onClose={() => {
          setNotesDrawer(false);
        }}
      />
    </div>
  );
};

export default Notes;
