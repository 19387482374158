import React, { useEffect } from "react";
// import images
// import logolight from "../../assets/images/logo-light.png";
// import logodark from "../../assets/images/logo-dark.png";
import logolight from '../../assets/images/ECOMMGPT.jpg';
import logodark from '../../assets/images/ECOMMGPT.jpg';
import { Row, Col, Typography } from 'antd';
// import { Container, Row, Col, Card, CardBody, Form } from "reactstrap";
import { Link } from "react-router-dom";

// import

const Login = () => {
  document.title = "Login | ProperlyAI";
    useEffect(() => {
        document.body.className = "bg-pattern";
        // remove classname when component will unmount
        return function cleanup() {
          document.body.className = "";
        };
      });
  return (
    <React.Fragment>
 <Row gutter={16}>
      <Col span={12}>
        <div style={{ backgroundColor: '#aaa', padding: '16px' }}>
          dfgdfg
        </div>
      </Col>
      <Col span={12}>
        <div style={{ backgroundColor: '#bbb', padding: '16px' }}>
        dfgd
        </div>
      </Col>
    </Row>
    </React.Fragment>
  );
};

export default Login;
