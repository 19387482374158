import React, { useEffect, useState } from "react";
import config from "../../config";
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  message,
  Image,
  Card,
  Tag,
  Radio,
  Collapse,
  Divider,
  // Collapse,
  // TimePicker,
} from "antd";
import { Link } from "react-router-dom";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DOMPurify from "dompurify";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import { getTasks } from "../../store/actions/tasks";
import left from "../../assets/figmaAssets/left.svg";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../../store/actions/contacts";
import axios from "axios";
import nodata from "../../assets/images/Nodata-cuate1.png";
import Search from "antd/es/transfer/search";
import { getAdditionalInfo } from "../../store/actions/additionalinfo";
import { getAddress } from "../../store/actions/address";
import { getSpouse } from "../../store/actions/spouse";
import { FiSearch } from "react-icons/fi";
import {
  getEmailsForContact,
  extractEmailData,
} from "../../utils/gmailServices";
import attached from "../../assets/figmaAssets/attachedfile.svg";

const { Panel } = Collapse;
const { Option } = Select;
// const { TextArea } = Input;

const EditContactDrawer = ({ open, onClose, selectedContact }) => {
  const contacts = useSelector((state) => state.contacts.list);
  const Address = useSelector((state) => state.address.list);
  const AdditionalInfo = useSelector((state) => state.additionalinfo.list);
  const Spouse = useSelector((state) => state.spouse.list);
  // console.log("Selected Contact is -> ", selectedContact);

  // console.log(" contacts -> ", contacts);
  // console.log("Address -> ", Address);
  // console.log("AdditionalInf o-> ", AdditionalInfo);
  // console.log("Spouse -> ", Spouse);

  // console.log("Address Data --> ", contacts, Address, AdditionalInfo, Spouse);

  const notes = useSelector((state) => state.notes.list);
  // const contact

  // Assuming selectedContact is an object with an 'id' property
  const selectedContactId = selectedContact?.id;

  // Filter notes to get only those that match the selected contact's id
  const filteredNotes = notes.filter(
    (note) => note.contactId === selectedContactId
  );

  const [defaultTabKey, setDefaultTabKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [mode, setMode] = useState("top");

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setEmailType(e.target.value);
  };

  const [spouseId, setSpouseId] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [additionalId, setAdditionalId] = useState(null);

  const [currentContactId, setContactId] = useState(null);

  const [tempNote, setTempNote] = useState(false);
  const [tempEmail, setTempEmail] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [emails, setEmails] = useState([]);
  const [emailType, setEmailType] = useState("received"); // Default to 'received'

  // primary details
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Ranking, setRanking] = useState("");
  const [email, setEmail] = useState("");
  const [Homephone, setHomephone] = useState("");
  const [Officephone, setOfficephone] = useState("");
  const [Company, setCompany] = useState("");
  const [Dateofbirth, setDateofbirth] = useState("");
  const [Source, setSource] = useState("");
  //
  const [SpouseFirstname, setSpouseFirstname] = useState("");
  const [SpouseLastname, setSpouseLastname] = useState("");
  const [Spouseemail, setSpouseEmail] = useState("");
  const [Spousephone, setSpousephone] = useState("");
  const [SpouseDateofbirth, setSpouseDateofbirth] = useState("");
  const [Spouseanniversary, setSpouseAniversary] = useState("");

  const [AddressType, setAddressType] = useState("");
  const [AddressLineOne, setAddressLineOne] = useState("");
  const [AddressLineTwo, setAddressLineTwo] = useState("");
  const [Location, setLocation] = useState("");
  const [Zip, setZip] = useState("");

  const [additionalSource, setAdditionalSource] = useState("");
  const [additionalTags, setAdditionalTags] = useState("");
  const [countryCode, setCountryCode] = useState("+1"); // Default country code
  const [countryCode2, setCountryCode2] = useState("+1"); // Default country code
  const [countryCode3, setCountryCode3] = useState("+1"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number input value
  const { Option } = Select;

  const [ArrayAddresses, setAddresses] = useState([]);

  // Function to filter emails based on search term (to, from, or subject)
  const filteredEmails = emails?.filter((email) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      email.to.toLowerCase().includes(lowercasedSearchTerm) ||
      email.from.toLowerCase().includes(lowercasedSearchTerm) ||
      email.subject.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  const selectBefore = (
    <Select
      defaultValue={countryCode}
      // disabled={true}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode(value)} // Update country code
    >
      <Option value="+1">+1</Option>
      {/* Add more country codes as needed */}
    </Select>
  );

  const selectBefore2 = (
    <Select
      // disabled={true}
      defaultValue={countryCode}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode2(value)} // Update country code
    >
      <Option value="+1">+1</Option>

      {/* Add more country codes as needed */}
    </Select>
  );

  const selectBefore3 = (
    <Select
      defaultValue={countryCode}
      // disabled={true}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode3(value)} // Update country code
    >
      <Option value="+1">+1</Option>

      {/* Add more country codes as needed */}
    </Select>
  );

  const [Status, setStatus] = useState("");
  const onChange = (key) => {
    // console.log(key);
    setDefaultTabKey(key);
  };

  const onFinish = (values) => {
    // console.log("Form values:", values);
    // Handle form submission logic
  };

  const extractCountryCode = (phoneNumber) => {
    // Ensure phoneNumber is a string
    const phoneStr = String(phoneNumber || "");
    if (phoneStr === "")
      return { countryCode: null, restOfNumber: phoneStr };

    if (phoneStr[0] === "+") {
      var countryCode = phoneStr.split(" ")[0]; // Add more conditions for other country codes
      var restOfNumber = phoneStr.slice(countryCode.length + 1); // Remove the countryCode
    }
    else {
      var countryCode = "";
      var restOfNumber = phoneStr;
    }
    return { countryCode, restOfNumber };
  };
  useEffect(() => {
    if (selectedContact) {
      setContactId(selectedContact.id);
      const { countryCode: homeCountryCode, restOfNumber: homePhone } =
        extractCountryCode(selectedContact.home_phone || "");
      const { countryCode: officeCountryCode, restOfNumber: officePhone } =
        extractCountryCode(selectedContact.office_phone || "");

      formikPrimary.setValues({
        firstName: selectedContact.firstName || "",
        lastName: selectedContact.lastName || "",
        email: selectedContact.email || "",
        ranking: selectedContact.ranking || "",
        home_phone: homePhone,
        office_phone: officePhone,
        company: selectedContact.company || "",
        date_of_birth: selectedContact.date_of_birth || null,
        source: selectedContact.source || "",
      });

      setCountryCode(homeCountryCode || "+1");
      setCountryCode2(officeCountryCode || "+1");

      // Set spouse details if there's a matching contactId
      const matchingSpouse = Spouse?.find(
        (spouse) => spouse.contact === selectedContact.id
      );

      if (matchingSpouse) {
        const { countryCode: CountryCodeSpouse, restOfNumber: PhoneSpouse } =
          extractCountryCode(matchingSpouse.phone || "");
        formikSpouse.setValues({
          contact: matchingSpouse.contact,
          firstName: matchingSpouse.firstName,
          lastName: matchingSpouse.lastName,
          email: matchingSpouse.email,
          phone: PhoneSpouse,
          date_of_birth: matchingSpouse.date_of_birth,
          anniversary: matchingSpouse.anniversary,
        });
        setSpouseId(matchingSpouse.id);
        setCountryCode3(PhoneSpouse || "+1");
      }

      // Set multiple addresses
      const matchingAddresses = Address?.filter(
        (address) => address.contact === selectedContact.id
      );
      if (matchingAddresses) {
        // console.log("Add matched==>", matchingAddresses);
        const formattedAddresses = matchingAddresses.map((address) => ({
          id: address.id || null,
          address_type: address.address_type || "",
          address_line1: address.address_line1 || "",
          address_line2: address.address_line2 || "",
          location: address.location || "",
          zip: address.zip || "",
        }));
        formikAddress.setValues({
          addresses:
            formattedAddresses.length > 0
              ? formattedAddresses
              : [
                  {
                    address_type: "",
                    address_line1: "",
                    address_line2: "",
                    location: "",
                    zip: "",
                  },
                ],
        });
        setAddressId(matchingAddresses.id);
      }

      // Set additional info if there's a matching contactId
      const matchingAdditionalInfo = AdditionalInfo?.find(
        (info) => info.contact === selectedContact.id
      );
      if (matchingAdditionalInfo) {
        const Initcategory = matchingAdditionalInfo.tags;
        const categoriesArray =
          Initcategory && Initcategory.split(",").map((item) => item.trim());
        formikAdditional.setValues({
          tags: categoriesArray,
          status: matchingAdditionalInfo.status,
        });
        setAdditionalId(matchingAdditionalInfo.id);
      }
      // console.log(matchingSpouse, matchingAdditionalInfo);
    }

    if (selectedContact && selectedContact.email) {
      const fetchEmails = async () => {
        const fetchedEmails = await getEmailsForContact(
          selectedContact.email,
          emailType
        );
        setEmails(fetchedEmails);
        // console.log("Email Data Fetched=>", fetchedEmails);
      };

      fetchEmails();
    }
  }, [selectedContact, Spouse, AdditionalInfo, Address, emailType]);

  const handlePrevious = () => {
    const tab = parseInt(defaultTabKey, 10);

    // Increment the integer
    const changeTo = tab - 1;

    // Update the state with the new value as a string
    setDefaultTabKey(changeTo.toString());
    // console.log(defaultTabKey);
    // console.log(changeTo);
  };

  const resetValues = () => {
    setFirstname("");
    setLastname("");
    setRanking("");
    setEmail("");
    setHomephone("");
    setOfficephone("");
    setCompany("");
    setDateofbirth("");
    setSource("");
    setSpouseFirstname("");
    setSpouseLastname("");
    setSpouseEmail("");
    setSpousephone("");
    setSpouseDateofbirth("");
    setSpouseAniversary("");
    setAddressType("");
    setAddressLineOne("");
    setAddressLineTwo("");
    setLocation("");
    setZip("");
    setAdditionalSource("");
    setAdditionalTags("");
  };

  const handleClose = () => {
    resetValues();
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    form4.resetFields();

    setDefaultTabKey("1");
    formikPrimary.resetForm();
    formikSpouse.resetForm();
    formikAddress.resetForm();
    formikAdditional.resetForm();
    onClose();
  };

  const handleTabClick = (key) => {
    // console.log(`Tab with key ${key} clicked`);
    // Add custom logic here based on the clicked tab key
  };
  // createContact('YOUR_ACCESS_TOKEN_HERE', contactDetails);

  const formikPrimary = useFormik({
    initialValues: {
      author: 3,
      // firstName: Firstname,
      lastName: Lastname,
      ranking: Ranking,
      location: "",
      email: "",
      last_interaction: new Date().toISOString(),
      home_phone: "",
      office_phone: "",
      company: "",
      date_of_birth: "",
      // source: "Default",
      source: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      // console.log("Submitting form with values:", values); // Log form values

      try {
        if (values.firstName) {
          const finalHomePhone =
          parseInt(values.home_phone)
              ? `${countryCode} ${values.home_phone}`
              : null;

          const finalofficePhone =
          parseInt(values.office_phone)
              ? `${countryCode2} ${values.office_phone}`
              : null;
          

          const idToken = localStorage.getItem("Afb"); // Example token retrieval
          const authId = localStorage.getItem("properly_UI");

          const contactPayload = {
            author: authId,
            firstName: values.firstName,
            lastName: values.lastName || "", // Default to empty string
            ranking: values.ranking || "", // Default to empty string
            email: values.email || "", // Default to empty string
            last_interaction: new Date().toISOString(),
            company: values.company || null,
            date_of_birth: values.date_of_birth || null, // Default to empty string
            home_phone: finalHomePhone, // Ensure valid integer or null
            office_phone: finalofficePhone, // Ensure valid integer or null
            source: values.source || null, // Ensure valid enum or null
          };

          // console.log("API Request Payload:", contactPayload);

          // First API: Create the contact and retrieve contactId
          const responseContact = await fetch(
            `${config.API_URL}/contacts/${selectedContact.id}/`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(contactPayload),
            }
          );

          if (!responseContact.ok) {
            console.error("API response error:", responseContact.status, responseContact.statusText); // Log response error
            message.error(
              "Something went wrong while creating contact. Try again!"
            ); 
            throw new Error("Failed to create contact.");
          }

          // console.log("Contact creation response:", await responseContact.json()); // Log successful response

          setFirstname(values.firstName);
          setLastname(values.lastName);
          setRanking(values.ranking);
          setLocation(values.location);
          setEmail(values.email);
          setDateofbirth(values.date_of_birth);
          setCompany(values.company);
          setHomephone(finalHomePhone);
          setOfficephone(finalofficePhone);
          setSource(values.source);
          dispatch(getContacts());
          setDefaultTabKey("2");
          message.success("Primary details updated successfully !");
        } else {
          message.error("Something went wrong while updating contact !");
        }
      } catch (error) {
        console.error("UKNOWN Error during contact creation:", error.message);
        message.error("Fill details to create contact");
      }
    },
  });

  // Formik for Spouse Info

  const formikSpouse = useFormik({
    initialValues: {
      // author: 3,
      contact: 4,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      date_of_birth: "",
      anniversary: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (currentContactId) {
        try {
          // Prepare spousePayload with blank values if fields are not provided
          const spousePayload = {
            contact: currentContactId,
            firstName: values.firstName || "", // Default to empty string
            lastName: values.lastName || "", // Default to empty string
            email: values.email || "", // Default to empty string
            phone: values.phone || null, // Default to empty string
            date_of_birth: values.date_of_birth || null, // Default to empty string
            anniversary: values.anniversary || null, // Default to empty string
          };

          const idToken = localStorage.getItem("Afb"); // Example token retrieval
          const authId = localStorage.getItem("properly_UI");

          // First API: Create the contact and retrieve contactId
          const responseSpouse = await fetch(
            `${config.API_URL}/spouse/${spouseId}/`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(spousePayload),
            }
          );

          if (!responseSpouse.ok) {
            // message.error(
            //   "Something went wrong while updating spouse. Try again!"
            // );
            throw new Error(
              "Something went wrong while updating spouse. Try again!"
            );
          }

          message.success("Spouse details updated successfully");
          dispatch(getSpouse());

          setSpouseFirstname(values.firstName);
          setSpouseLastname(values.lastName);
          setSpouseAniversary(values.anniversary);
          setSpouseDateofbirth(values.date_of_birth);
          setSpousephone(values.phone);
          setSpouseEmail(values.email);
          setDefaultTabKey("3");
          // console.log(
          //   SpouseDateofbirth,
          //   SpouseFirstname,
          //   SpouseLastname,
          //   Spouseanniversary,
          //   Spouseemail,
          //   Spousephone
          // );
        } catch (error) {
          message.error(
            error || "Something went wrong while updating the spouse details"
          );
        }
      } else {
        console.log("Something went wrong!");
        message.error("Contact credentials are invalid !");
      }
    },
  });

  // Formik for Address
  const formikAddress = useFormik({
    initialValues: {
      addresses: [
        {
          id: null,
          address_type: "",
          address_line1: "",
          address_line2: "",
          location: "",
          zip: "",
        },
      ],
    },
    onSubmit: async (values) => {
      if (currentContactId) {
        try {
          // Prepare payload
          const payload = {
            addresses: values.addresses.map((address) => ({
              id: address.id || null, // Include id if updating, or null if new
              address_type: address.address_type,
              address_line1: address.address_line1,
              address_line2: address.address_line2,
              location: address.location,
              zip: address.zip || null,
            })),
          };
          // console.log("Address ==>", payload);

          // Prepare updatedAddressPayload with blank values
          const updatedAddressPayload = {
            contact_id: currentContactId,
            addresses: payload.addresses,
          };

          const idToken = localStorage.getItem("Afb"); // Example token retrieval
          const authId = localStorage.getItem("properly_UI");

          // First API: Create the contact and retrieve contactId
          const responseAddress = await fetch(
            `${config.API_URL}/address/bulk-update/`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(updatedAddressPayload),
            }
          );

          if (!responseAddress.ok) {
            // message.error(
            //   "Something went wrong while updating spouse. Try again!"
            // );
            throw new Error(
              "Something went wrong while updating addresses. Try again!"
            );
          }

          message.success("Addresses updated successfully");
          dispatch(getAddress());

          setAddresses(payload);
          // API call logic can go here
          setDefaultTabKey("4");
          // message.success("Addresses saved successfully!");
        } catch (error) {
          message.error(
            error || "Something went wrong while updating addresses !"
          );
        }
      } else {
        message.error("Something went wrong!");
        console.log("Something went wrong!");
      }
    },
  });

  const addAddress = () => {
    const newAddress = {
      address_type: "",
      address_line1: "",
      address_line2: "",
      location: "",
      zip: "",
    };
    formikAddress.setFieldValue("addresses", [
      ...formikAddress.values.addresses,
      newAddress,
    ]);
  };

  const removeAddress = (index) => {
    const updatedAddresses = formikAddress.values.addresses.filter(
      (_, i) => i !== index
    );
    formikAddress.setFieldValue("addresses", updatedAddresses);
  };

  // const handleCreateContact = async (tag, status) => {
  //   if (Firstname) {
  //     try {
  //       const idToken = localStorage.getItem("Afb"); // Example token retrieval
  //       const authId = localStorage.getItem("properly_UI");

  //       // Create contactPayload with optional fields defaulting to blank if not provided
  //       const contactPayload = {
  //         author: authId,
  //         firstName: Firstname,
  //         lastName: Lastname || "", // Default to empty string
  //         ranking: Ranking || "", // Default to empty string
  //         email: email || "", // Default to empty string
  //         last_interaction: new Date().toISOString(),
  //         company: Company || null,
  //         date_of_birth: Dateofbirth || null, // Default to empty string
  //         home_phone: Homephone ? parseInt(Homephone) : null, // Ensure valid integer or null
  //         office_phone: Officephone ? parseInt(Officephone) : null, // Ensure valid integer or null
  //         source: Source || null, // Ensure valid enum or null
  //       };

  //       // First API: Create the contact and retrieve contactId
  //       const responseContact = await fetch(
  //         `${config.API_URL}/contacts/${selectedContact.id}/`,
  //         {
  //           method: "PATCH",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `${idToken}`,
  //           },
  //           body: JSON.stringify(contactPayload),
  //         }
  //       );

  //       if (!responseContact.ok) {
  //         message.error(
  //           "Something went wrong while creating contact. Try again!"
  //         );
  //         throw new Error("Failed to create contact.");
  //       }

  //       const responseData = await responseContact.json();
  //       const contactId = responseData.id; // Store the contactId
  //       setContactId(contactId); // Set contactId in the state for later use
  //       console.log("Contact created successfully, ID: ", contactId);

  //       // Prepare spousePayload with blank values if fields are not provided
  //       const spousePayload = {
  //         contact: contactId,
  //         firstName: SpouseFirstname || "", // Default to empty string
  //         lastName: SpouseLastname || "", // Default to empty string
  //         email: Spouseemail || "", // Default to empty string
  //         phone: Spousephone || null, // Default to empty string
  //         date_of_birth: SpouseDateofbirth || null, // Default to empty string
  //         anniversary: Spouseanniversary || null, // Default to empty string
  //       };

  //       // Prepare updatedAddressPayload with blank values
  //       const updatedAddressPayload = {
  //         contact_id: contactId,
  //         addresses: ArrayAddresses.addresses,
  //       };

  //       // Prepare additionalPayload
  //       const additionalPayload = {
  //         contact: contactId,
  //         tags: tag || "", // Default to empty string
  //         status: status || "", // Default to empty string
  //       };

  //       // Make API calls regardless of blank fields
  //       const [responseSpouse, responseAddress, responseAdditional] =
  //         await Promise.all([
  //           fetch(`${config.API_URL}/spouse/${spouseId}/`, {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `${idToken}`,
  //             },
  //             body: JSON.stringify(spousePayload),
  //           }),
  //           fetch(`${config.API_URL}/address/bulk-update/`, {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `${idToken}`,
  //             },
  //             body: JSON.stringify(updatedAddressPayload),
  //           }),
  //           fetch(`${config.API_URL}/additionalinfo/${additionalId}/`, {
  //             method: "PATCH",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `${idToken}`,
  //             },
  //             body: JSON.stringify(additionalPayload),
  //           }),
  //         ]);

  //       // Check all API responses for success
  //       if (responseSpouse.ok && responseAddress.ok && responseAdditional.ok) {
  //         dispatch(getContacts()); // Refresh contact list
  //         dispatch(getAdditionalInfo());
  //         dispatch(getAddress());
  //         dispatch(getSpouse());
  //         setLoading(false);
  //         toast.success("Contact and all details updated successfully");
  //         // handleClose();
  //       } else {
  //         throw new Error(
  //           "Failed to save spouse, address, or additional info."
  //         );
  //       }
  //     } catch (error) {
  //       // In case any API call fails, delete the contact
  //       // if (contactId) {
  //       //   try {
  //       //     const idToken = localStorage.getItem("Afb");
  //       //     const responseDelete = await fetch(
  //       //       `${config.API_URL}/contacts/delete/${contactId}/`,
  //       //       {
  //       //         method: "DELETE",
  //       //         headers: {
  //       //           "Content-Type": "application/json",
  //       //           Authorization: `${idToken}`,
  //       //         },
  //       //       }
  //       //     );

  //       //     if (responseDelete.ok) {
  //       //       console.log(
  //       //         `Contact with ID ${contactId} has been deleted successfully.`
  //       //       );
  //       //     } else {
  //       //       console.error(`Failed to delete contact with ID ${contactId}.`);
  //       //     }
  //       //   } catch (deleteError) {
  //       //     console.error(
  //       //       "Error during contact deletion:",
  //       //       deleteError.message
  //       //     );
  //       //   }
  //       // }

  //       console.log("Failed to update contact.");
  //       // Handle any other errors that occurred
  //       message.error(`Error: ${error.message}`);
  //       setLoading(false);
  //     }
  //   } else {
  //     message.error("Firstname is required to create a contact.");
  //   }
  // };

  // Formik for Additional Info

  const formikAdditional = useFormik({
    initialValues: {
      tags: "",
      status: "",
    },

    onSubmit: async (values) => {
      const commaSeparatedString = values.tags ? values.tags.join(",") : ""; // Join array into a string

      if (additionalId) {
        try {
          const additionalPayload = {
            contact: currentContactId,
            tags: commaSeparatedString || "", // Default to empty string
            status: values.status || "", // Default to empty string
          };

          const idToken = localStorage.getItem("Afb"); // Example token retrieval
          const authId = localStorage.getItem("properly_UI");

          // First API: Create the contact and retrieve contactId
          const responseAdditional = await fetch(
            `${config.API_URL}/additionalinfo/${additionalId}/`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(additionalPayload),
            }
          );

          if (!responseAdditional.ok) {
            throw new Error(
              "Something went wrong while updating additional info. Try again!"
            );
          }

          message.success("Additional info updated successfully");
          dispatch(getAdditionalInfo());
          setDefaultTabKey("5");

          // Set the values and log the previous and updated states
          setAdditionalTags(commaSeparatedString);
          setStatus(values.status);

          // await handleCreateContact(commaSeparatedString, values.status);
        } catch (error) {
          message.error(
            error || "Something went wrong while updating additional info !"
          );
        }
      } else {
        message.error("Something went wrong!");
        console.log("Something went wrong!");
      }
    },
  });

  const items = [
    {
      key: "1",
      label: "Primary details",
      children: (
        <Form
          layout="vertical"
          requiredMark={true}
          form={form1}
          onFinish={formikPrimary.handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name="firstname"
                label="First Name"
                // validateStatus={formikPrimary.errors.firstName ? "error" : ""}
                // help={formikPrimary.errors.firstName}
                onChange={(value) =>
                  formikPrimary.setFieldValue("firstname", value)
                }
                rules={[
                  {
                    required: true,
                    message: "Enter the first name",
                  },
                ]}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  name="firstName"
                  placeholder="Please enter First Name"
                  value={formikPrimary.values.firstName}
                  onChange={formikPrimary.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" style={{ marginBottom: "16px" }}>
                <Input
                  name="lastName"
                  placeholder="Please enter Last Name"
                  value={formikPrimary.values.lastName}
                  onChange={formikPrimary.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Ranking"
                validateStatus={formikPrimary.errors.ranking ? "error" : ""}
                help={formikPrimary.errors.ranking}
                style={{ marginBottom: "16px" }}
              >
                <Select
                  name="ranking"
                  placeholder="Select Ranking"
                  value={formikPrimary.values.ranking}
                  onChange={(value) =>
                    formikPrimary.setFieldValue("ranking", value)
                  }
                >
                  <Option value="A">A</Option>
                  <Option value="B">B</Option>
                  <Option value="C">C</Option>
                  <Option value="D">D</Option>
                  <Option value="E">E</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name="email"
                label="Email"
                style={{ marginBottom: "16px" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the email",
                //   },
                // ]}
              >
                <Input
                  name="email"
                  type="email"
                  placeholder="Please enter email"
                  value={formikPrimary.values.email}
                  onChange={formikPrimary.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name="home_phone"
                label="Home Phone Number"
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the phone number",
                //   },
                // ]}
                style={{ marginBottom: "16px" }}
              >
                {/* <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="Enter Office Phone number"
                  value={formikPrimary.values.home_phone}
                  onChange={(value) =>
                    formikPrimary.setFieldValue("home_phone", value)
                  }
                /> */}
                <Input
                  addonBefore={selectBefore}
                  placeholder="Enter Home Phone number"
                  type="text" // Keep it as text to allow flexibility
                  value={formikPrimary.values.home_phone}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikPrimary.setFieldValue("home_phone", value);
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name="office_phone"
                label="Office Phone Number"
                validateStatus={
                  formikPrimary.errors.office_phone ? "error" : ""
                }
                help={formikPrimary.errors.office_phone}
                style={{ marginBottom: "16px" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the office Phone number",
                //   },
                // ]}
              >
                {/* <PhoneInput
                  international
                  defaultCountry="US"
                  style={{}}
                  placeholder="Enter Office Phone number"
                  value={formikPrimary.values.office_phone}
                  onChange={(value) =>
                    formikPrimary.setFieldValue("office_phone", value)
                  }
                /> */}
                <Input
                  addonBefore={selectBefore2}
                  placeholder="Enter Office Phone number"
                  type="text" // Keep it as text to allow flexibility
                  value={formikPrimary.values.office_phone}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikPrimary.setFieldValue("office_phone", value);
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Company"
                validateStatus={formikPrimary.errors.company ? "error" : ""}
                help={formikPrimary.errors.company}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  name="company"
                  placeholder="Please enter Company"
                  value={formikPrimary.values.company}
                  onChange={formikPrimary.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* <Form.Item
                name="Dateofbirth"
                label="Date of Birth"
                validateStatus={
                  formikPrimary.errors.date_of_birth ? "error" : ""
                }
                help={formikPrimary.errors.date_of_birth}
                style={{ marginBottom: "16px" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the office Phone number",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={
                    formikPrimary.values.date_of_birth
                      ? moment(formikPrimary.values.date_of_birth)
                      : null
                  } // Ensure to wrap with moment()
                  onChange={(value) => {
                    // Format the date to YYYYMMDD using moment
                    const formattedDate = value
                      ? moment(value).format("YYYY-MM-DD")
                      : null;
                    formikPrimary.setFieldValue("date_of_birth", formattedDate);
                  }}
                />
              </Form.Item> */}

              <Form.Item
                // name="Dateofbirth"
                label="Date of Birth"
                validateStatus={
                  formikPrimary.errors.date_of_birth ? "error" : ""
                }
                help={formikPrimary.errors.date_of_birth}
                style={{ marginBottom: "16px" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the date of birth",
                //   },
                // ]}
              >
                <DatePicker
                  name="Dateofbirth"
                  style={{ width: "100%" }}
                  value={
                    formikPrimary.values.date_of_birth
                      ? moment(formikPrimary.values.date_of_birth, "YYYY-MM-DD") // Ensure the correct format is used
                      : null
                  }
                  onChange={(date, dateString) => {
                    // Use dateString directly, which is in the correct format already
                    formikPrimary.setFieldValue("date_of_birth", dateString);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name="source"
                label="Source"
                style={{ marginBottom: "16px" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the source",
                //   },
                // ]}
                validateStatus={formikPrimary.errors.source ? "error" : ""}
                help={formikPrimary.errors.source}
              >
                <Select
                  name="source"
                  placeholder="Select source"
                  value={formikPrimary.values.source}
                  onChange={(value) =>
                    formikPrimary.setFieldValue("source", value)
                  }
                >
                  <Option value="Client Referral">Client Referral</Option>
                  <Option value="Website">Website</Option>
                  <Option value="Social Media">Social Media</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Flex className="mt-4" justify="flex-end" align="center">
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button
              htmlType="submit"
              onClick={() => formikPrimary.handleSubmit}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Save & Next
            </Button>
          </Flex>
        </Form>
      ),
    },
    {
      key: "2",
      label: "Spouse Info",
      children: (
        <Form
          layout="vertical"
          onFinish={formikSpouse.handleSubmit}
          form={form2}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name={"firstName"}
                label="First Name"
                validateStatus={formikSpouse.errors.firstName ? "error" : ""}
                help={formikSpouse.errors.firstName}
                style={{ width: "100%" }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the first name",
                //   },
                // ]}
              >
                <Input
                  name="firstName"
                  placeholder="Please enter First Name"
                  value={formikSpouse.values.firstName}
                  onChange={formikSpouse.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name={"lastName"}
                label="Last Name"
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the last name",
                //   },
                // ]}
                validateStatus={formikSpouse.errors.lastName ? "error" : ""}
                help={formikSpouse.errors.lastName}
              >
                <Input
                  name="lastName"
                  placeholder="Please enter Last Name"
                  value={formikSpouse.values.lastName}
                  onChange={formikSpouse.handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name={"email"}
                label="Email"
                validateStatus={formikSpouse.errors.email ? "error" : ""}
                help={formikSpouse.errors.email}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the email",
                //   },
                // ]}
              >
                <Input
                  name="email"
                  placeholder="Please enter Email"
                  type="email"
                  value={formikSpouse.values.email}
                  onChange={formikSpouse.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name={"phone"}
                label="Phone Number"
                validateStatus={formikSpouse.errors.phone ? "error" : ""}
                help={formikSpouse.errors.phone}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the phone number",
                //   },
                // ]}
              >
                {/* <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="Enter your Phone number"
                  value={formikSpouse.values.phone}
                  onChange={(value) =>
                    formikSpouse.setFieldValue("phone", value)
                  }
                /> */}

                <Input
                  addonBefore={selectBefore3}
                  placeholder="Enter your Home Phone number"
                  type="text" // Keep it as text to allow flexibility
                  value={formikSpouse.values.phone}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikSpouse.setFieldValue("phone", value);
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date of birth"
                // name="date_of_birth"
                validateStatus={
                  formikSpouse.errors.date_of_birth ? "error" : ""
                }
                help={formikSpouse.errors.date_of_birth}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the date of birth",
                //   },
                // ]}
              >
                <DatePicker
                  name="date_of_birth"
                  style={{ width: "100%" }}
                  placeholder="Please enter Date of birth"
                  value={
                    formikSpouse.values.date_of_birth
                      ? moment(formikSpouse.values.date_of_birth, "YYYY-MM-DD")
                      : null
                  } // Correctly format the date for display
                  onChange={(date, dateString) => {
                    // dateString is already in YYYY-MM-DD format
                    formikSpouse.setFieldValue("date_of_birth", dateString);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Anniversary"
                // name="anniversary"
                validateStatus={formikSpouse.errors.anniversary ? "error" : ""}
                help={formikSpouse.errors.anniversary}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the anniversary date",
                //   },
                // ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Please enter Anniversary"
                  value={
                    formikSpouse.values.anniversary
                      ? moment(formikSpouse.values.anniversary, "YYYY-MM-DD")
                      : null
                  } // Correctly format the date for display
                  onChange={(date, dateString) => {
                    // Use dateString which is in YYYY-MM-DD format
                    formikSpouse.setFieldValue("anniversary", dateString);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>

          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={() => handlePrevious()}>Previous</Button>
            <Button
              style={{ marginLeft: 10 }}
              htmlType="submit"
              type="primary"
              onClick={() => formikSpouse.submitForm}
            >
              Save & Next
            </Button>
          </div>
        </Form>
      ),
    },
    {
      key: "3",
      label: "Address",
      children: (
        <Form layout="vertical" onSubmit={formikAddress.handleSubmit}>
          {formikAddress.values.addresses.map((address, index) => (
            <div key={index} style={{ marginTop: "15px" }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Type"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_type
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_type
                        ? formikAddress.errors.addresses[index].address_type
                        : ""
                    }
                  >
                    <Select
                      placeholder="Select Address Type"
                      value={address.address_type}
                      onChange={(value) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_type = value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                    >
                      <Option value="home">Home</Option>
                      <Option value="office">Office</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Line 1"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line1
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line1
                        ? formikAddress.errors.addresses[index].address_line1
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter address line 1"
                      value={address.address_line1}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_line1 = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Line 2"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line2
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line2
                        ? formikAddress.errors.addresses[index].address_line2
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter address line 2"
                      value={address.address_line2}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_line2 = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Location"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.location
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.location
                        ? formikAddress.errors.addresses[index].location
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter State"
                      value={address.location}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].location = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Zip"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.zip
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.zip
                        ? formikAddress.errors.addresses[index].zip
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter Zip code"
                      type="number"
                      value={address.zip}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].zip = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="danger"
                style={{ color: "#1A4866" }}
                onClick={() => removeAddress(index)}
              >
                Remove Address
              </Button>
            </div>
          ))}

          <Flex
            className="mt-4"
            justify="space-between"
            align="center"
            style={{ width: "100%" }}
          >
            <Button
              type="secondary"
              onClick={addAddress}
              style={{ marginBottom: 16, color: "#1A4866" }}
            >
              +Add more
            </Button>

            <div>
              <Button onClick={handlePrevious}>Previous</Button>
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                onClick={formikAddress.handleSubmit}
              >
                Save & Next
              </Button>
            </div>
          </Flex>
        </Form>
      ),
    },
    {
      key: "4",
      label: "Additional Info",
      children: (
        <Form
          layout="vertical"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            formikAdditional.handleSubmit(); // Trigger Formik's submit handler
          }}
          form={form4}
          noValidate
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // name="tags"
                label="Tags"
                validateStatus={formikAdditional.errors.tags ? "error" : ""}
                help={formikAdditional.errors.tags}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the Tags",
                //   },
                // ]}
              >
                <Select
                  name="tags"
                  mode="tags"
                  placeholder="Select Tags"
                  value={formikAdditional.values.tags} // Keep it as an array
                  onChange={(value) =>
                    formikAdditional.setFieldValue("tags", value)
                  }
                >
                  {/* <Option value="default">Default</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name="status"
                label="Status"
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the Status",
                //   },
                // ]}
              >
                <Select
                  name="status"
                  placeholder="Select Status"
                  value={formikAdditional.values.status}
                  onChange={(value) =>
                    formikAdditional.setFieldValue("status", value)
                  }
                >
                  <Option value="Active">Active</Option>
                  <Option value="Prospective">Prospective</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Flex className="mt-4" justify="flex-end" align="center">
            <Button onClick={() => handlePrevious()}>Previous</Button>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              loading={loading}
              onClick={() => formikAdditional.handleSubmit()}
            >
              Save & Next
            </Button>
          </Flex>
        </Form>
      ),
    },
    {
      key: "5",
      label: "Notes",
      children: (
        <>
          {filteredNotes.length === 0 ? (
            <Flex justify="center" style={{ height: "80vh" }}>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div>
                  <strong>You haven’t added any Notes yet!</strong>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button type="primary">
                    <Link to={"/notes"}> + Add Note</Link>
                  </Button>
                </div>
                <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
              </div>
            </Flex>
          ) : (
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              {filteredNotes.map((note) => (
                <Col span={24} style={{ marginBottom: "10px" }} key={note.id}>
                  <Card className="mb-2" style={{ background: "#F9FAFB" }}>
                    <Flex
                      justify="space-between"
                      align="flex-start"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      <div>{note.title}</div>
                      <div>
                        <Tag
                          style={{
                            borderRadius: "2vh",
                            fontWeight: "semibold",
                            fontSize: "12px",
                            padding: "5px 12px",
                            background: "#F3F3F3",
                          }}
                        >
                          Edit Notes
                        </Tag>
                        <Tag
                          style={{
                            borderRadius: "2vh",
                            fontWeight: "bold",
                            fontSize: "12px",
                            padding: "5px 12px",
                            background: "#F3F3F3",
                          }}
                        >
                          Last modified:{" "}
                          {moment(note.updated_at).format("MM.DD.YYYY")}
                        </Tag>
                      </div>
                    </Flex>
                    <div className="mt-3" style={{ fontSize: "14px" }}>
                      {note.content}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      ),
    },
    {
      key: "6",
      label: "Emails",
      children: (
        <>
          {tempEmail ? (
            <Flex justify="center" style={{ height: "80vh" }}>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div>
                  <strong>
                    You haven’t sent or received any <br /> Emails yet!
                  </strong>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button type="primary" href="/communication">
                    Send Emails
                  </Button>
                </div>
                <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
              </div>
            </Flex>
          ) : (
            <div>
              <Row>
                <Col span={12}>
                  <Radio.Group
                    onChange={handleModeChange}
                    value={emailType}
                    style={{
                      marginBottom: 8,
                      height: "18px",
                    }}
                    // className="email-switcher"
                  >
                    <Radio.Button
                      value="sent"
                      style={
                        emailType == "sent"
                          ? {
                              background: "#20A0EC",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Sent Emails
                    </Radio.Button>
                    <Radio.Button
                      value="received"
                      style={
                        emailType == "received"
                          ? {
                              background: "#20A0EC",
                              color: "white",
                            }
                          : null
                      }
                    >
                      Received Emails
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    // label="Task Name"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Enter template title to search templates",
                    //   },
                    // ]}
                  >
                    {/* Search Input */}
                    <Input
                      prefix={<FiSearch />}
                      value={searchTerm}
                      placeholder="Search by To, From, or Subject"
                      type="search"
                      onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={24} style={{ marginBottom: "10px" }}>
                  {filteredEmails && filteredEmails.length > 0 ? (
                    <Collapse
                      accordion
                      bordered={false}
                      expandIconPosition="right" // Positioning the down arrow on the right
                      style={{ marginBottom: "15px" }}
                    >
                      {filteredEmails.map((email, index) => {
                        const sanitizedHtml = DOMPurify.sanitize(email.body);
                        return (
                          <Panel
                            key={index}
                            header={
                              <div
                                style={{
                                  fontSize: "15px",
                                  padding: "10px", // Padding for the header
                                }}
                              >
                                <Flex
                                  justify="space-between"
                                  align="flex-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      maxWidth: "307px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    {emailType === "sent"
                                      ? `To: ${email.to}`
                                      : `From: ${email.from}`}
                                  </span>
                                  <div>
                                    <Tag
                                      style={{
                                        borderRadius: "2vh",
                                        fontWeight: "semibold",
                                        fontSize: "12px",
                                        padding: "5px 12px",
                                        background: "#F3F3F3",
                                      }}
                                    >
                                      Compose Email
                                    </Tag>
                                    <Tag
                                      style={{
                                        borderRadius: "2vh",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: "5px 12px",
                                        background: "#F3F3F3",
                                      }}
                                    >
                                      {moment(
                                        email.date,
                                        "ddd, DD MMM YYYY HH:mm:ss Z"
                                      ).format("DD.MM.YYYY")}
                                    </Tag>
                                  </div>
                                </Flex>
                                <Flex
                                  justify="space-between"
                                  align="flex-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  <div style={{ fontSize: "13px" }}>
                                    Subject:{" "}
                                    {email.subject.length > 40
                                      ? `${email.subject.substring(0, 40)}...`
                                      : email.subject}
                                  </div>
                                </Flex>
                              </div>
                            }
                          >
                            <div
                              // className="mb-5"
                              style={{
                                padding: "5px 10px ",
                              }}
                            >
                              {email.body !== "" || null ? (
                                <div
                                  // className="mt-3"
                                  style={{ fontSize: "14px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizedHtml,
                                  }}
                                />
                              ) : (
                                <div
                                  // className="mt-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  No Body
                                </div>
                              )}
                            </div>
                            {email.attachments.length > 0 && (
                              <>
                                <Divider />
                                <Tag
                                  style={{
                                    background: "#E4E7EC",
                                    padding: "8px 10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Attachments ({email.attachments.length}){" "}
                                  <img
                                    style={{ marginLeft: "4px" }}
                                    src={attached}
                                  />
                                </Tag>
                              </>
                            )}
                          </Panel>
                        );
                      })}
                    </Collapse>
                  ) : (
                    <Flex justify="center" style={{ height: "80vh" }}>
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <div>
                          <strong>
                            You haven’t {emailType} any <br /> Emails yet!
                          </strong>
                        </div>
                        <Image
                          style={{ marginTop: 20 }}
                          src={nodata}
                          preview={false}
                        />
                      </div>
                    </Flex>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="View Contact"
      width={720}
      onClose={() => handleClose()}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <CloseOutlined onClick={() => handleClose()} />
        </Space>
      }
    >
      <Tabs
        // defaultActiveKey={defaultTabKey}
        onTabClick={handleTabClick}
        activeKey={defaultTabKey}
        items={items}
        onChange={onChange}
      />
    </Drawer>
  );
};

export default EditContactDrawer;
