import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Checkbox,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import logolight from "../../assets/images/realestatecrm.png";
import keyIcon from "../../assets/images/keyIcon.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../App";

const { Title, Text } = Typography;

const ForgetPassword = () => {
  document.title = "Forget Password | ProperlyAI";
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email("The input is not valid E-mail!")
        .required("Please input your email!"),
    }),
    onSubmit: async (values) => {
      try {

        // console.log("clicked forget pass button");
        const resetPasswordWithEmail = async (email) => {
          try {
            await sendPasswordResetEmail(auth, email);
            toast.success("Password reset email sent successfully.");
            navigate("/login");
          } catch (error) {
            console.error("Error sending password reset email:", error);
          }
        };

        resetPasswordWithEmail(values.username);
      } catch (error) {
        toast.error("An error occurred: " + error.message);
      }
    },
  });

  return (
    <>
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "#183244", height: "100%" }}>
            <Image src={logolight} preview={false} />
            <Text
              style={{
                backgroundColor: "#183244",
                marginLeft: 60,
                paddinLeft: 60,
                paddingRight: 100,
                fontSize: "12px",
                color: "white",
              }}
            >
              To know more, contact us at support@properlyai.co
            </Text>
          </div>
        </Col>
        <Col span={12} style={{ padding: 0 }}>
          <div style={{ backgroundColor: "white", height: "100%" }}>
            <div style={styles.container}>
              <div style={styles.formContainer}>
                <Row justify="center" align="middle">
                  <Col>
                    <Flex align="center" justify="center">
                      <Image
                        src={keyIcon}
                        className="mb-4"
                        preview={false}
                        width="14%"
                        alt="keylogo"
                      />
                    </Flex>
                    <Title level={2} style={{ textAlign: "center" }}>
                      Forgot password?
                    </Title>
                    <Text
                      style={{
                        display: "block",
                        textAlign: "center",
                        color: "#667085",
                      }}
                    >
                      No worries, we’ll send you reset instructions. Please
                      check your email.
                    </Text>
                  </Col>
                </Row>
                {/* <Title level={2} justifyContent='center'>Forgot password?</Title>
                                <Text>No worries, we’ll send you reset instructions. Please check your email.</Text> */}
                <Form
                  layout="vertical"
                  style={styles.form}
                  onFinish={formik.handleSubmit}
                >
                  <Form.Item label="Email">
                    <Input
                      name="username"
                      placeholder="Enter your email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ width: "100%", color: "white" }}
                    >
                      Confirm
                    </Button>
                  </Form.Item>
                </Form>
                <Row justify="center" align="middle">
                  <Col>
                    <Row gutter={8}>
                      <Col color="primary">
                        <strong>
                          <a href="/login" style={{ color: "#667085" }}>
                            <ArrowLeftOutlined /> Back to log in
                          </a>
                        </strong>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    padding: "20px",
  },
  formContainer: {
    maxWidth: "400px",
    width: "100%",
    padding: "20px",
    paddingTop: "50%",
  },
  form: {
    maxWidth: "100%",
    paddingTop: "10%",
  },
};

export default ForgetPassword;
