import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  ClassicEditor,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { Button, Form, Select, message } from "antd";
import magic from "../../assets/images/magic.svg";
import magicBlack from "../../assets/images/magic-black.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Option } from "antd/es/mentions";
import { input } from "@testing-library/user-event/dist/cjs/event/input.js";
import config from "../../config";

const CustomButtonPlugin = (editor) => {
  editor.ui.addButton("CustomButton", {
    label: "Custom Action",
    withText: true,
    tooltip: true,
    // Define the button action
    commandName: "customCommand",
    // The function to run when the button is clicked
    execute: () => {
      // Your custom function logic
      console.log("Custom button clicked!");
    },
  });

  // Register the command to execute on button click
  editor.commands.add("customCommand", {
    execute: () => {
      // Execute your custom logic here
      console.log("Executing custom command!");
    },
  });
};

function Richtext({
  bodyText,
  setBodyText,
  toEmail,
  loading,
  setLoading,
  setSubject,
}) {
  const [toggleWriteAI, setToggleWriteAI] = useState(false);
  const [toggleReWriteAI, setToggleReWriteAI] = useState(false);
  const googleToken = localStorage.getItem("Gp_TK");
  const [aiContent, setAiContent] = useState(""); // Store AI generated content here
  const [descriptionContent, setDescriptionContent] = useState("");
  const token = localStorage.getItem("Afb");
  const [myPrompt, setMyPrompt] = useState("");
  const [promptBtnText, setPromptBtnText] = useState("Write with AI");
  // const [loading, setLoading] = useState(false); // Loading state for button
  const [buttonTxt, setButtonTxt] = useState("Create");
  const [selectedTone, setSelectedTone] = useState("casual");
  const [emailContent, setEmailContent] = useState(
    "<p>Enter the body of the email.</p>"
  );
  // const editorRef = useRef(null); // To hold the editor instance reference
  const handleToggleAI = () => {
    if (googleToken) {
      setToggleWriteAI(!toggleWriteAI);
      return;
    } else {
      message.info("Connect with Google via Settings page to send emails");
    }

    return;
  };

  const formatTextToParagraphs = (text) => {
    // Split text into paragraphs by double newlines
    const paragraphs = text.split(/\n\s*\n/);

    // Join paragraphs with HTML <p> tags
    return paragraphs
      .map((para, index) => `<p key=${index}>${para}</p>`)
      .join("");
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setData(emailContent); // Update CKEditor content
    }
  }, [emailContent]);

  const handleCreateAIContent = async (inputText) => {
    if (myPrompt == "") {
      return message.info("Please provide valid prompt");
    }
    setLoading(true);
    try {
      console.log(selectedTone);
      const response = await fetch(`${config.API_URL}/chatgpt/`, {
        method: "POST",
        headers: {
          Authorization: `${token}`, // Added Bearer prefix
          "Content-Type": "application/json", // Added Content-Type header,
        },
        body: JSON.stringify({
          query: `You are an expert guide & helper assistant in real estate client management. Please answer or respond to the question or request based on the context of evaluating and handling client contacts in the real estate industry. Example questions include 'How should I follow up with a client who showed interest?' or 'What are some effective strategies for client engagement?' or 'Wishing happy birthday or anniversay to clients to maintain relationships'. Do not respond to questions outside this context. Keep in mind that you are generating an email body content for the question or request. Here is the question or request: ${inputText}`,
          tone: selectedTone,
        }),
      });

      // Just for testing below line is added here
      // setDescriptionContent("I am here");

      if (!response.ok) {
        // Try to extract the error message from the response
        const errorMessage = await response.json().catch(() => null);
        throw new Error(errorMessage?.detail || "Failed to fetch AI content");
      }

      const data = await response.json();

      // Format the response message into paragraphs
      // const formattedMessage = formatTextToParagraphs(data.message);

      let formattedText = data.message
        .split("\n\n") // Paragraph breaks
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`) // Handle single line breaks
        .join(""); // Join paragraphs into one string

      // Replace [Your Name] with the email signature
      formattedText = formattedText.replace(
        /\[Your Name\]/g,
        "{{emailSignature}}"
      );

      // Regex to match the subject line
      const subjectMatch = formattedText.match(/Subject:\s*(.*)/i);

      if (subjectMatch && subjectMatch[1]) {
        // Extract subject from the response
        const extractedSubject = subjectMatch[1].trim();
        setSubject(extractedSubject); // Store the subject in state

        // Remove the subject from the body text
        formattedText = formattedText.replace(/Subject:\s*.*\n*/, "");
      }

      setAiContent(data.message); // Assuming response has the generated content
      setDescriptionContent(data.message);
      setEmailContent(data.message);
      console.log(data.message);
      setBodyText(formattedText);
      setButtonTxt("Replace");
      message.success(
        `Email content generated using AI with ${selectedTone} tone.`
      );
      handleToggleAI();
    } catch (error) {
      // Display specific error message
      message.error(`Error: ${error.message}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReWriteWithAI = async () => {
    // if (myPrompt == "") {
    //   return message.info("Please provide valid prompt");
    // }
    setLoading(true);
    try {
      console.log(selectedTone);
      const response = await fetch(`${config.API_URL}/chatgpt/`, {
        method: "POST",
        headers: {
          Authorization: `${token}`, // Added Bearer prefix
          "Content-Type": "application/json", // Added Content-Type header,
        },
        body: JSON.stringify({
          query: `Act as you are an expert in spelling mistake finding work. You have to find errors in spelling at provided paragraph content and replace the wrong spelling with correct spelling. Keep in mind that you have to not change the context and words that are already correct under any circumstances. Just you have to seek if there are any wrong spellings or spelling mistake and make it corrected. Here is the paragraph: " ${bodyText}}`,
          tone: selectedTone,
        }),
      });

      // Just for testing below line is added here
      // setDescriptionContent("I am here");

      if (!response.ok) {
        // Try to extract the error message from the response
        const errorMessage = await response.json().catch(() => null);
        throw new Error(errorMessage?.detail || "Failed to fetch AI content");
      }

      const data = await response.json();
      const formattedMessage = formatTextToParagraphs(data.message);

      setAiContent(formattedMessage); // Assuming response has the generated content
      setDescriptionContent(formattedMessage);
      setEmailContent(formattedMessage);
      setBodyText(formattedMessage);
      // setButtonTxt("Replace");
      message.success(`Content refined using AI`);
    } catch (error) {
      // Display specific error message
      message.error(`Error: ${error.message}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const editorRef = useRef();

  return (
    <>
      {loading ? (
        <Skeleton count={10} />
      ) : (
        <>
          {googleToken ? (
            <>
              <CKEditor
                editor={ClassicEditor}
                data={bodyText}
                config={{
                  toolbar: {
                    items: ["undo", "redo", "|", "bold", "italic", "underline"],
                  },
                  plugins: [
                    Bold,
                    Essentials,
                    Italic,
                    Mention,
                    Paragraph,
                    Undo,
                    Underline,
                  ],
                  // licenseKey: '<YOUR_LICENSE_KEY>',
                  mention: {
                    // Mention configuration
                  },
                  // initialData: bodyText,
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log("editor data: ", data);
                  setBodyText(data);
                }}
              />

              {/* Ṛewrite */}
              {bodyText ? (
                <>
                  <>
                    {" "}
                    <Button
                      onClick={handleReWriteWithAI}
                      style={{
                        // backgroundColor: "#2b6cb0",
                        background:
                          "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                        borderRadius: "24px",
                        padding: "14px 20px",
                        color: "#fff",
                        fontWeight: "bold",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        top: "432px",
                        left: "20px",
                      }}
                    >
                      <img
                        src={magic}
                        alt="magic-wand"
                        style={{
                          marginRight: "8px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      {"Re-Write with AI"}
                    </Button>
                  </>
                </>
              ) : null}

              {/* Write */}

              {bodyText == "" ? (
                <>
                  <>
                    {" "}
                    {toggleWriteAI ? null : (
                      <Button
                        onClick={handleToggleAI}
                        style={{
                          // backgroundColor: "#2b6cb0",
                          background:
                            "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                          borderRadius: "24px",
                          padding: "14px 20px",
                          color: "#fff",
                          fontWeight: "bold",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: "370px",
                          left: "20px",
                        }}
                      >
                        <img
                          src={magic}
                          alt="magic-wand"
                          style={{
                            marginRight: "8px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        Write with AI
                      </Button>
                    )}
                  </>
                  <>
                    {" "}
                    {toggleWriteAI ? (
                      <div>
                        <div
                          className="setToneAI"
                          style={{
                            position: "absolute",
                            top: "170px",
                            left: "22px",
                            width: "140px",
                          }}
                        >
                          <Form.Item
                            name="Select Tone"
                            rules={[
                              { required: true, message: "Select the tone" },
                            ]}
                          >
                            <Select
                              placeholder="Tone"
                              value={selectedTone}
                              onChange={setSelectedTone}
                            >
                              <Option value={"default"}>Tone</Option>
                              <Option value={"casual"}>Casual</Option>
                              <Option value={"semi Formal"}>Semi formal</Option>
                              <Option value={"professional"}>
                                Professional
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div
                          className="magicAI"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            border: "1px solid #2b6cb0",
                            borderRadius: "24px",
                            padding: "10px 15px",
                            marginBottom: "20px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: "350px",
                            left: "20px",
                            width: "540px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* <EditOutlined
                      style={{
                        color: "#2b6cb0",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    /> */}
                            <img
                              src={magicBlack}
                              alt="magic-wand"
                              style={{
                                marginRight: "8px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            {bodyText == "" ? (
                              <input
                                placeholder="Tell us what do you want to write here"
                                type="text"
                                style={{
                                  color: "#5f6c7b",
                                  fontSize: "16px",
                                  border: "none",
                                  outline: "none",
                                  width: "290px",
                                }}
                                onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                              />
                            ) : (
                              <input
                                placeholder="Refine the text with AI"
                                type="text"
                                style={{
                                  color: "#5f6c7b",
                                  fontSize: "16px",
                                  border: "none",
                                  outline: "none",
                                  width: "290px",
                                }}
                                onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                              />
                            )}
                          </div>

                          <div style={{ display: "flex", gap: "10px" }}>
                            <Button
                              onClick={handleToggleAI}
                              type="link"
                              style={{ color: "#2b6cb0", fontWeight: "bold" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              style={{
                                // backgroundColor: "#2b6cb0",
                                background:
                                  "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                                borderColor: "#2b6cb0",
                                borderRadius: "24px",
                                padding: "0 20px",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleCreateAIContent(myPrompt)}
                            >
                              {buttonTxt}
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                </>
              ) : null}
            </>
          ) : (
            <>
              <CKEditor
                editor={ClassicEditor}
                disabled
                config={{
                  toolbar: {
                    items: ["undo", "redo", "|", "bold", "italic", "underline"],
                  },
                  plugins: [
                    Bold,
                    Essentials,
                    Italic,
                    Mention,
                    Paragraph,
                    Undo,
                    Underline,
                  ],
                  // licenseKey: '<YOUR_LICENSE_KEY>',
                  mention: {
                    // Mention configuration
                  },
                  initialData: bodyText,
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setBodyText(data);
                }}
              />
              <div className="" disabled>
                {/* Ṛewrite */}
                {bodyText ? (
                  <>
                    <>
                      {" "}
                      <Button
                        onClick={handleReWriteWithAI}
                        style={{
                          // backgroundColor: "#2b6cb0",
                          background:
                            "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                          borderRadius: "24px",
                          padding: "14px 20px",
                          color: "#fff",
                          fontWeight: "bold",
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: "370px",
                          left: "20px",
                        }}
                      >
                        <img
                          src={magic}
                          alt="magic-wand"
                          style={{
                            marginRight: "8px",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        {"Re-Write with AI"}
                      </Button>
                    </>
                  </>
                ) : null}

                {/* Write */}

                {bodyText == "" ? (
                  <>
                    <>
                      {" "}
                      {toggleWriteAI ? null : (
                        <Button
                          onClick={handleToggleAI}
                          style={{
                            // backgroundColor: "#2b6cb0",
                            background:
                              "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                            borderRadius: "24px",
                            padding: "14px 20px",
                            color: "#fff",
                            fontWeight: "bold",
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: "370px",
                            left: "20px",
                          }}
                        >
                          <img
                            src={magic}
                            alt="magic-wand"
                            style={{
                              marginRight: "8px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          Write with AI
                        </Button>
                      )}
                    </>
                    <>
                      {" "}
                      {toggleWriteAI ? (
                        <div>
                          <div
                            className="setToneAI"
                            style={{
                              position: "absolute",
                              top: "170px",
                              left: "22px",
                              width: "140px",
                            }}
                          >
                            <Form.Item
                              name="Select Tone"
                              rules={[
                                { required: true, message: "Select the tone" },
                              ]}
                            >
                              <Select
                                placeholder="Tone"
                                value={selectedTone}
                                onChange={setSelectedTone}
                              >
                                <Option value={"default"}>Tone</Option>
                                <Option value={"casual"}>Casual</Option>
                                <Option value={"semi Formal"}>
                                  Semi formal
                                </Option>
                                <Option value={"professional"}>
                                  Professional
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className="magicAI"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #2b6cb0",
                              borderRadius: "24px",
                              padding: "10px 15px",
                              marginBottom: "20px",
                              backgroundColor: "white",
                              position: "absolute",
                              top: "350px",
                              left: "20px",
                              width: "540px",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {/* <EditOutlined
                      style={{
                        color: "#2b6cb0",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    /> */}
                              <img
                                src={magicBlack}
                                alt="magic-wand"
                                style={{
                                  marginRight: "8px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              {bodyText == "" ? (
                                <input
                                  placeholder="Tell us what do you want to write here"
                                  type="text"
                                  style={{
                                    color: "#5f6c7b",
                                    fontSize: "16px",
                                    border: "none",
                                    outline: "none",
                                    width: "290px",
                                  }}
                                  onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                                />
                              ) : (
                                <input
                                  placeholder="Refine the text with AI"
                                  type="text"
                                  style={{
                                    color: "#5f6c7b",
                                    fontSize: "16px",
                                    border: "none",
                                    outline: "none",
                                    width: "290px",
                                  }}
                                  onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                                />
                              )}
                            </div>

                            <div style={{ display: "flex", gap: "10px" }}>
                              <Button
                                onClick={handleToggleAI}
                                type="link"
                                style={{ color: "#2b6cb0", fontWeight: "bold" }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="primary"
                                style={{
                                  // backgroundColor: "#2b6cb0",
                                  background:
                                    "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                                  borderColor: "#2b6cb0",
                                  borderRadius: "24px",
                                  padding: "0 20px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => handleCreateAIContent(myPrompt)}
                              >
                                {buttonTxt}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  </>
                ) : null}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Richtext;
