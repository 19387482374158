// reducers.js
import { SET_CONTACTS, SET_EMAILS, SET_CALENDAR_EVENTS } from '../types';



const initialState = {
  contacts: [],          // Update to match action keys
  emails: [],            // Update to match action keys
  calendarEvents: [],   // Update to match action keys
};

export const dataReducer = (state = initialState, action) => {
  // console.log("Reducer action: ", action);  // Add this log for debugging
  switch (action.type) {
    case SET_CONTACTS:
      return { ...state, contacts: action.payload };  // Ensure correct key
    case SET_EMAILS:
      return { ...state, emails: action.payload };    // Ensure correct key
    case SET_CALENDAR_EVENTS:
      return { ...state, calendarEvents: action.payload };  // Ensure correct key
    default:
      return state;
  }
};

// Selectors
export const selectCalendarEvents = (state) => state.data.calendarEvents;
export const selectEmails = (state) => state.data.emails;
export const selectContacts = (state) => state.data.contacts;


