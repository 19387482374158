import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   Alert,
//   CardBody,
//   Button,
//   Label,
//   Input,
//   FormFeedback,
//   Form,
// } from "reactstrap";
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Form,
  Space,
  Button,
  message,
} from "antd";
import { firestore, auth } from "../../App"; // Import firestore from App.js
// import { getAuth } from "firebase/auth";
// import { getAuth } from "firebase/auth";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import { createSelector } from "reselect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Bold,
  ClassicEditor,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Underline,
  Undo,
} from "ckeditor5";
import config from "../../config";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const { Option } = Select;
const { TextArea } = Input;

const usStates = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const usCities = {
  AL: ["Birmingham", "Montgomery", "Huntsville", "Mobile"],
  AK: ["Anchorage", "Juneau", "Fairbanks", "Sitka"],
  AZ: ["Phoenix", "Tucson", "Mesa", "Chandler"],
  AR: ["Little Rock", "Fort Smith", "Fayetteville", "Springdale"],
  CA: ["Los Angeles", "San Francisco", "San Diego", "San Jose", "Sacramento"],
  CO: ["Denver", "Colorado Springs", "Aurora", "Fort Collins"],
  CT: ["Bridgeport", "New Haven", "Stamford", "Hartford"],
  DE: ["Wilmington", "Dover", "Newark", "Middletown"],
  FL: ["Miami", "Orlando", "Tampa", "Jacksonville"],
  GA: ["Atlanta", "Augusta", "Columbus", "Savannah"],
  HI: ["Honolulu", "Hilo", "Kailua", "Kapolei"],
  ID: ["Boise", "Meridian", "Nampa", "Idaho Falls"],
  IL: ["Chicago", "Aurora", "Naperville", "Joliet"],
  IN: ["Indianapolis", "Fort Wayne", "Evansville", "South Bend"],
  IA: ["Des Moines", "Cedar Rapids", "Davenport", "Sioux City"],
  KS: ["Wichita", "Overland Park", "Kansas City", "Olathe"],
  KY: ["Louisville", "Lexington", "Bowling Green", "Owensboro"],
  LA: ["New Orleans", "Baton Rouge", "Shreveport", "Lafayette"],
  ME: ["Portland", "Lewiston", "Bangor", "South Portland"],
  MD: ["Baltimore", "Frederick", "Gaithersburg", "Rockville"],
  MA: ["Boston", "Worcester", "Springfield", "Cambridge"],
  MI: ["Detroit", "Grand Rapids", "Warren", "Sterling Heights"],
  MN: ["Minneapolis", "Saint Paul", "Rochester", "Duluth"],
  MS: ["Jackson", "Gulfport", "Southaven", "Biloxi"],
  MO: ["Kansas City", "Saint Louis", "Springfield", "Independence"],
  MT: ["Billings", "Missoula", "Great Falls", "Bozeman"],
  NE: ["Omaha", "Lincoln", "Bellevue", "Grand Island"],
  NV: ["Las Vegas", "Henderson", "Reno", "North Las Vegas"],
  NH: ["Manchester", "Nashua", "Concord", "Dover"],
  NJ: ["Newark", "Jersey City", "Paterson", "Elizabeth"],
  NM: ["Albuquerque", "Las Cruces", "Rio Rancho", "Santa Fe"],
  NY: ["New York City", "Buffalo", "Rochester", "Yonkers", "Syracuse"],
  NC: ["Charlotte", "Raleigh", "Greensboro", "Durham"],
  ND: ["Fargo", "Bismarck", "Grand Forks", "Minot"],
  OH: ["Columbus", "Cleveland", "Cincinnati", "Toledo"],
  OK: ["Oklahoma City", "Tulsa", "Norman", "Broken Arrow"],
  OR: ["Portland", "Salem", "Eugene", "Gresham"],
  PA: ["Philadelphia", "Pittsburgh", "Allentown", "Erie"],
  RI: ["Providence", "Warwick", "Cranston", "Pawtucket"],
  SC: ["Charleston", "Columbia", "North Charleston", "Mount Pleasant"],
  SD: ["Sioux Falls", "Rapid City", "Aberdeen", "Brookings"],
  TN: ["Nashville", "Memphis", "Knoxville", "Chattanooga"],
  TX: ["Houston", "San Antonio", "Dallas", "Austin", "Fort Worth"],
  UT: ["Salt Lake City", "West Valley City", "Provo", "West Jordan"],
  VT: ["Burlington", "South Burlington", "Rutland", "Essex Junction"],
  VA: ["Virginia Beach", "Norfolk", "Chesapeake", "Richmond"],
  WA: ["Seattle", "Spokane", "Tacoma", "Vancouver"],
  WV: ["Charleston", "Huntington", "Morgantown", "Parkersburg"],
  WI: ["Milwaukee", "Madison", "Green Bay", "Kenosha"],
  WY: ["Cheyenne", "Casper", "Laramie", "Gillette"],
};

const UserProfile = () => {
  document.title = "Profile | ProperlyAI";

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const useremail = localStorage.getItem("useremail");
  const [signature, setSignature] = useState("");
  const [profileId, setProfileId] = useState(null); // Store the profile ID for PATCH request
  const [emailSignature, setEmailSignature] = useState("");
  const userId = localStorage.getItem("properly_UI"); // Replace with actual user ID logic
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState(null);

  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    // Get the user's current timezone when the component mounts
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(userTimeZone);
  }, []);

  useEffect(() => {
    const auth = getAuth();

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Retrieve the displayName from the logged-in user
        setUserName(user.displayName || "");
      } else {
        // Handle if no user is logged in (redirect or show a message)
        // console.log("No user is logged in");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Fetch user profile on component mount (GET)
    fetchUserProfile();
  }, []);

  useEffect(() => {
    // Load email signature from local storage or API
    const storedSignature = localStorage.getItem("emailSignature");
    const storedUserId = localStorage.getItem("userProfileId");
    if (storedSignature) {
      setEmailSignature(storedSignature);
    } else {
      fetchEmailSignature();
    }
  }, []);

  const fetchEmailSignature = async () => {
    try {
      const token = localStorage.getItem("Afb");
      const response = await axios.get(`${config.API_URL}/emailsignature/`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if (response) {
        // console.log(response);
        // console.log(response[0].signature);
        setEmailSignature(response[0].signature);
        localStorage.setItem("emailSignature", response[0].signature);
        localStorage.setItem("SignatureId", response[0].id);
        message.success("Signature retrieved !");
      }
    } catch (error) {
      // message.error("Could not found signature !");
      console.error("Error fetching email signature:", error);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem("Afb");
      const response = await axios.get(`${config.API_URL}/userprofile/`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      console.log(userId);
      console.log(response);
      const matchedProfile = response.find((profile) => profile.user == userId);
      console.log(matchedProfile);
      if (matchedProfile) {
        setProfileId(matchedProfile.id); // Store profile ID for PATCH
        setUserName(matchedProfile.first_name);
        setLastName(matchedProfile.last_name);
        setEmail(matchedProfile.email);
        setWebsite(matchedProfile.website);
        setBusinessName(matchedProfile.business_name);
        setPhoneNumber(matchedProfile.cell_phone);
        setAddressLine1(matchedProfile.address_line_1);
        setaddressLine2(matchedProfile.address_line_2);
        setZip(matchedProfile.zip_code);
        setSelectedCity(matchedProfile.city);
        setSelectedState(matchedProfile.state);
        setIsSaved(true); // Profile exists
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const handleSaveProfile = async () => {
    const payload = {
      first_name: userName || "",
      last_name: lastName || "",
      email: useremail || "",
      website: website || "",
      cell_phone: phoneNumber || "",
      business_name: businessName || "",
      address_line_1: addressLine1 || "",
      address_line_2: addressLine2 || "",
      city: selectedCity || "",
      state: selectedState || "",
      zip_code: Zip || "",
    };
    const token = localStorage.getItem("Afb");
    // Get the current user from Firebase Authentication
    const user = firebase.auth().currentUser;

    if (!Issaved) {
      // POST Request to create new profile
      try {
        await firebase.auth().currentUser.updateProfile({
          displayName: userName || "", // Update the displayName with the new first name
        });

        const response = await axios.post(
          `${config.API_URL}/userprofile/`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setProfileId(response.id); // Save the returned profile ID for PATCH
        setIsSaved(true); // Profile is now saved
        message.success("Profile saved successfully!");
      } catch (error) {
        console.error("Error saving profile:", error);
        message.error("Error saving profile");
      }
    } else {
      // PATCH Request to update existing profile
      try {
        await firebase.auth().currentUser.updateProfile({
          displayName: userName || "", // Update the displayName with the new first name
        });
        const response = await axios.patch(
          `${config.API_URL}/userprofile/${profileId}/`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        message.success("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
        message.error("Error updating profile");
      }
    }
  };

  useEffect(() => {
    // Load saved signature from localStorage or API on mount
    const savedSignature = localStorage.getItem("emailSignature");
    if (savedSignature) {
      setSignature(savedSignature);
    }
  }, []);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // Handle state change when a state is selected
  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(null); // Reset city selection when state changes
  };

  // Handle city change and automatically set state based on city
  const handleCityChange = (value) => {
    setSelectedCity(value);

    // Find the state associated with the selected city
    const foundState = Object.keys(usCities).find((state) =>
      usCities[state].includes(value)
    );
    setSelectedState(foundState || null);
  };

  // Filter cities based on the selected state
  const getCityOptions = () => {
    if (selectedState && usCities[selectedState]) {
      return usCities[selectedState].map((city) => (
        <Option key={city} value={city}>
          {city}
        </Option>
      ));
    }

    // Allow all cities to be selected if no state is chosen
    return Object.keys(usCities).flatMap((state) =>
      usCities[state].map((city) => (
        <Option key={city} value={city}>
          {city}
        </Option>
      ))
    );
  };
  const saveSignature = () => {
    // console.log("I am clicked from sig");

    localStorage.setItem("emailSignature", signature); // Save signature locally
    message.success("Signature saved successfully");
  };

  const handleSave = async () => {
    if (emailSignature !== "") {
      const payload = { signature: emailSignature };

      if (!localStorage.getItem("emailSignature")) {
        const token = localStorage.getItem("Afb");

        // Create if not exists
        try {
          const response = await axios.post(
            `${config.API_URL}/emailsignature/`,
            payload,
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          );
          setEmailSignature(response.data.signature);
          localStorage.setItem("emailSignature", response.data.signature);
          localStorage.setItem("SignatureId", response.data.id);
          message.success("Email signature created successfully!");
        } catch (error) {
          message.error("Error creating email signature.");
          console.error("Error creating email signature:", error);
        }
      } else {
        // Update if exists
        try {
          const token = localStorage.getItem("Afb");
          const signatureId = localStorage.getItem("SignatureId");
          if (signatureId) {
            const response = await axios.patch(
              `${config.API_URL}/emailsignature/${signatureId}/`,
              payload,
              {
                headers: {
                  Authorization: `${token}`,
                },
              }
            );
            setEmailSignature(response.data.signature);
            localStorage.setItem("emailSignature", response.data.signature);
            message.success("Email signature updated successfully!");
          } else {
            message.info("Could not found email signature");
          }
        } catch (error) {
          console.error("Error updating email signature:", error);
        }
      }
    } else {
      message.error("Email Signature can not be blank");
    }
  };

  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [Zip, setZip] = useState("");
  const [countryCode, setCountryCode] = useState("+1"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number input value
  const [Issaved, setIsSaved] = useState(false);

  // const handleSaveProfile = async () => {
  //   const user = auth.currentUser;

  //   if (user) {
  //     const payload = {
  //       first_name: userName || "",
  //       last_name: lastName || "",
  //       email: useremail || "",
  //       website: website || "",
  //       cell_phone: phoneNumber || "",
  //       business_name: businessName || "",
  //       address_line_1: addressLine1 || "",
  //       address_line_2: addressLine2 || "",
  //       city: selectedCity || "",
  //       state: selectedState || "",
  //       zip_code: Zip || "",
  //     };
  //     if (!localStorage.getItem("userProfileId")) {
  //       try {
  //         const token = localStorage.getItem("Afb");

  //         const response = await axios.post(
  //           `${config.API_URL}/userprofile/`,
  //           payload,
  //           {
  //             headers: {
  //               Authorization: `${token}`,
  //             },
  //           }
  //         );
  //         console.log(response);
  //         localStorage.setItem("userProfileId", response.id);
  //         setIsSaved(true);
  //         message.success("User profile data saved successfully");
  //       } catch (error) {
  //         message.error(error || "Something went wrong !");
  //       }
  //     } else {
  //       message.info("User profile is already saved");
  //     }
  //   }
  // };

  const userprofilepage = createSelector(
    (state) => state.profile,
    (state) => ({
      error: state.error,
      success: state.success,
    })
  );
  // Inside your component
  const { error, success } = useSelector(userprofilepage);

  // const auth = getAuth();

  useEffect(() => {
    // Get the currently logged-in user from Firebase
    const user = auth.currentUser;

    if (user) {
      // User is logged in, set the email
      setEmail(user.email);
    } else {
      // No user is logged in, handle accordingly
      // console.log("No user is logged in");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setEmail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setEmail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || "",
      idx: idx || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  const selectBefore = (
    <Select
      defaultValue={countryCode}
      style={{ color: "black" }}
      onChange={(value) => setCountryCode(value)} // Update country code
    >
      <Option value="+1">+1</Option>
      {/* Add more country codes as needed */}
    </Select>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Card title="Profile Information">
          <Row justify="start">
            <Col span={6}>
              <label>First name</label>
              <Input
                placeholder="Enter your first name"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Last name</label>
              <Input
                value={lastName}
                placeholder="Enter your last name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Email</label>
              <Input placeholder={useremail} disabled />
            </Col>
          </Row>
          <Row justify="start" style={{ marginTop: 10 }}>
            <Col span={6}>
              <label>Website</label>
              <Input
                placeholder="Enter website link"
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
                value={website}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Cell Phone</label>
              <Input
                addonBefore={selectBefore}
                value={phoneNumber}
                type="tel"
                onChange={(e) => {
                  // Get the current value from the input field
                  let inputValue = e.target.value;

                  // Remove the +1 if it's present at the start of the input
                  if (inputValue.startsWith("+1")) {
                    inputValue = inputValue.slice(2); // Remove the first two characters (+1)
                  }

                  // Update the phoneNumber state with the new value
                  setPhoneNumber(inputValue);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Time zone</label>
              <Input placeholder="GMT(-4)" value={timeZone} disabled />
            </Col>
          </Row>
          <label style={{ marginTop: 10 }}>Email Signature</label>

          <div className="ckEditorSignature" style={{ width: "930px" }}>
            <CKEditor
              editor={ClassicEditor}
              data={emailSignature}
              config={{
                toolbar: {
                  items: ["undo", "redo", "|", "bold", "italic", "underline"],
                },
                plugins: [
                  Bold,
                  Essentials,
                  Italic,
                  Mention,
                  Paragraph,
                  Undo,
                  Underline,
                ],
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEmailSignature(data);
              }}
              style={{ height: "70px" }}
            />
          </div>

          <Form.Item>
            <Space style={{ marginTop: "7px" }}>
              {/* <Button type="secondary">Cancel</Button> */}
              <Button onClick={() => handleSave()}>Save Signature</Button>
            </Space>
          </Form.Item>

          <Row justify="start" style={{ marginTop: 10 }}>
            <Col span={6}>
              <label>Business Name</label>
              <Input
                placeholder="Enter business name"
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Address Line 1</label>
              <Input
                placeholder="Enter your address 1"
                value={addressLine1}
                onChange={(e) => {
                  setAddressLine1(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Address Line 2</label>
              <Input
                placeholder="Enter address line 2"
                value={addressLine2}
                onChange={(e) => {
                  setaddressLine2(e.target.value);
                }}
              />
            </Col>
          </Row>

          {/* City, State, and Zip */}
          <Row justify="start" style={{ marginTop: 10 }}>
            <Col span={6}>
              <label>City</label>
              <Select
                showSearch
                value={selectedCity}
                placeholder="Select or type a city"
                style={{ width: "100%" }}
                onChange={handleCityChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {getCityOptions()}
              </Select>
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>State</label>
              {/* <Select
                placeholder="Select state"
                value={selectedState}
                onChange={(value) => handleStateChange(value)}
                style={{ width: "100%" }} // Ensure the dropdown is 100% width
              >
                {usStates.map((state) => (
                  <Select.Option key={state.value} value={state.value}>
                    {state.label}
                  </Select.Option>
                ))}
              </Select> */}
              <Select
                showSearch
                value={selectedState}
                placeholder="Select a state"
                onChange={handleStateChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
              >
                {usStates.map((state) => (
                  <Option key={state.value} value={state.value}>
                    {state.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <label>Zip</label>
              <Input
                placeholder="Enter zip code"
                value={Zip}
                onChange={(e) => {
                  setZip(e.target.value);
                }}
              />
            </Col>
          </Row>
          {/* Country (fixed as USA) */}
          <Row justify="start" style={{ marginTop: 10 }}>
            <Col span={6}>
              <label>Country</label>
              <Input value="USA" disabled />
            </Col>
          </Row>

          <div className="" style={{ marginTop: "30px" }}>
            {Issaved ? (
              <Form.Item>
                <Space>
                  {/* <Button type="secondary">Cancel</Button> */}
                  <Button type="primary" onClick={() => handleSaveProfile()}>
                    Save Profile
                  </Button>
                </Space>
              </Form.Item>
            ) : (
              <Form.Item>
                <Space>
                  {/* <Button type="secondary">Cancel</Button> */}
                  <Button onClick={() => handleSaveProfile()}>
                    Update Profile
                  </Button>
                </Space>
              </Form.Item>
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);

// if (user) {
//   // Save or update user data in Firestore
//   firestore
//     .collection("users")
//     .doc(user.uid)
//     .set(
//       {
//         firstName,
//         lastName,
//         email,
//         phone,
//         website,
//         businessName,
//       },
//       { merge: true } // Merge with existing data
//     )
//     .then(() => {
//       message.success("Profile successfully updated!");
//       console.log("Profile successfully updated!");
//     })
//     .catch((error) => {
//       console.error("Error updating profile: ", error);
//     });
// }
