import axios from "../../axios";
import config from "../../config";
import { ADD_NOTE, GET_NOTE, UPDATE_TASK } from "../types";
import { setAlert } from "./alert";

export const addNote = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");

    const res = await axios.post(`${config.API_URL}/notes/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    // const res = await axios.post("tasks/", formData);
    dispatch({ type: ADD_NOTE, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
    // if (res.data.code === 2) {
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'Warning',
    //         text: res.data.message,
    //         confirmButtonText: 'OK'
    //     });
    // dispatch(setAlert(res.data.message, 'warning'));
    // }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getNote = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/notes/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // Make the API request with the token
    // const res = await axios.get("contacts", config);
    // console.log("notes data:", res.data);
    res.data.reverse();
    await dispatch({ type: GET_NOTE, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateNote = (id, formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("idtoken");

    const res = await axios.patch(`${config.API_URL}/notes/${id}/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: UPDATE_TASK, payload: res.data });

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

// export const deleteNote = (id) => async (dispatch) => {
//     try {
//         if (!id) {
//             dispatch(setAlert('Please select any record', 'warning'));
//             return false;
//         }
//         const token = localStorage.getItem('idtoken');

//         const res = await axios.delete(`notes/${id}/`, {
//             headers: {
//                 Authorization: `${token}`
//               }
//         });
//             dispatch({type: DELETE_TASK, payload: {id}});
//             dispatch(setAlert(res.data.message, 'success'));
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };
