import axios from "../../axios";
import config from "../../config";
import { ADD_SPOUSE, GET_SPOUSE, UPDATE_SPOUSE } from "../types";
import { setAlert } from "./alert";

export const addSpouse = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");
    const res = await axios.post(`${config.API_URL}/spouse/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: ADD_SPOUSE, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
    // }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getSpouse = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/spouse/`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    // console.log(`${config.API_URL}/contacts/`);

    // Make the API request with the token
    // const res = await axios.get("contacts", config);
    // console.log("contacts data:", res.data);

    await dispatch({ type: GET_SPOUSE, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateSpouse = (formData, id) => async (dispatch) => {
  try {
    const res = await axios.patch(`spouse/${id}`, formData);
    dispatch({ type: UPDATE_SPOUSE, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
