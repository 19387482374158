import axios from "../../axios";
import config from "../../config";
import {
  ADD_EMAIL_FOLDER,
  DELETE_EMAIL_FOLDER,
  GET_EMAIL_FOLDER,
  UPDATE_EMAIL_FOLDER,
} from "../types";
import { setAlert } from "./alert";

export const addEmailFolder = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("email-folders/", formData);
    dispatch({ type: ADD_EMAIL_FOLDER, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
    dispatch(getEmailFolders());
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getEmailFolders = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/email-folders/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // Make the API request with the token
    // console.log("email folder data:", res.data);
    res.data.reverse();

    // const res = await axios.get("contacts", config);
    await dispatch({ type: GET_EMAIL_FOLDER, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateEmailFolder = (formData) => async (dispatch) => {
  try {
    const res = await axios.patch("email-folders", formData);
    dispatch({ type: UPDATE_EMAIL_FOLDER, payload: res.data.data });
    dispatch(getEmailFolders());

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteEmailFolder = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.delete(`email-folders/${id}`);
    dispatch({ type: DELETE_EMAIL_FOLDER, payload: { id } });
    dispatch(getEmailFolders());

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
