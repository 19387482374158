import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Flex, message, Select } from "antd";
import CommunicationDrawer from "./CommunicationDrawer";
import Richtext from "../../components/Richtext";
import { client, getChatCompletion } from "../../helpers/openai_helper";
import { getAuth } from "firebase/auth";
import axios from "axios";
import { useSelector } from "react-redux";
import { htmlToText } from "html-to-text";
import { getGoogleAccessToken } from "../../utils/auth";

const { TextArea } = Input;
const { Text } = Typography;

const ComposeEmail = () => {
  const [email, setEmail] = useState("");
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [loading, setLoading] = useState("");
  const contacts = useSelector((state) => state.contacts.list);

  const [isApplied, setIsApplied] = useState(false);

  const { Option } = Select;

  const googleToken = localStorage.getItem("Gp_TK");
  const token = localStorage.getItem("Afb");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toEmail, setToEmail] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsCC, setOptionsCC] = useState([]);
  const [optionsBCC, setOptionsBCC] = useState([]);
  const [optionsTo, setOptionsTo] = useState([]);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [CC, setCc] = useState("");
  const [BCC, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [emailSignature, setEmailSignature] = useState("");
  const [useTemplate, setUseTemplate] = useState(null);
  const [form] = Form.useForm(); // Initialize form hook

  const [size, setSize] = useState("");

  useEffect(() => {
    // Load the signature from localStorage (or an API if using a backend)
    const savedSignature = localStorage.getItem("emailSignature");
    if (savedSignature) {
      setEmailSignature(savedSignature);
    }
  }, []);

  useEffect(() => {
    if (subject !== "") {
      form.setFieldsValue({
        subject: subject,
        // Set other fields here as needed
      });
    }
  }, [subject]);

  useEffect(() => {
    // Populate the options array based on the contacts list
    if (contacts && contacts.length > 0) {
      const contactOptions = contacts
        .filter((contact) => contact.email) // Filter out contacts without an email
        .map((contact, index) => ({
          value: contact.email, // Use email as the value for the options
          label: `${contact.firstName} - ${contact.email}`, // Display name and email
          key: `${contact.email}-${index}`, // Ensure unique key
        }));

      setOptions(contactOptions);
      setOptionsCC(contactOptions);
      setOptionsBCC(contactOptions);
    }
  }, [contacts]); // Update options whenever contacts change

  const handleChange = (value, fieldType) => {
    const newValue = value[value.length - 1]; // Get the last entered value
    const currentOptionsSetter =
      fieldType === "cc" ? setOptionsCC : setOptionsBCC;

    // Check if the value already exists in the respective options array
    if (!options.some((option) => option.value === newValue)) {
      // If it doesn't exist, add it to the options
      currentOptionsSetter((prevOptions) => [
        ...prevOptions,
        { value: newValue, label: newValue, key: newValue }, // Ensure unique key
      ]);
    }
  };

  for (let i = 10; i < 36; i++) {
    // console.log("Email Contacts", contacts);
    // options.push({
    //   value: i.toString(36) + i,
    //   label: i.toString(36) + i,
    // });
  }

  // Function to check Google token availability
  const checkGoogleToken = () => {
    if (!googleToken) {
      message.info("Connect with your Google Account to send emails");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (useTemplate) {
      // Set the subject and bodyText when a template is selected
      // setSubject(useTemplate.subject);
      // setBodyText(useTemplate.body);
    }
  }, [useTemplate]);

  useEffect(() => {
    if (useTemplate) {
      form.setFieldsValue({
        subject: subject, // Set other fields here as needed
      });
      setBodyText(useTemplate.bodyText);
    }
  }, [useTemplate, form]);

  const sendEmail = () => {
    const emailContent = createEmail(from, to, CC, BCC, subject, body);
    console.log("Email data ---> ", emailContent);
  };

  const createEmail = (from, to, CC, BCC, subject, body) => {
    const emailLines = [
      `From: ${from}`,
      `To: ${to}`,
      CC ? `Cc: ${CC}` : null,
      BCC ? `Bcc: ${BCC}` : null,
      `Subject: ${subject}`,
      "", // Ensure a blank line to separate headers from body
      body.trim(), // Ensure no leading whitespace in the body
    ];

    console.log(emailLines);

    return emailLines
      .filter((line) => line) // Filter out nulls
      .join("\r\n")
      .trim(); // Trim final result
  };

  const handleChooseTemplate = () => {
    setDrawerOpen(true);
  };
  const handleInputChange = () => {
    // checkGoogleToken();
    return;
  };

  const handleSignature = () => {
    console.log(bodyText);
    const savedSignature = localStorage.getItem("emailSignature");

    // console.log(bodyText.includes("{{emailSignature}}"));
    if (savedSignature) {
      if (!bodyText.includes("{{emailSignature}}")) {
        const updatedBodyText = `${bodyText}\n\n{{emailSignature}}`;
        setBodyText(updatedBodyText);
        message.success("Signature retrieved from profile page.");
      }
      if (bodyText.includes("{{emailSignature}}")) {
        const updatedBodyText = bodyText.replace(
          /\n*\{\{emailSignature\}\}\n*/g,
          ""
        ); // Remove placeholder
        setBodyText(updatedBodyText);
        setIsApplied(false);
        message.info("Signature removed successfully");
      }
    } else {
      message.error("Add your signature via profile page to apply here.");
    }
  };

  useEffect(() => {
    if (bodyText.includes("{{emailSignature}}")) {
      setIsApplied(true);
    }

    if (!bodyText.includes("{{emailSignature}}")) {
      setIsApplied(false);
    }
  }, []);

  // onFinish function that will send the email
  const onFinish = async (values) => {
    // Replace the {{emailSignature}} placeholder with the actual signature
    // const processedBody = bodyText.replace(
    //   "{{emailSignature}}",
    //   emailSignature
    // );

    // const processedBody = `\r\n${bodyText.replace(
    //   "{{emailSignature}}",
    //   emailSignature
    // )}`;

    // const plainTextBody = htmlToText(processedBody, {
    //   wordwrap: 130,
    // });

    // Add a dummy line that will be discarded by the email client
    const processedBody = `\r\nThis line will be ignored\r\n${bodyText.replace(
      "{{emailSignature}}",
      emailSignature
    )}`;

    const savedSignature = localStorage.getItem("emailSignature");

    const plainTextBody = htmlToText(processedBody, {
      wordwrap: 130,
    });

    setToEmail(values.to);
    setFrom(email);
    console.log("To value", values.to); // Ensure 'from' is set correctly
    setTo(values.to);
    setCc(values.cc || "");
    setBcc(values.bcc || "");
    setSubject(values.subject);
    setBody(plainTextBody); // Ensure 'body' is updated


    const googleAccess = await getGoogleAccessToken();

    console.log("googleAccess googleAccess");
    console.log(googleAccess);

    if (googleToken) {
      try {
        const emailContent = createEmail(
          email,
          values.to,
          values.cc,
          values.bcc,
          values.subject,
          plainTextBody
        );
        console.log("Email data ---> ", emailContent);

        if (email && values.to && values.subject && plainTextBody) {
          try {
            const response = await fetch(
              "https://www.googleapis.com/gmail/v1/users/me/messages/send",
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${googleAccess.google_access_token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  raw: btoa(emailContent)
                    .replace(/\+/g, "-")
                    .replace(/\//g, "_"),
                }),
              }
            );

            const data = await response.json();

            if (response.ok) {
              // Check if the response status is 200-299
              console.log("Email sent successfully:", data);
              message.success("Email sent successfully");
              setBodyText("");
              if (!savedSignature) {
                message.info("You can set signature via user profile page.");
              }
            } else {
              console.error("Error sending email:", data);
              message.error(
                data.error
                  ? data.error.message
                  : "Something went wrong while sending the email."
              );
            }
          } catch (error) {
            message.error("Something went wrong while sending the email.");
          }
        } else {
          message.error("Incorrect Credentials !");
        }
      } catch (error) {
        console.error(
          "Error sending email:",
          error.response ? error.response.data : error.message
        );
        message.error("Something went wrong while sending the email.");
      }
    } else {
      message.info("Connect with your Google Account to send emails");
    }
  };

  const handleBCC = () => {
    setBCC(!bcc);
  };

  const handleCC = () => {
    setCC(!cc);
  };
  const auth = getAuth();

  useEffect(() => {
    // Get the currently logged-in user from Firebase
    const user = auth.currentUser;

    if (user) {
      // User is logged in, set the email
      setEmail(user.email);
    } else {
      // No user is logged in, handle accordingly
      console.log("No user is logged in");
    }
  }, []);

  const checkGmailAuth = () => {
    message.info("Connect with Google via Settings page to send emails");
  };

  useEffect(() => {
    const token = localStorage.getItem("Gp_TK");
    setIsButtonDisabled(!token);
  }, []);

  return (
    <div>
      <Flex justify="space-between" align="flex-start">
        <div>
          <strong>Send an Email</strong>
        </div>
        <div>
          <Button type="primary" onClick={handleChooseTemplate}>
            Choose Template
          </Button>
        </div>
      </Flex>
      <div style={{ width: "100%", boxSizing: "border-box" }}>
        <Form
          form={form}
          name="emailForm"
          onFinish={onFinish}
          initialValues={{
            from: email,
            to: "",
            subject: subject,
            body: bodyText,
          }}
          layout="vertical" // Vertical layout for labels above fields
          style={{ maxWidth: "100%" }} // Ensure the form takes the full width
        >
          {/* From Field */}
          <Form.Item label="From" name="from" initialValue={email}>
            <Input placeholder={email} disabled />
            <Text>
              NOTE: Your message will be sent using a default address in the
              “From” line and your Properly account email address in the
              “Reply-To” line.
            </Text>
          </Form.Item>

          <Form.Item
            name="to"
            label="To"
            rules={[
              {
                required: true,
                message: "Please enter the recipient's email address!",
              },
            ]}
          >
            <Select
              mode="tags"
              size={size}
              placeholder="Add or Select the email"
              onChange={(value) => handleChange(value, "to")}
              defaultValue={[]}
              style={{
                width: "100%",
              }}
              options={options}
            />
          </Form.Item>

          <Text>
            <div>
              <strong
                style={{
                  textDecoration: "underline",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleCC()}
              >
                CC
              </strong>
              <strong
                style={{
                  textDecoration: "underline",
                  marginLeft: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleBCC()}
              >
                BCC
              </strong>
            </div>
          </Text>

          {/* CC Field */}
          <>
            {cc ? (
              <Form.Item label="CC" name="cc" onChange={handleInputChange}>
                <Select
                  mode="tags"
                  size={size}
                  placeholder="Add or Select the email"
                  onChange={(value) => handleChange(value, "cc")}
                  defaultValue={[]}
                  style={{
                    width: "100%",
                  }}
                  options={optionsCC}
                />
              </Form.Item>
            ) : null}

            {/* BCC Field */}

            {bcc ? (
              <Form.Item label="BCC" name="bcc" onChange={handleInputChange}>
                <Select
                  mode="tags"
                  size={size}
                  placeholder="Add or Select the email"
                  onChange={(value) => handleChange(value, "bcc")}
                  defaultValue={[]}
                  style={{
                    width: "100%",
                  }}
                  options={optionsBCC}
                />
              </Form.Item>
            ) : null}
          </>
          {/* Subject Field */}
          <Form.Item
            label="Subject"
            name="subject"
            onChange={handleInputChange}
            rules={[
              {
                required: true,
                message: "Please enter the subject of the email!",
              },
            ]}
          >
            <Input
              placeholder="Enter the subject of the email"
              value={subject}
            />
          </Form.Item>

          {/* Email Body Field */}
          <Form.Item label="Email Body" name="body">
            <Richtext
              bodyText={bodyText}
              setBodyText={setBodyText}
              toEmail={toEmail}
              loading={loading}
              setLoading={setLoading}
              setSubject={setSubject}
            />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
            {googleToken ? (
              <Button
                type="primary"
                // disabled={isButtonDisabled}
                htmlType="submit"
                // onClick={checkGmailAuth}
              >
                Send Email
              </Button>
            ) : (
              <Button
                type="primary"
                // disabled={isButtonDisabled}
                // htmlType="submit"
                onClick={checkGmailAuth}
              >
                Send Email
              </Button>
            )}
            {googleToken ? (
              <>
                {!loading && (
                  <Button
                    onClick={() => handleSignature()}
                    style={{
                      position: "absolute",
                      top: "-439px",
                      left: "185px",
                    }}
                  >
                    {bodyText.includes("{{emailSignature}}")
                      ? "Remove Signature"
                      : "Apply Signature"}
                  </Button>
                )}
              </>
            ) : null}
          </Form.Item>
        </Form>
      </div>

      <CommunicationDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        useTemplate={useTemplate}
        setUseTemplate={setUseTemplate}
        bodyText={bodyText}
        setBodyText={setBodyText}
        setSubject={setSubject}
      />
    </div>
  );
};

export default ComposeEmail;
