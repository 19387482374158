import React, { useEffect, useState } from "react";
import {
  Input,
  Dropdown,
  Button,
  Space,
  Table,
  Tag,
  Image,
  Flex,
  Modal,
  message,
} from "antd";
import { DownOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import nodata from "../../assets/images/5425970_Mailsent1.png";
import {
  // AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import MyMeetingDrawer from "./MyMeetingDrawer";
import EditMyMeetingDrawer from "./EditMyMeetingDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetings,
  updateMeeting,
  deleteMeeting,
  updatePendingMeeting,
} from "../../store/actions/meetings";
// import { deleteMeeting, getMeetings } from "../../store/actions/meetings";
import edit1 from "../../assets/figmaAssets/edit.svg";
import delete1 from "../../assets/figmaAssets/delete.svg";
import tick from "../../assets/figmaAssets/tick.svg";
import completed from "../../assets/figmaAssets/completed.svg";

const Mymeeting = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedTask, setSelectedMeeting] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVirtual, setIsVirtual] = useState(false);
  const [pendingMeeting, setPendingMeeting] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // For search input
  const [filterStatus, setFilterStatus] = useState("All"); // For dropdown filter

  const dispatch = useDispatch();
  const meetings = useSelector((state) => state.meetings.list);
  const loading = useSelector((state) => state.loading);

  // Filter tasks based on search input and dropdown
  const filteredTasks = meetings.filter((task) => {
    const matchesSearch = task.title
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    const matchesStatus =
      filterStatus === "All" || task.category === filterStatus;

    return matchesSearch && matchesStatus;
  });

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setFilterStatus(e.key);
  };

  const handleOk = () => {};

  useEffect(() => {
    const fetchMeeting = async () => {
      await dispatch(getMeetings());
    };
    fetchMeeting();
  }, [dispatch]);

  const handleEditClick = (record) => {
    // updateMeeting(record);
    setSelectedMeeting(record);
    setEditDrawerOpen(true);
  };

  // update pending meeting task
  const handlePendingMeeting = async (record) => {
    try {
      await dispatch(deleteMeeting(record.id));
      message.success("Meeting has been completed");
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  // cancel the meeting
  const handleCancelPendingMeeting = () => {
    setPendingMeeting(false);
  };

  const handleDeleteClick = async (record) => {
    try {
      await dispatch(deleteMeeting(record.id));
      message.success("Meeting has been deleted");
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const showModal = (task) => {
    setSelectedMeeting(task);
    setIsModalOpen(true);
  };

  console.log("meetings Comp :", meetings);

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = (e) => {
    console.log("click left button", e);
  };

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "2nd menu item",
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "3rd menu item",
      key: "3",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      icon: <UserOutlined />,
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columns = [
    {
      title: "Meeting Title",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <span style={{ textDecoration: "underline" }}>{text}</span>
      ),
    },
    {
      title: "Reminder Date & Time",
      dataIndex: "reminder_date",
      key: "status",
      render: (reminder_date, record) => {
        // Combine reminder_date and start_time into a single Date object
        const combinedDateTime = new Date(
          `${reminder_date}T${record.reminder_time}`
        );

        // Format the combinedDateTime into MM.DD.YYYY HH:MM:SS
        const formattedDateTime = `${(
          "0" +
          (combinedDateTime.getMonth() + 1)
        ).slice(-2)}.${("0" + combinedDateTime.getDate()).slice(
          -2
        )}.${combinedDateTime.getFullYear()} ${(
          "0" + combinedDateTime.getHours()
        ).slice(-2)}:${("0" + combinedDateTime.getMinutes()).slice(-2)}:${(
          "0" + combinedDateTime.getSeconds()
        ).slice(-2)}`;

        // Define colors based on some status value (you can adjust this part as needed)
        const color = "default"; // Fallback color if needed

        return (
          <span color={color}>
            {reminder_date && record.reminder_time !== null
              ? formattedDateTime
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => {
        const color = text === "In-person meeting" ? "yellow" : "green";
        if (text !== "In-person meeting") {
          setIsVirtual(true);
        }
        return (
          <Tag
            style={{
              borderRadius: "2vh",
              border: "none",
              fontWeight: "bold",
              padding: "5px 15px",
              fontSize: "13px",
            }}
            color={color}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "category",
      render: (text) => {
        const color = "#5DA4E5";

        // Split the contact string by commas to get an array of emails
        const emails = text.split(",").map((email) => email.trim());

        // If there's more than one email, show the first one followed by +<remaining>
        const displayedText =
          emails.length > 1 ? `${emails[0]} +${emails.length - 1}` : emails[0];

        return (
          <span style={{ textDecoration: "", color: "#5DA4E5" }}>
            {displayedText}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          {/* <AiOutlineDelete
            onClick={() => handleDeleteClick(record)}
            // onClick={showModal}
            style={{
              cursor: "pointer",
              marginRight: 8,
              fontSize: "20px",
              color: "#ff4d4f",
            }}
          />
          <AiOutlineEdit
            // onClick={() => handleEdit(record)}
            onClick={() => handleEditClick(record)}
            style={{
              cursor: "pointer",
              marginRight: 8,
              fontSize: "20px",
              color: "#1890ff",
            }}
          /> */}

          <img
            src={delete1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleDeleteClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#ff4d4f",
            }}
          />

          <img
            src={edit1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleEditClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#1890ff",
            }}
          />
          {/* <AiOutlineCheckCircle /> */}
          <img
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handlePendingMeeting(record);
            }}
            src={tick}
            alt="tick"
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
    {
      title: "",
      key: "notes",
      render: (text, record) => {
        // Destructure record to get location and meeting_link
        const { location, category, meeting_link } = record;

        // Check if location is available and handle missing values
        console.log("record of location", record);

        // Conditional rendering based on category
        if (category === "In-person meeting") {
          const meeting_location = location;
          return (
            <a
              href={`https://www.google.com/maps?q=${meeting_location}`}
              target="_blank"
              style={{
                textDecoration: "underline !important",
                color: "#5DA4E5",
                cursor: "pointer",
              }}
              className="underline-link"
            >
              View Location
            </a>
          );
        } else if (category === "Remote meeting" && location) {
          return (
            <a
              href={
                location.startsWith("http") ? location : `https://${location}`
              }
              target="_blank"
              style={{
                textDecoration: "underline",
                color: "#5DA4E5",
                cursor: "pointer",
              }}
              className="underline-link"
            >
              Join Meeting
            </a>
          );
        } else {
          return <span>No meeting details available</span>;
        }
      },
    },
  ];

  const hasData = meetings && meetings.length > 0;

  return (
    <div>
      <Flex justify="space-between" align="flex-start">
        <div>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search Meetings"
            style={{ width: "320px" }}
            value={searchValue}
            onChange={handleSearchChange} // Capture search input changes
          />
          <Dropdown
            menu={{
              items: [
                { label: "All", key: "All" },
                { label: "In-person", key: "In-person meeting" },
                { label: "Remote", key: "Remote meeting" },
                // { label: "Overdue", key: "Overdue" },
              ],
              onClick: handleDropdownChange, // Handle dropdown changes
            }}
          >
            <Button style={{ marginLeft: 10 }}>
              <Space>
                {`${filterStatus}`}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div>
          <Button type="primary" onClick={() => setDrawerOpen(true)}>
            Track meeting
          </Button>
        </div>
      </Flex>

      {/* Conditionally render centered content or table */}
      {filteredTasks.length === 0 ? (
        <Flex
          justify="center" // Center horizontally
          align="center" // Center vertically
          style={{ height: "80vh" }} // Ensure it takes full viewport height
        >
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>You haven’t scheduled any meeting yet!</strong>
            </div>
            <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
          </div>
        </Flex>
      ) : (
        <Table
          bordered
          className="mt-4"
          dataSource={filteredTasks}
          columns={columns}
          style={{ cursor: "pointer" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                // Call a function and pass the record value
                handleEditClick(record);
              },
            };
          }}
        />
      )}
      <MyMeetingDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <EditMyMeetingDrawer
        open={editDrawerOpen}
        selectedTask={selectedTask}
        onClose={() => setEditDrawerOpen(false)}
      />
      <Modal
        style={{ height: 400, width: 400 }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button type="primary" danger onClick={handleOk}>
            Delete
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Complete Meeting</h3>
        <div>
          Are you sure you want to delete the meeting. This cannot be undone
        </div>
      </Modal>

      {/* update meeting status approved */}
      <Modal
        style={{ height: 400, width: 400 }}
        open={pendingMeeting}
        onOk={handleOk}
        onCancel={handleCancelPendingMeeting}
        footer={[
          <Button onClick={handleCancelPendingMeeting}>Cancel</Button>,
          <Button type="primary" success onClick={handleOk}>
            Complete
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Complete Meeting</h3>
        <div>
          Are you sure you want to complete the meeting. This cannot be undone
        </div>
      </Modal>
    </div>
  );
};

export default Mymeeting;
