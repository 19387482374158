import React from "react";

const UserProfilePic = ({ firstName }) => {
  const letter = firstName.charAt(0).toUpperCase();
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#F33FFF", "#FFD733"];
  const backgroundColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <svg width="40" height="40" style={{ borderRadius: "50%" }}>
      <rect width="100%" height="100%" rx="50%" fill={"#183244"} />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="20"
        fill="#FFFFFF"
        fontFamily="Arial, sans-serif"
      >
        {letter}
      </text>
    </svg>
  );
};

export default UserProfilePic;
