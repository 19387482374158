import React, { useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Collapse,
  TimePicker,
  message,
} from "antd";
import { addMeeting, getMeetings } from "../../store/actions/meetings";
import left from "../../assets/figmaAssets/left.svg";
import moment from "moment"; // Import moment.js for date and time manipulation
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const MyMeetingDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // change meeting link input box by dynamically
  const [category, setCategory] = useState("Remote meeting"); // Default to "Remote meeting"
  const [loadingMeet, setLoadingMeet] = useState(false); // Default to "Remote meeting"
  const [value, setValue] = useState(null);

  // Handles the change and immediately closes the picker after selecting time
  const handleChange = (time) => {
    setValue(time);
  };

  // Retrieve contacts from Redux store using useSelector
  const contacts = useSelector((state) => state.contacts.list);

  // Map the contacts to email options for the Select dropdown
  const emailOptions = contacts
    .filter((contact) => contact.email) // Only include contacts with a non-empty email
    .map((contact, index) => ({
      value: contact.email,
      label: `${contact.firstName} - ${contact.email}`,
      key: `${contact.email}-${index}`,
    }));

  const onChange = (key) => {
    console.log(key);
  };

  // Handle category change
  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const createCalendarEvent = async (accessToken, eventDetails) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        const event = await response.json();
        message.success("Synced with your calendar successfully");
      } else {
        const error = await response.json();
        console.error("Failed to create event:", error);
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
    }
  };

  // Call the function with an access token and event details
  const syncGapiCalendar = (token, event) => {
    createCalendarEvent(token, event);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  // Helper functions
  const formatDateToYYYYMMDD = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

  const formatTimeToHHMM = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const hours = String(date.getHours()).padStart(2, "0"); // Ensures 2 digits for hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensures 2 digits for minutes
    return `${hours}:${minutes}`; // Format as HH:mm
  };

  const handleCreateMeeting = async (values) => {
    // Transform date and time values into the correct formats
    const contacts = values.contact;
    const commaSeparatedString = Array.isArray(contacts)
      ? contacts.join(",")
      : contacts;

    console.log("=>", values);

    // Format dates and times
    const startDate = formatDateToYYYYMMDD(values["Start date"]);

    const reminderDate = formatDateToYYYYMMDD(values["Reminder date"]);

    const startTime = formatTimeToHHMM(values["Start time"]);
    const endTime = formatTimeToHHMM(values["End time"]);
    const reminderTime = formatTimeToHHMM(values["Reminder Time"]);

    // Not functional in frontend ( Removed by demand )
    // const endDate = moment(values.end_date).format("YYYY-MM-DD");
    // const endTime = moment(values.end_time).format("HH:mm");

    const endDate = startDate; // Assuming end date is the same as start date

    // Create payload
    const payload = {
      title: values.title,
      category: values.category,
      contact: commaSeparatedString,
      location: values.meeting,
      start_date: startDate || null, // Default to null if the date is invalid
      end_date: endDate || null,
      start_time: startTime || null, // Default to null if the time is invalid
      end_time: endTime || null,
      reminder_date: reminderDate || null,
      reminder_time: reminderTime || null,
      remind_via: values.reminder_via || null,
    };

    const googleToken = localStorage.getItem("Gp_TK");

    // Output formatted payload for debugging
    console.log("Formatted Payload:", payload);

    try {
      setLoadingMeet(true);
      await dispatch(addMeeting(payload)); // Dispatch the action with the formatted payload
      await dispatch(getMeetings());
      onClose(); // Close the drawer after dispatching the action
      form.resetFields();
      setLoadingMeet(false);
      message.success("Meeting created!");
    } catch (error) {
      message.error("Something went wrong while creating a meeting.");
    }

    if (googleToken) {
      if (
        payload.start_date &&
        payload.end_date &&
        payload.start_time &&
        payload.end_time &&
        payload.reminder_date &&
        payload.reminder_time &&
        payload.remind_via
      ) {
        try {
          // Get the user's timezone
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Combine start date and time into a Date object for calculation
          const eventStartDateTime = new Date(
            `${payload.start_date}T${payload.start_time}:00`
          );
          const reminderDateTime = new Date(
            `${payload.reminder_date}T${payload.reminder_time}:00`
          );

          // Calculate the difference in minutes between the event start and reminder time
          const timeDifferenceInMs = eventStartDateTime - reminderDateTime;
          const timeDifferenceInMinutes = Math.floor(
            timeDifferenceInMs / 60000
          ); // Convert milliseconds to minutes

          if (timeDifferenceInMinutes < 0) {
            message.error("Reminder time must be before the event start time.");
            return;
          }

          const attendeeEmails = values.contact.map((email) => ({
            email: email,
          }));

          const eventDetails = {
            summary: payload.title,
            location: payload.location,
            // description: payload.category,
            start: {
              dateTime: `${payload.start_date}T${payload.start_time}:00`, // Combine date and time for Google Calendar
              timeZone: userTimeZone, // Modify to your desired timezone
            },
            end: {
              dateTime: `${payload.end_date}T${payload.end_time}:00`, // Combine date and time for Google Calendar
              timeZone: userTimeZone,
            },
            // Note: Do not include end here
            attendees: attendeeEmails,
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: timeDifferenceInMinutes },
                { method: "popup", minutes: 10 }, // 10 minutes before
              ],
            },
          };
          // Sync with Google Calendar
          syncGapiCalendar(googleToken, eventDetails);
        } catch (error) {
          message.error("Failed to sync with Google Calendar.");
        }
      }
    } else {
      message.info("Connect with Google to sync the event!");
    }
  };

  const items = [
    {
      key: "1",
      label: <strong>Sync Calendar (Optional)</strong>,
      children: (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="Start date" label="Start date">
              <DatePicker
                placeholder="Choose start date"
                style={{ width: "100%" }}
                format="MM/DD/YYYY" // Format for month, day, and year
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item name="End date" label="End date">
              <DatePicker
                placeholder="Choose end date"
                style={{ width: "100%" }}
                format="MM/DD/YYYY" // Format for month, day, and year
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item name="Start time" label="Start time">
              <TimePicker
                placeholder="Choose start time"
                style={{ width: "100%" }}
                format="h:mm a" // 12-hour format with AM/PM
                renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                onSelect={() => document.querySelector(".ant-picker").blur()} // Closes picker on selection
                onChange={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="End time" label="End time">
              <TimePicker
                placeholder="Choose end time"
                renderExtraFooter={() => null}
                use12Hours
                format="h:mm a"
                style={{ width: "100%" }}
                type={"time"}
                onChange={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const itemstwo = [
    {
      key: "1",
      label: <strong>Set Reminder (Optional)</strong>,
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="Reminder Time" label="Reminder Time">
              <TimePicker
                type="time"
                placeholder="Choose Reminder Time"
                style={{ width: "100%" }}
                format="h:mm a" // 12-hour format with AM/PM
                renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                onSelect={() => document.querySelector(".ant-picker").blur()} // Closes picker on selection
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="Reminder date" label="Reminder date">
              <DatePicker
                placeholder="Choose Reminder date"
                style={{ width: "100%" }}
                format="MM/DD/YYYY" // Format for month, day, and year
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="reminder_via" // Change this to match the payload
              label="Remind Via"
              rules={[{ message: "Please choose the Remind Via" }]}
            >
              <Select name="Remind Via" placeholder="Select a Remind via">
                <Option value="SMS & Email" disabled>
                  SMS & Email
                </Option>
                <Option value="Email">Email</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Track Meeting"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <CloseOutlined onClick={onClose} />
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={handleCreateMeeting}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Meeting title*"
              rules={[
                {
                  required: true,
                  message: "Enter task name",
                },
              ]}
            >
              <Input placeholder="Enter task name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Enter the category",
                },
              ]}
            >
              <Select
                placeholder="Enter the category"
                onChange={handleCategoryChange}
              >
                <Option value="Remote meeting">Remote Meeting</Option>
                <Option value="In-person meeting">In-person Meeting</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="contact"
              label="Contact"
              rules={[
                {
                  required: true,
                  message: "please enter Contact",
                },
              ]}
            >
              {/* <Input.TextArea rows={4} placeholder="please enter Contact" /> */}
              <Select
                mode="multiple"
                placeholder="Please select contact email"
                options={emailOptions}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {category === "Remote meeting" ? (
              <Form.Item
                name="meeting"
                label="Meeting link*"
                rules={[
                  {
                    required: true,
                    message: "Enter Meeting link*",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Enter Meeting link*"
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="meeting"
                label="Location*"
                rules={[
                  {
                    required: true,
                    message: "Enter Meeting Location*",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Enter Meeting Location*"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Collapse items={items} defaultActiveKey={["1"]} onChange={onChange} />
        <Collapse
          style={{ marginTop: 20 }}
          items={itemstwo}
          defaultActiveKey={["1"]}
          onChange={onChange}
        />
        <Flex className="mt-4" justify="flex-end" align="center">
          <Button onClick={() => handleCancel()}>cancel</Button>
          <Button
            style={{ marginLeft: 10 }}
            type="primary"
            htmlType="submit"
            loading={loadingMeet}
          >
            Track Meeting
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default MyMeetingDrawer;
