import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
// import { useDispatch } from "react-redux";
// import { addNote, getNote } from "../../store/actions/notes";
import magic from "../../assets/images/magic.svg";
import magicBlack from "../../assets/images/magic-black.svg";
import Skeleton from "react-loading-skeleton";
import left from "../../assets/figmaAssets/left.svg";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { getNote, updateNote } from "../../store/actions/notes";

const { Option } = Select;
const { TextArea } = Input;

const GetNotesDrawer = ({ notesData, open, onClose }) => {
  // console.log("notesddd", notesData);
  //   const dispatch = useDispatch();
  const [form] = Form.useForm(); // Initialize form hook
  const [toggleWriteAI, setToggleWriteAI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonTxt, setButtonTxt] = useState("Create");
  const token = localStorage.getItem("Afb");
  const [selectedTone, setSelectedTone] = useState("casual");
  const [myPrompt, setMyPrompt] = useState("");
  const contacts = useSelector((state) => state.contacts.list);
  const dispatch = useDispatch();

  // const [myPrompt, setMyPrompt] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [options, setOptions] = useState([]);

  const [aiContent, setAiContent] = useState(""); // Store AI generated content here
  const [descriptionContent, setDescriptionContent] = useState("");

  const handleChange = () => {
    console.log(options);
  };

  const handleToggleAI = () => {
    setToggleWriteAI(!toggleWriteAI);
  };

  useEffect(() => {
    // Populate the options array based on the contacts list
    if (contacts && contacts.length > 0) {
      const contactOptions = contacts
        .filter((contact) => contact.email)
        .map((contact) => ({
          value: contact.id, // Use email as the value for the options
          label: `${contact.firstName} - ${contact.email}`, // Display name and email
        }));
      // console.log("Contact Options ->", contactOptions);
      setOptions(contactOptions);
    }
  }, [contacts]); // Update options whenever contacts change

  const formatTextToParagraphs = (text) => {
    // Split text into paragraphs by double newlines
    const paragraphs = text.split(/\n\s*\n/);

    // Join paragraphs with HTML <p> tags
    return paragraphs
      .map((para, index) => `<p key=${index}>${para}</p>`)
      .join("");
  };

  const handleCreateAIContent = async (inputText) => {
    if (myPrompt == "") {
      return message.info("Please provide valid prompt");
    }
    setLoading(true);
    try {
      // console.log(selectedTone);
      const response = await fetch(`${config.API_URL}/chatgpt/`, {
        method: "POST",
        headers: {
          Authorization: `${token}`, // Added Bearer prefix
          "Content-Type": "application/json", // Added Content-Type header,
        },
        body: JSON.stringify({
          query: `You are an expert guide & helper assistant in real estate client management. Please answer or respond to the question or request based on the context of evaluating and handling client contacts in the real estate industry. Example questions include 'How should I follow up with a client who showed interest?' or 'What are some effective strategies for client engagement?' or 'Wishing happy birthday or anniversay to clients to maintain relationships'. Do not respond to questions outside this context. Keep in mind that you are generating an notes content to keep noted for user for the question or request. Here is the question or request: ${inputText}`,
          tone: selectedTone,
        }),
      });

      // Just for testing below line is added here
      // setDescriptionContent("I am here");

      if (!response.ok) {
        // Try to extract the error message from the response
        const errorMessage = await response.json().catch(() => null);
        throw new Error(errorMessage?.detail || "Failed to fetch AI content");
      }

      const data = await response.json();

      // Format the response message into paragraphs
      // const formattedMessage = formatTextToParagraphs(data.message);

      const formattedText = data.message
        .split("\n\n") // Paragraph breaks
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, "<br>")}</p>`) // Handle single line breaks
        .join(""); // Join paragraphs into one string

      setAiContent(data.message); // Assuming response has the generated content
      setDescriptionContent(data.message);
      // setEmailContent(data.message);
      // console.log(data.message);
      setBodyText(data.message);
      setButtonTxt("Replace");
      message.success(
        `Email content generated using AI with ${selectedTone} tone.`
      );
      handleToggleAI();
    } catch (error) {
      // Display specific error message
      message.error(`Error: ${error.message}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReWriteWithAI = async () => {
    // if (myPrompt == "") {
    //   return message.info("Please provide valid prompt");
    // }
    setLoading(true);
    try {
      // console.log(selectedTone);
      const response = await fetch(`${config.API_URL}/chatgpt/`, {
        method: "POST",
        headers: {
          Authorization: `${token}`, // Added Bearer prefix
          "Content-Type": "application/json", // Added Content-Type header,
        },
        body: JSON.stringify({
          query: `Act as you are an expert in spelling mistake finding work. You have to find errors in spelling at provided paragraph content and replace the wrong spelling with correct spelling. Keep in mind that you have to not change the context and words that are already correct under any circumstances. Just you have to seek if there are any wrong spellings or spelling mistake and make it corrected. Here is the paragraph: " ${bodyText}}`,
          tone: selectedTone,
        }),
      });

      // Just for testing below line is added here
      // setDescriptionContent("I am here");

      if (!response.ok) {
        // Try to extract the error message from the response
        const errorMessage = await response.json().catch(() => null);
        throw new Error(errorMessage?.detail || "Failed to fetch AI content");
      }

      const data = await response.json();
      const formattedMessage = formatTextToParagraphs(data.message);

      setAiContent(formattedMessage); // Assuming response has the generated content
      setDescriptionContent(formattedMessage);
      // setEmailContent(formattedMessage);
      setBodyText(data.message);
      // setButtonTxt("Replace");
      message.success(`Content refined using AI`);
    } catch (error) {
      // Display specific error message
      message.error(`Error: ${error.message}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notesData) {
      // Find the contact that matches the contactId
      const assigneeContact = contacts.find(
        (contact) => contact.id === notesData.contactId
      );
      const assigneeEmail = assigneeContact ? assigneeContact.email : ""; // Get the email or set to an empty string
      // const categories = notesData.tags;

      // // Handle null, string, and array cases
      // let commaSeparatedString = "";

      // if (Array.isArray(categories)) {
      //   // If it's an array, join the elements with a comma
      //   commaSeparatedString = categories.join(",");
      // } else if (typeof categories === "string") {
      //   // If it's already a string, use it as is
      //   commaSeparatedString = categories;
      // } else if (!categories) {
      //   // If it's null or undefined, set it as an empty string
      //   commaSeparatedString = "";
      // }

      const Initcategory = notesData.tags;
      const categoriesArray =
        Initcategory && Initcategory.split(",").map((item) => item.trim());

      setBodyText(notesData.content);
      // Set form values
      form.setFieldsValue({
        title: notesData.title,
        content: notesData.content,
        tags: categoriesArray,
        assignee: assigneeEmail, // Set the email instead of contactId

        // Set other fields here as needed
      });
    }
  }, [notesData, contacts, form]);

  const handleNoteUpdate = async () => {
    // Validate if the required fields are filled
    form
      .validateFields()
      .then(async (values) => {
        // setLoading(true);
        try {
          // Ensure tags is an array
          const tagsArray = Array.isArray(values.tags) ? values.tags : [];

          const commaSeparatedString = tagsArray.join(", "); // Join array into a string, will be empty if no tags

          // Create the payload to send to the backend
          const updatedNoteData = {
            title: values.title,
            content: bodyText || "", // Using bodyText state, as it might have AI-generated content
            tags: commaSeparatedString || "", // Convert tags array to a comma-separated string
            contactId: values.assignee,
          };

          console.log("=>", updatedNoteData);
          console.log("=>", notesData.id);
          console.log("=>", values);

          await dispatch(updateNote(notesData.id, updatedNoteData));
          await dispatch(getNote());

          // Successful update
          message.success("Note updated successfully!");
          onClose(); // Optionally close the drawer after successful update
        } catch (error) {
          // Handle errors from the API call
          message.error(`Error: ${error.message}`);
        } finally {
          // setLoading(false);
        }
      })
      .catch((info) => {
        // Handle form validation errors
        message.error("Please check the required fields.");
      });
  };
  return (
    <Drawer
      title="View Note"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      closeIcon={<img src={left} />}
      extra={
        <Space>
          <CloseOutlined onClick={onClose} />
        </Space>
      }
    >
      <Form
        layout="vertical"
        form={form}
        hideRequiredMark
        onFinish={handleNoteUpdate}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Note Title"
              rules={[{ required: true, message: "Enter Note Title" }]}
            >
              <Input placeholder="Please enter Note Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="assignee"
              label="Assignee"
              rules={[{ required: true, message: "Enter the Assignee" }]}
            >
              <Select
                // mode="tags"
                // size={size}
                placeholder="Add or Select the email"
                onChange={handleChange}
                defaultValue={[]}
                style={{
                  width: "100%",
                }}
                options={options}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="tags"
              label="Tags"
              // rules={[{ required: true, message: "Please enter Tags" }]}
            >
              {/* <Input placeholder="Add Tags" /> */}
              {/* <Input placeholder="Add Tags" /> */}
              <Select
                mode="tags"
                placeholder="Select Tags"
                name="tags"
                // value={formikAdditional.values.tags} // Keep it as an array
                // onChange={(value) =>
                //   formikAdditional.setFieldValue("tags", value)
                // }
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="content"
              label="Note Description"
              // rules={[
              //   { required: true, message: "Please enter Note Description" },
              // ]}
            >
              {loading ? (
                <Skeleton count={5} />
              ) : (
                <>
                  <TextArea
                    rows={12}
                    placeholder="Write your description"
                    value={form.getFieldValue("content")} // Bind form value
                    onChange={(e) => {
                      setBodyText(e.target.value); // Update bodyText state on each change
                      form.setFieldsValue({ content: e.target.value }); // Optionally update form value
                    }}
                  />
                  {/* Ṛewrite */}
                  {bodyText ? (
                    <>
                      <>
                        {" "}
                        <Button
                          onClick={handleReWriteWithAI}
                          style={{
                            // backgroundColor: "#2b6cb0",
                            background:
                              "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                            borderRadius: "24px",
                            padding: "14px 20px",
                            color: "#fff",
                            fontWeight: "bold",
                            border: "none",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: "295px",
                            left: "20px",
                          }}
                        >
                          <img
                            src={magic}
                            alt="magic-wand"
                            style={{
                              marginRight: "8px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          {"Re-Write with AI"}
                        </Button>
                      </>
                    </>
                  ) : null}

                  {/* Write */}

                  {bodyText == "" ? (
                    <>
                      <>
                        {" "}
                        {toggleWriteAI ? null : (
                          <Button
                            onClick={handleToggleAI}
                            style={{
                              // backgroundColor: "#2b6cb0",
                              background:
                                "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                              borderRadius: "24px",
                              padding: "14px 20px",
                              color: "#fff",
                              fontWeight: "bold",
                              border: "none",
                              display: "flex",
                              alignItems: "center",
                              position: "absolute",
                              top: "220px",
                              left: "20px",
                            }}
                          >
                            <img
                              src={magic}
                              alt="magic-wand"
                              style={{
                                marginRight: "8px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            Write with AI
                          </Button>
                        )}
                      </>
                      <>
                        {" "}
                        {toggleWriteAI ? (
                          <div>
                            <div
                              className="setToneAI"
                              style={{
                                position: "absolute",
                                top: "81px",
                                left: "22px",
                                width: "140px",
                              }}
                            >
                              <Form.Item
                                name="Select Tone"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select the tone",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Tone"
                                  value={selectedTone}
                                  onChange={setSelectedTone}
                                >
                                  <Option value={"default"}>Tone</Option>
                                  <Option value={"casual"}>Casual</Option>
                                  <Option value={"semi Formal"}>
                                    Semi formal
                                  </Option>
                                  <Option value={"professional"}>
                                    Professional
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div
                              className="magicAI"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                border: "1px solid #2b6cb0",
                                borderRadius: "24px",
                                padding: "10px 15px",
                                marginBottom: "20px",
                                backgroundColor: "white",
                                position: "absolute",
                                top: "204px",
                                left: "20px",
                                width: "540px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <EditOutlined
                      style={{
                        color: "#2b6cb0",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    /> */}
                                <img
                                  src={magicBlack}
                                  alt="magic-wand"
                                  style={{
                                    marginRight: "8px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                {bodyText == "" ? (
                                  <input
                                    placeholder="Tell us what do you want to write here"
                                    type="text"
                                    style={{
                                      color: "#5f6c7b",
                                      fontSize: "16px",
                                      border: "none",
                                      outline: "none",
                                      width: "290px",
                                    }}
                                    onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                                  />
                                ) : (
                                  <input
                                    placeholder="Refine the text with AI"
                                    type="text"
                                    style={{
                                      color: "#5f6c7b",
                                      fontSize: "16px",
                                      border: "none",
                                      outline: "none",
                                      width: "290px",
                                    }}
                                    onBlur={(e) => setMyPrompt(e.target.value)} // Call API when input is provided
                                  />
                                )}
                              </div>

                              <div style={{ display: "flex", gap: "10px" }}>
                                <Button
                                  onClick={handleToggleAI}
                                  type="link"
                                  style={{
                                    color: "#2b6cb0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="primary"
                                  style={{
                                    // backgroundColor: "#2b6cb0",
                                    background:
                                      "linear-gradient(90deg, #1B5478 0%, #1D73A8 50%, #1F95DC 100%)",
                                    borderColor: "#2b6cb0",
                                    borderRadius: "24px",
                                    padding: "0 20px",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    handleCreateAIContent(myPrompt)
                                  }
                                >
                                  {buttonTxt}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    </>
                  ) : null}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Flex justify="flex-end" align="center">
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={handleNoteUpdate}>
              Save Note
            </Button>
          </Space>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default GetNotesDrawer;
