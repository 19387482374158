import { ADD_EMAIL_FOLDER, DELETE_EMAIL_FOLDER,  GET_EMAIL_FOLDER,  UPDATE_EMAIL_FOLDER } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMAIL_FOLDER:      
      return { ...state, list: payload };

    case UPDATE_EMAIL_FOLDER:
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case ADD_EMAIL_FOLDER:
      return {
        ...state,
        list: [payload, ...state.list],
      };

    case DELETE_EMAIL_FOLDER:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== payload.id),
      };

    default:
      return state;
  }
};

export default reducer;
