import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Dropdown,
  Button,
  Space,
  Table,
  Tag,
  Image,
  Flex,
  Modal,
  message,
} from "antd";
import { DownOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import nodata from "../../assets/images/Nodata-cuate1.png";
import {
  // AiOutlineCheckCircle,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import MyTaskDrawer from "./MyTaskDrawer";
import EditMyTaskDrawer from "./EditMyTaskDrawer";
import {
  getTasks,
  deleteTask,
  updatePendingTask,
  updateTasks,
} from "../../store/actions/tasks";
import edit1 from "../../assets/figmaAssets/edit.svg";
import delete1 from "../../assets/figmaAssets/delete.svg";
import tick from "../../assets/figmaAssets/tick.svg";
import completed from "../../assets/figmaAssets/completed.svg";
import { deleteMeeting, getMeetings } from "../../store/actions/meetings";

const Mytasks = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskCompleted, isTaskCompleted] = useState(false);
  const [isCompletd, setIsCompleted] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // For search input
  const [filterStatus, setFilterStatus] = useState("All"); // For dropdown filter

  const googleToken = localStorage.getItem("Gp_TK");

  const handleGapiRestrict = () => {
    message.info("Connect your google account to create tasks");
  };

  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.list);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    const fetchTasks = async () => {
      await dispatch(getTasks());
    };
    fetchTasks();
  }, [dispatch]);

  const showModal = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (selectedTask) {
      await dispatch(deleteMeeting(selectedTask.id));
      await dispatch(getMeetings());

      setIsModalOpen(false);
    }
  };

  // Filter tasks based on search input and dropdown
  const filteredTasks = tasks.filter((task) => {
    const matchesSearch = task.name
      .toLowerCase()
      .includes(searchValue.toLowerCase());

    const matchesStatus =
      filterStatus === "All" || task.status === filterStatus;

    return matchesSearch && matchesStatus;
  });

  const handleCompletedTask = async (data) => {
    try {
      if (data.status == "Completed") {
        const payload = {
          status: "Pending",
        };
        await dispatch(updateTasks(payload, data.id));

        message.success("Tasks status changed !");

        setIsCompleted(false);
      }

      if (data.status == "Pending") {
        const payload = {
          status: "Completed",
        };
        await dispatch(updateTasks(payload, data.id));
        message.success("Tasks completed !");
        setIsCompleted(true);
      }

      if (data.status == "Overdue") {
        const payload = {
          status: "Completed",
        };
        await dispatch(updateTasks(payload, data.id));
        message.success("Tasks completed !");
        setIsCompleted(true);
      }
      await dispatch(getTasks());

      console.log(data);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const cancelPendingTaskModel = () => {
    isTaskCompleted(false);
  };

  const handleEditClick = (record) => {
    setSelectedTask(record);
    setEditDrawerOpen(true);
  };

  const handleTickClick = (record) => {
    handleCompletedTask(record);
  };

  const handleCompletedTickClick = (record) => {
    handleCompletedTask(record);
  };

  const handleDeleteClick = async (record) => {
    try {
      await dispatch(deleteTask(record.id));
      console.log("record", record);
      // showModal(record);
      message.success("Task deleted.");
    } catch (error) {
      message.error("Something went wrong while deleting the task.");
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      await dispatch(getTasks());
    };
    fetchTasks();
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e) => {
    setFilterStatus(e.key);
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span style={{ textDecoration: "underline" }}>{text}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const color = text === "Pending" ? "gold" : "green";
        return (
          <Tag
            style={{
              borderRadius: "2vh",
              border: "none",
              fontWeight: "bold",
              padding: "5px 15px",
              fontSize: "13px",
            }}
            color={color}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => {
        // Split the text into an array by commas and trim whitespace
        const categories = text
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean);
        const primaryCategory = categories[0]; // First category
        const additionalCount = categories.length - 1; // Count of additional categories

        const color = primaryCategory === "Follow-up" ? "#F3F3F3" : "#F3F3F3"; // You can customize this logic as needed

        return (
          <span>
            <Tag
              style={{
                borderRadius: "2vh",
                color: "black",
                padding: "5px 15px",
                fontSize: "13px",
              }}
              color={color}
            >
              {text ? primaryCategory : "-"}
            </Tag>
            {additionalCount > 0 && (
              <Tag
                style={{
                  borderRadius: "2vh",
                  color: "black",
                  padding: "5px 15px",
                  fontSize: "13px",
                }}
                color={color}
              >
                +{additionalCount}
              </Tag>
            )}
          </span>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => {
        return (
          <span
            style={{
              color: "black",
              fontSize: "13px",
            }}
          >
            {text.length > 48 ? `${text.slice(0, 48)}...` : text}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <img
            src={delete1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleDeleteClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#ff4d4f",
            }}
          />

          <img
            src={edit1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleEditClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#1890ff",
            }}
          />
          {/* <AiOutlineCheckCircle /> */}
          {record.status == "Completed" ? (
            <img
              src={completed}
              alt="tick"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling to row click
                handleCompletedTickClick(record);
              }}
            />
          ) : (
            <img
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling to row click
                handleCompletedTickClick(record);
              }}
              src={tick}
              alt="tick"
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      ),
    },
  ];

  const hasData = tasks && tasks.length > 0;

  return (
    <div>
      <Flex justify="space-between" align="flex-start">
        <div>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search Tasks"
            style={{ width: "320px" }}
            value={searchValue}
            onChange={handleSearchChange} // Capture search input changes
          />
          <Dropdown
            menu={{
              items: [
                { label: "All", key: "All" },
                { label: "Pending", key: "Pending" },
                { label: "Completed", key: "Completed" },
                { label: "Overdue", key: "Overdue" },
              ],
              onClick: handleDropdownChange, // Handle dropdown changes
            }}
          >
            <Button style={{ marginLeft: 10 }}>
              <Space>
                {`${filterStatus} Tasks`}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div>
          <Button type="primary" onClick={() => setDrawerOpen(true)}>
            Create Task
          </Button>
        </div>
      </Flex>

      {filteredTasks.length === 0 ? (
        <Flex justify="center" align="center" style={{ height: "80vh" }}>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>You haven’t added any tasks yet!</strong>
            </div>
            <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
          </div>
        </Flex>
      ) : (
        <Table
          bordered
          style={{ cursor: "pointer" }}
          className="mt-4"
          dataSource={filteredTasks} // Pass filtered data here
          columns={columns}
          loading={loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                // Call a function and pass the record value
                handleEditClick(record);
              },
            };
          }}
        />
      )}

      {/* {!hasData ? (
        <Flex justify="center" align="center" style={{ height: "80vh" }}>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>You haven’t added any tasks yet!</strong>
            </div>
            <Image style={{ marginTop: 20 }} src={nodata} preview={false} />
          </div>
        </Flex>
      ) : (
        <Table bordered
          style={{ cursor: "pointer" }}
          className="mt-4"
          dataSource={tasks}
          columns={columns}
          loading={loading}
        />
      )} */}

      <MyTaskDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <EditMyTaskDrawer
        open={editDrawerOpen}
        selectedTask={selectedTask}
        onClose={() => setEditDrawerOpen(false)}
      />

      <Modal
        style={{ height: 400, width: 400 }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={handleOk}>
            Delete
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Delete Task</h3>
        <div>
          Are you sure you want to delete this task? This cannot be undone.
        </div>
      </Modal>

      <Modal
        style={{ height: 400, width: 400 }}
        open={taskCompleted}
        onOk={handleOk}
        onCancel={cancelPendingTaskModel}
        footer={[
          <Button key="cancel" onClick={cancelPendingTaskModel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={handleOk}>
            Approved
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Update Pending Task</h3>
        <div>
          Are you sure you want to update this task? This cannot be undone.
        </div>
      </Modal>
    </div>
  );
};

export default Mytasks;
