import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserProfilePic from "./UserProfilePic";
import userProfile from "../../../assets/figmaAssets/logout.svg";
import { Flex, Row } from "antd";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  const [userPhoto, setUserPhoto] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const auth = getAuth();

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Retrieve the displayName from the logged-in user
        setUserName(user.displayName || null);
        setUserPhoto(
          user.photoURL ||
            "https://upload.wikimedia.org/wikipedia/commons/b/bc/Unknown_person.jpg"
        );
      } else {
        // Handle if no user is logged in (redirect or show a message)
        console.log("No user is logged in");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={userPhoto}
            alt="Header Avatar"
          /> */}

          <UserProfilePic firstName={userName ? userName : "User"} />
          <span className="d-none d-xl-inline-block ms-2 me-2">
            {userName ? userName : "User"}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag="a"
            href="/userprofile"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            {/* <i className="ri-user-line align-middle me-2" /> */}
            <Flex>
              <Row style={{ marginRight: "9px" }}>
                <span style={{ marginRight: "9px" }}>
                  <UserProfilePic firstName={userName ? userName : "User"} />
                </span>
              </Row>
              <Row style={{ marginLeft: "9px" }}>
                {userName ? userName : "User"}
                <span
                  style={{
                    color: "#20A0EC",
                    fontSize: "12px",
                    cursor: "pointer",
                    fontWeight: "lighter",
                  }}
                >
                  View more details
                </span>
              </Row>
            </Flex>
            {/* {props.t("Profile")}{" "} */}
          </DropdownItem>
          <Link to="/logout" className="dropdown-item">
            <img src={userProfile} style={{ marginRight: "5px" }} />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
