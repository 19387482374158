// ALERT
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//ADMIN LOGIN
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';

//ITEMS
export const ADD_ITEM = "ADD_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const GET_ITEMS = "GET_ITEMS"
export const GET_ITEM = "GET_ITEM"
export const DELETE_ITEM = "DELETE_ITEM"

//SALESMAN
export const ADD_SALESMAN="ADD_SALESMAN"
export const UPDATE_SALESMAN = "UPDATE_SALESMAN"
export const GET_SALESMANS = "GET_SALESMANS"
export const GET_SALESMAN="GET_SALESMAN"
export const DELETE_SALESMAN = "DELETE_SALESMAN"

//VISIT
export const GET_VISITS="GET_VISITS"

//COMPANY
export const ADD_COMPANY="ADD_COMPANY"
export const UPDATE_COMPANY="UPDATE_COMPANY"
export const GET_COMPANIES="GET_COMPANIES"
export const GET_COMPANY="GET_COMPANY"
export const DELETE_COMPANY="DELETE_COMPANY"
export const GET_CITIES="GET_CITIES"

//ADDRESS
export const GET_ADDRESS = "GET_ADDRESS"
export const ADD_ADDRESS = "ADD_ADDRESS"
export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const DELETE_ADDRESS = 'DELETE_ADDRESS';


// MASTER SKU
export const ADD_MASTER_SKU = 'ADD_MASTER_SKU';
export const DELETE_MASTER_SKU = 'DELETE_MASTER_SKU';
export const GET_MASTER_SKUS = 'GET_MASTER_SKUS';
export const GET_MASTER_SKU = 'GET_MASTER_SKU';
export const UPDATE_MASTER_SKU = 'UPDATE_MASTER_SKU';

export const GET_CONTACTS = 'GET_CONTACTS';
export const ADD_CONTACTS = 'ADD_CONTACTS';


//ADDITIONAL_INFO
export const GET_ADDITIONAL_INFO = "GET_ADDITIONAL_INFO"
export const ADD_ADDITIONAL_INFO = "ADD_ADDITIONAL_INFO"
export const UPDATE_ADDITIONAL_INFO = "UPDATE_ADDITIONAL_INFO"
export const DELETE_ADDITIONAL_INFO = 'DELETE_ADDITIONAL_INFO';

//SPOUSE
export const GET_SPOUSE = "GET_SPOUSE"
export const ADD_SPOUSE = "ADD_SPOUSE"
export const UPDATE_SPOUSE = "UPDATE_SPOUSE"
export const DELETE_SPOUSE = 'DELETE_SPOUSE';

//EMAIL
export const GET_EMAIL = "GET_EMAIL"
export const ADD_EMAIL = "ADD_EMAIL"
export const UPDATE_EMAIL = "UPDATE_EMAIL"
export const DELETE_EMAIL = 'DELETE_EMAIL';

//EMAIL TEMP
export const GET_EMAIL_TEMP = "GET_EMAIL_TEMP"
export const ADD_EMAIL_TEMP = "ADD_EMAIL_TEMP"
export const UPDATE_EMAIL_TEMP = "UPDATE_EMAIL_TEMP"
export const DELETE_EMAIL_TEMP = 'DELETE_EMAIL_TEMP';

//EMAIL FOLDER
export const GET_EMAIL_FOLDER = "GET_EMAIL_FOLDER"
export const ADD_EMAIL_FOLDER = "ADD_EMAIL_FOLDER"
export const UPDATE_EMAIL_FOLDER = "UPDATE_EMAIL_FOLDER"
export const DELETE_EMAIL_FOLDER = 'DELETE_EMAIL_FOLDER';

//MEETING
export const GET_MEETING = "GET_MEETING"
export const ADD_MEETING = "ADD_MEETING"
export const UPDATE_MEETING = "UPDATE_MEETING"
export const DELETE_MEETING = 'DELETE_MEETING';
export const update_Pending_Meeting="Pending_Meeting"

//TASK
export const GET_TASK = "GET_TASK"
export const ADD_TASK = "ADD_TASK"
export const UPDATE_TASK = "UPDATE_TASK"
export const DELETE_TASK = 'DELETE_TASK';
export const update_Pending_Task="Pending_Task"

//NOTE
export const GET_NOTE = "GET_NOTE"
export const ADD_NOTE = "ADD_NOTE"
export const UPDATE_NOTE = "UPDATE_NOTE"
export const DELETE_NOTE = 'DELETE_NOTE';

//GOOGLE
export const GET_GOOGLE = "GET_GOOGLE"
// export const ADD_GOOGLE = "ADD_GOOGLE"
// export const UPDATE_GOOGLE = "UPDATE_GOOGLE"
// export const DISCONNECT_GOOGLE = 'DISCONNECT_GOOGLE';

export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_EMAILS = 'SET_EMAILS';
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';



