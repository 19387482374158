import axios from "../../axios";
import config from "../../config";
import {
  ADD_EMAIL_TEMP,
  DELETE_EMAIL_TEMP,
  GET_EMAIL_TEMP,
  UPDATE_EMAIL_TEMP,
} from "../types";
import { setAlert } from "./alert";

export const addEmailTemp = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("email-templates/", formData);
    dispatch({ type: ADD_EMAIL_TEMP, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
    // if (res.data.code === 2) {
    //     Swal.fire({
    //         icon: 'warning',
    //         title: 'Warning',
    //         text: res.data.message,
    //         confirmButtonText: 'OK'
    //     });
    // dispatch(setAlert(res.data.message, 'warning'));
    // }
    await dispatch(getEmailTemps());
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getEmailTemps = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/email-templates/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // Make the API request with the token
    // const res = await axios.get("contacts", config);
    // console.log("contacts data:", res.data);
    res.data.reverse();

    await dispatch({ type: GET_EMAIL_TEMP, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateEmailTemps = (formData, id) => async (dispatch) => {
  try {
    const res = await axios.patch(`email-templates/${id}/`, formData);
    dispatch({ type: UPDATE_EMAIL_TEMP, payload: res.data });
    await dispatch(getEmailTemps());

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteEmailTemp = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.delete(`email-templates/${id}/`);

    dispatch({ type: DELETE_EMAIL_TEMP, payload: { id } });
    await dispatch(getEmailTemps());
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
