import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Collapse,
  TimePicker,
  message,
} from "antd";
import { getTasks, updateTasks } from "../../store/actions/tasks";
import left from "../../assets/figmaAssets/left.svg";

import moment from "moment";
import { auth } from "../../App";

const { Option } = Select;
const { TextArea } = Input;

const EditMyTaskDrawer = ({ open, onClose, selectedTask }) => {
  const dispatch = useDispatch();

  const task = selectedTask || {};
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);

  const [size, setSize] = useState("Default");

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const createCalendarEvent = async (accessToken, eventDetails) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        const event = await response.json();
        message.success("Synced with your calendar successfully");
      } else {
        const error = await response.json();
        console.error("Failed to create event:", error);
      }
    } catch (error) {
      console.error("Error creating calendar event:", error);
    }
  };

  // Helper functions
  const formatDateToYYYYMMDD = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

  const formatTimeToHHMM = (dateString) => {
    if (!dateString) return null; // Handle undefined/null values
    const date = new Date(dateString);
    if (isNaN(date)) return null; // Handle invalid dates
    const hours = String(date.getHours()).padStart(2, "0"); // Ensures 2 digits for hours
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Ensures 2 digits for minutes
    return `${hours}:${minutes}`; // Format as HH:mm
  };

  // Call the function with an access token and event details
  const syncGapiCalendar = (token, event) => {
    createCalendarEvent(token, event);
  };

  const handleUpdateTask = async (values) => {
    const categories = values.category;

    // Handle null, string, and array cases
    let commaSeparatedString = "";

    if (Array.isArray(categories)) {
      // If it's an array, join the elements with a comma
      commaSeparatedString = categories.join(",");
    } else if (typeof categories === "string") {
      // If it's already a string, use it as is
      commaSeparatedString = categories;
    } else if (!categories) {
      // If it's null or undefined, set it as an empty string
      commaSeparatedString = "";
    }
    // Format dates and times
    const startDate = formatDateToYYYYMMDD(values["start_date"]);

    const reminderDate = formatDateToYYYYMMDD(values["reminder_date"]);

    const startTime = formatTimeToHHMM(values["start_time"]);
    const endTime = formatTimeToHHMM(values["end_time"]);
    const reminderTime = formatTimeToHHMM(values["reminder_time"]);

    // Not functional in frontend ( Removed by demand )
    // const endDate = moment(values.end_date).format("YYYY-MM-DD");
    // const endTime = moment(values.end_time).format("HH:mm");

    const endDate = startDate; // Assuming end date is the same as start date

    const payload = {
      ...selectedTask,
      name: values.name,
      category: commaSeparatedString,
      notes: values.notes,
      start_date: startDate || null, // Default to null if the date is invalid
      end_date: endDate || null,
      start_time: startTime || null, // Default to null if the time is invalid
      end_time: endTime || null,
      reminder_date: reminderDate || null,
      reminder_time: reminderTime || null,
      remind_via: values.remindVia || "Email",
    };

    const googleToken = localStorage.getItem("Gp_TK");
    // console.log("Task payload", payload);

    if (googleToken) {
      // Check if the start and end date, time, and reminder fields are not null
      if (
        payload.start_date &&
        payload.end_date &&
        payload.start_time &&
        payload.end_time &&
        payload.reminder_date &&
        payload.reminder_time &&
        payload.remind_via
      ) {
        // Combine start date and time into a Date object for calculation
        const eventStartDateTime = new Date(
          `${payload.start_date}T${payload.start_time}:00`
        );
        const reminderDateTime = new Date(
          `${payload.reminder_date}T${payload.reminder_time}:00`
        );

        // Calculate the difference in minutes between the event start and reminder time
        const timeDifferenceInMs = eventStartDateTime - reminderDateTime;
        const timeDifferenceInMinutes = Math.floor(timeDifferenceInMs / 60000); // Convert milliseconds to minutes

        if (timeDifferenceInMinutes < 0) {
          message.error("Reminder time must be before the event start time.");
          return;
        }

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Build the event details object for Google Calendar sync
        const eventDetails = {
          summary: payload.name,
          description: payload.category,
          start: {
            dateTime: `${payload.start_date}T${payload.start_time}:00`, // Combine date and time for Google Calendar
            timeZone: userTimeZone, // Modify to your desired timezone
          },
          end: {
            dateTime: `${payload.end_date}T${payload.end_time}:00`,
            timeZone: userTimeZone,
          },
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: timeDifferenceInMinutes }, // User-defined email reminder
              { method: "popup", minutes: 10 }, // 10 minutes before for popup reminder
            ],
          },
        };

        // Sync with Google Calendar
        syncGapiCalendar(googleToken, eventDetails);
      }
      // Close the drawer after dispatching the action
    } else {
      message.info("Connect with Google to sync the event!");
    }

    try {
      await dispatch(updateTasks(payload, task.id));
      await dispatch(getTasks());
      message.success("Task updated successfully");
      // form.resetFields();
      onClose();
    } catch (error) {
      message.error("Something went wrong", error);
    }
  };

  useEffect(() => {
    if (task) {
      // console.log("selected task data ", task);
      const Initcategory = task.category;
      const categoriesArray =
        Initcategory && Initcategory.split(",").map((item) => item.trim());

      // Convert dates and times to moment objects
      const startDate = task.start_date
        ? moment(task.start_date, "YYYY-MM-DD")
        : null;
      const endDate = task.end_date
        ? moment(task.end_date, "YYYY-MM-DD")
        : null;
      const startTime = task.start_time
        ? moment(task.start_time, "HH:mm:ss")
        : null;
      const endTime = task.end_time ? moment(task.end_time, "HH:mm:ss") : null;
      const reminderDate = task.reminder_date
        ? moment(task.reminder_date, "YYYY-MM-DD")
        : null;
      const reminderTime = task.reminder_time
        ? moment(task.reminder_time, "HH:mm:ss")
        : null;

      form.setFieldsValue({
        name: task.name,
        category: categoriesArray,
        notes: task.notes,
        // Set other fields here as needed
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        reminder_date: reminderDate,
        reminder_time: reminderTime,
        remindVia: task.reminder_via, // Assuming this is a field in the form
      });
    }
  }, [task, form]);

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Edit Task"
      width={720}
      onClose={onClose}
      open={open}
      zIndex={1003}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <CloseOutlined onClick={onClose} />
        </Space>
      }
    >
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        // initialValues={{
        //   ...selectedTask,
        //   name: task.name || "",
        //   category: task.category || "",
        //   notes: task.notes || "",
        //   start_date: null,
        //   end_date: null,
        //   start_time: null,
        //   end_time: null,
        //   reminder_date: null,
        //   reminder_time: null,
        //   remindVia: null,
        // }}
        onFinish={handleUpdateTask}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Task Name"
              rules={[{ required: true, message: "Enter task name" }]}
            >
              <Input placeholder="Enter task name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              // rules={[{ required: true, message: "Enter the category" }]}
            >
              <Select
                mode="tags"
                size={size}
                placeholder="Please select"
                defaultValue={[]}
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
                options={options}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="notes"
              label="Notes"
              // rules={[{ required: true, message: "Please enter task notes!" }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Collapse defaultActiveKey={["1", "2"]}>
          <Collapse.Panel header="Sync Calendar" key="1">
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="start_date" label="Start date">
                  <DatePicker
                    placeholder="Choose start date"
                    style={{ width: "100%" }}
                    format="MM/DD/YYYY" // Format for month, day, and year
                  />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="end_date" label="End date">
                  <DatePicker
                    placeholder="Choose end date"
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item name="start_time" label="Start time">
                  <TimePicker
                    placeholder="Choose start time"
                    style={{ width: "100%" }}
                    format="HH:mm a"
                    use12Hours
                    renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                    onSelect={() =>
                      document.querySelector(".ant-picker").blur()
                    } // Closes picker on selection
                    onChange={(value) => console.log(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="end_time" label="End time">
                  <TimePicker
                    placeholder="Choose end time"
                    style={{ width: "100%" }}
                    format="HH:mm a"
                    use12Hours
                    renderExtraFooter={() => null}
                    onSelect={() =>
                      document.querySelector(".ant-picker").blur()
                    } // Closes picker on selection
                    onChange={(value) => console.log(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel header="Set Reminder" key="2">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="reminder_time" label="Reminder Time">
                  <TimePicker
                    use12Hours
                    placeholder="Choose Reminder Time"
                    style={{ width: "100%" }}
                    format="HH:mm a"
                    renderExtraFooter={() => null} // This removes the "OK" button by removing footer
                    onSelect={() =>
                      document.querySelector(".ant-picker").blur()
                    } // Closes picker on selection
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="reminder_date" label="Reminder date">
                  <DatePicker
                    use12Hours
                    placeholder="Choose Reminder date"
                    style={{ width: "100%" }}
                    format="MM/DD/YYYY" // Format for month, day, and year
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="remindVia"
                  label="Remind Via"
                  rules={[
                    {
                      message: "Please choose how to be reminded",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a Remind via"
                    // defaultValue={"Email"}
                  >
                    <Option value="SMS & Email" disabled>
                      SMS & Email
                    </Option>
                    <Option value="Email">Email</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
        <Flex className="mt-4" justify="flex-end" align="center">
          <Button onClick={onClose}>Cancel</Button>
          <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
            Save Changes
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default EditMyTaskDrawer;
