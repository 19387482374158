import React, { useState } from "react";
import config from "../../config";
import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  message,
  // Collapse,
  // TimePicker,
} from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import { getTasks } from "../../store/actions/tasks";
import left from "../../assets/figmaAssets/left.svg";
import { useDispatch } from "react-redux";
import { getContacts } from "../../store/actions/contacts";
import { getAdditionalInfo } from "../../store/actions/additionalinfo";
import { getAddress } from "../../store/actions/address";
import { getSpouse } from "../../store/actions/spouse";

const { Option } = Select;
// const { TextArea } = Input;

const MyContactsDrawer = ({ open, onClose }) => {
  const [defaultTabKey, setDefaultTabKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [contactId, setContactId] = useState(null);

  // primary details
  const [Firstname, setFirstname] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Ranking, setRanking] = useState("A");
  const [email, setEmail] = useState("");
  const [Homephone, setHomephone] = useState("");
  const [Officephone, setOfficephone] = useState("");
  const [Company, setCompany] = useState("");
  const [Dateofbirth, setDateofbirth] = useState("");
  const [Source, setSource] = useState("");
  //
  const [SpouseFirstname, setSpouseFirstname] = useState("");
  const [SpouseLastname, setSpouseLastname] = useState("");
  const [Spouseemail, setSpouseEmail] = useState("");
  const [Spousephone, setSpousephone] = useState("");
  const [SpouseDateofbirth, setSpouseDateofbirth] = useState("");
  const [Spouseanniversary, setSpouseAniversary] = useState("");

  const [AddressType, setAddressType] = useState("");
  const [AddressLineOne, setAddressLineOne] = useState("");
  const [AddressLineTwo, setAddressLineTwo] = useState("");
  const [Location, setLocation] = useState("");
  const [Zip, setZip] = useState("");

  const [ArrayAddresses, setAddresses] = useState([]);

  const [additionalSource, setAdditionalSource] = useState("");
  const [additionalTags, setAdditionalTags] = useState("");

  const [Status, setStatus] = useState("");
  const onChange = (key) => {
    // console.log(key);
    setDefaultTabKey(key);
  };

  const handlePrevious = () => {
    const tab = parseInt(defaultTabKey, 10);

    // Increment the integer
    const changeTo = tab - 1;

    // Update the state with the new value as a string
    setDefaultTabKey(changeTo.toString());
    console.log(defaultTabKey);
    console.log(changeTo);
  };

  const [countryCode, setCountryCode] = useState("+1"); // Default country code
  const [countryCode2, setCountryCode2] = useState("+1"); // Default country code
  const [countryCode3, setCountryCode3] = useState("+1"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number input value
  const { Option } = Select;

  const selectBefore = (
    <Select
      defaultValue={countryCode}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode(value)} // Update country code
    >
      <Option value="+1">+1</Option>
      {/* Add more country codes as needed */}
    </Select>
  );

  const selectBefore2 = (
    <Select
      defaultValue={countryCode}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode2(value)} // Update country code
    >
      <Option value="+1">+1</Option>

      {/* Add more country codes as needed */}
    </Select>
  );

  const selectBefore3 = (
    <Select
      defaultValue={countryCode}
      // style={{ color: "black" }}
      style={{ background: "transperant" }}
      onChange={(value) => setCountryCode3(value)} // Update country code
    >
      <Option value="+1">+1</Option>

      {/* Add more country codes as needed */}
    </Select>
  );

  const resetValues = () => {
    setFirstname("");
    setLastname("");
    setRanking("");
    setEmail("");
    setHomephone("");
    setOfficephone("");
    setCompany("");
    setDateofbirth("");
    setSource("");
    setSpouseFirstname("");
    setSpouseLastname("");
    setSpouseEmail("");
    setSpousephone("");
    setSpouseDateofbirth("");
    setSpouseAniversary("");
    setAddressType("");
    setAddressLineOne("");
    setAddressLineTwo("");
    setLocation("");
    setZip("");
    setAdditionalSource("");
    setAdditionalTags("");
  };

  const handleClose = () => {
    resetValues();
    form1.resetFields();
    form2.resetFields();
    form3.resetFields();
    form4.resetFields();

    setDefaultTabKey("1");
    formikPrimary.resetForm();
    formikSpouse.resetForm();
    formikAddress.resetForm();
    formikAdditional.resetForm();
    onClose();
  };

  const goNextTab = () => {
    console.log(defaultTabKey); // Logs the current tab key

    // Convert defaultTabKey from string to an integer
    const tab = parseInt(defaultTabKey, 10);

    // Increment the integer
    const changeTo = tab + 1;

    // Update the state with the new value as a string
    setDefaultTabKey(changeTo.toString());

    console.log(changeTo.toString()); // This will log the new value immediately
  };

  const createContact = async (accessToken, contactDetails) => {
    try {
      const response = await fetch(
        "https://people.googleapis.com/v1/people:createContact",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contactDetails),
        }
      );

      if (response.ok) {
        const contact = await response.json();
        message.success("Contact created successfully:", contact);
      } else {
        const error = await response.json();
        console.error("Failed to create contact:", error);
      }
    } catch (error) {
      console.error("Error creating contact:", error);
    }
  };

  const contactDetails = {
    names: [{ givenName: "John", familyName: "Doe" }],
    emailAddresses: [{ value: "johndoe@example.com" }],
    phoneNumbers: [{ value: "+1234567890" }],
    addresses: [
      {
        streetAddress: "1234 Main St",
        city: "Los Angeles",
        region: "CA",
        postalCode: "90001",
        country: "USA",
      },
    ],
  };

  // createContact('YOUR_ACCESS_TOKEN_HERE', contactDetails);

  const formikPrimary = useFormik({
    initialValues: {
      author: 3,
      firstName: "",
      lastName: "",
      ranking: Ranking,
      location: "",
      email: "",
      last_interaction: new Date().toISOString(),
      home_phone: "",
      office_phone: "",
      company: "",
      date_of_birth: "",
      // source: "Default",
      source: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        // const contactPayload = {
        //   author: 4,
        //   firstName: values.firstName,
        //   lastName: values.lastName,
        //   ranking: values.ranking,
        //   location: values.location,
        //   email: values.email,
        //   last_interaction: new Date().toISOString(),
        //   date_of_birth: values.date_of_birth,
        //   company: values.company,
        //   home_phone: values.home_phone,
        //   office_phone: values.office_phone,
        //   source: values.source,
        // };

        const finalRanking = values.ranking || Ranking;
        if (values.firstName) {
          const finalHomePhone =
          parseInt(values.home_phone)
              ? `${countryCode} ${values.home_phone}`
              : "";
          const finalofficePhone =
          parseInt(values.office_phone)
              ? `${countryCode2} ${values.office_phone}`
              : "";
          setFirstname(values.firstName);
          setLastname(values.lastName);
          setRanking(finalRanking);
          setLocation(values.location);
          setEmail(values.email);
          setDateofbirth(values.date_of_birth);
          setCompany(values.company);
          setHomephone(values.home_phone);
          setOfficephone(values.office_phone);
          setSource(values.source);
          setDefaultTabKey("2");
          // message.info("Continue adding details to create contact !");
        }
      } catch (error) {
        message.error("Fill details to create contact");
      }
    },
  });

  // Formik for Spouse Info

  const formikSpouse = useFormik({
    initialValues: {
      // author: 3,
      contact: 4,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      date_of_birth: "",
      anniversary: "",
    },

    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setSpouseFirstname(values.firstName);
        setSpouseLastname(values.lastName);
        setSpouseAniversary(values.anniversary);
        setSpouseDateofbirth(values.date_of_birth);
        setSpousephone(values.phone);
        setSpouseEmail(values.email);
        setDefaultTabKey("3");
        console.log(
          SpouseDateofbirth,
          SpouseFirstname,
          SpouseLastname,
          Spouseanniversary,
          Spouseemail,
          Spousephone
        );
      } catch (error) {
        message.error("Something went wrong while adding the sppuse details");
      }
    },
  });

  // Formik for Address
  // const formikAddress = useFormik({
  //   initialValues: {
  //     contact: 1,
  //     address_type: "",
  //     address_line1: "",
  //     address_line2: "",
  //     location: "",
  //     zip: "",
  //   },
  //   validationSchema: Yup.object({
  //     address_type: Yup.string().required("Please select the address type!"),
  //     address_line1: Yup.string().required("Please input address line 1!"),
  //     address_line2: Yup.string().required("Please input address line 2!"),
  //     location: Yup.string().required("Please input location"),
  //     zip: Yup.string().required("Please input zip!"),
  //   }),
  //   onSubmit: async (values) => {
  //     try {
  //       setAddressLineOne(values.address_line1);
  //       setAddressLineTwo(values.address_line2);
  //       setAddressType(values.address_type);
  //       setLocation(values.location);
  //       setZip(values.zip);
  //       setDefaultTabKey("4");
  //       console.log(AddressLineOne, AddressLineTwo, AddressType, Location, Zip);
  //     } catch (error) {
  //       message.error("Something went wrong");
  //     }
  //     // try {
  //     //   const idToken = localStorage.getItem("Afb"); // Example token retrieval

  //     //   // API request
  //     //   const response = await fetch(`${config.API_URL}/address/`, {
  //     //     method: "POST",
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //       Authorization: `Bearer ${idToken}`, // Sending token in headers
  //     //     },
  //     //     body: JSON.stringify(values),
  //     //   });

  //     //   if (response.ok) {
  //     //     toast.success("Primary details saved successfully!");
  //     //     setDefaultTabKey("4");
  //     //   } else {
  //     //     const errorData = await response.json();
  //     //     toast.error(
  //     //       `Submission failed: ${errorData.message || "Unknown error"}`
  //     //     );
  //     //   }
  //     // } catch (error) {
  //     //   toast.error("An error occurred: " + error.message);
  //     // }
  //   },
  // });

  // Formik for Address
  const formikAddress = useFormik({
    initialValues: {
      addresses: [
        {
          contact: contactId,
          // address_type: "home",
          address_line1: "",
          address_line2: "",
          location: "",
          zip: "",
        },
      ],
    },
    onSubmit: async (values) => {
      try {
        // Prepare payload
        const payload = {
          addresses: values.addresses,
        };
        console.log("Address ==>", payload);
        setAddresses(values.addresses);
        // message.info("Address saved!");
        // API call logic can go here
        setDefaultTabKey("4");
        // message.success("Addresses saved successfully!");
      } catch (error) {
        message.error("Something went wrong");
      }
    },
  });

  // Function to add a new address
  const addAddress = () => {
    formikAddress.setFieldValue("addresses", [
      ...formikAddress.values.addresses,
      {
        contact: contactId,
        address_type: "",
        address_line1: "",
        address_line2: "",
        location: "",
        zip: "",
      },
    ]);
  };

  // Function to remove an address
  const removeAddress = (index) => {
    const updatedAddresses = formikAddress.values.addresses.filter(
      (_, i) => i !== index
    );
    formikAddress.setFieldValue("addresses", updatedAddresses);
  };

  // Formik for Additional Info
  const handleCreateContact = async (tag, status) => {
    if (Firstname) {
      try {
        const idToken = localStorage.getItem("Afb"); // Example token retrieval
        const authId = localStorage.getItem("properly_UI");

        const finalHomePhone =
          parseInt(formikPrimary.values.home_phone)
              ? `${countryCode} ${formikPrimary.values.home_phone}`
              : "";
          const finalofficePhone =
          parseInt(formikPrimary.values.office_phone)
              ? `${countryCode2} ${formikPrimary.values.office_phone}`
              : "";

        // Create contactPayload with optional fields defaulting to blank if not provided
        const contactPayload = {
          author: authId,
          firstName: Firstname,
          lastName: Lastname || "", // Default to empty string
          ranking: Ranking || "A", // Default to empty string
          email: email || "", // Default to empty string
          last_interaction: new Date().toISOString(),
          date_of_birth: Dateofbirth || null, // Default to empty string
          company: Company || null,
          home_phone: finalHomePhone || null, // Ensure valid integer or null
          office_phone: finalofficePhone || null, // Ensure valid integer or null
          source: Source || null, // Ensure valid enum or null
        };

        // First API: Create the contact and retrieve contactId
        const responseContact = await fetch(`${config.API_URL}/contacts/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${idToken}`,
          },
          body: JSON.stringify(contactPayload),
        });

        if (!responseContact.ok) {
          message.error(
            "Something went wrong while creating contact. Try again!"
          );
          throw new Error("Failed to create contact.");
        }

        const responseData = await responseContact.json();
        const contactId = responseData.id; // Store the contactId
        setContactId(contactId); // Set contactId in the state for later use
        // console.log("Contact created successfully, ID: ", contactId);

        // Prepare spousePayload with blank values if fields are not provided
        const spousePayload = {
          contact: contactId,
          firstName: SpouseFirstname || "", // Default to empty string
          lastName: SpouseLastname || "", // Default to empty string
          email: Spouseemail || "", // Default to empty string
          phone: Spousephone || null, // Default to empty string
          date_of_birth: SpouseDateofbirth || null, // Default to empty string
          anniversary: Spouseanniversary || null, // Default to empty string
        };

        // Prepare updatedAddressPayload with blank values
        const updatedAddressPayload = {
          addresses:
            ArrayAddresses?.map((address) => ({
              ...address,
              contact: contactId, // Add contact ID to each address
              line1: address.line1 || "", // Default to empty string
              line2: address.line2 || "", // Default to empty string
              city: address.city || "", // Default to empty string
              state: address.state || "", // Default to empty string
              zip: address.zip || null, // Default to empty string
              country: address.country || "", // Default to empty string
            })) || [], // Default to empty array if no addresses
        };

        // Prepare additionalPayload
        const additionalPayload = {
          contact: contactId,
          tags: tag || "", // Default to empty string
          status: status || "", // Default to empty string
        };

        // Make API calls regardless of blank fields
        const [responseSpouse, responseAddress, responseAdditional] =
          await Promise.all([
            fetch(`${config.API_URL}/spouse/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(spousePayload),
            }),
            fetch(`${config.API_URL}/address/bulk-create/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(updatedAddressPayload),
            }),
            fetch(`${config.API_URL}/additionalinfo/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${idToken}`,
              },
              body: JSON.stringify(additionalPayload),
            }),
          ]);

        // Check all API responses for success
        if (responseSpouse.ok && responseAddress.ok && responseAdditional.ok) {
          toast.success("Contact and all details created successfully");
          dispatch(getContacts()); // Refresh contact list
          dispatch(getAdditionalInfo());
          dispatch(getAddress());
          dispatch(getSpouse());
          setLoading(false);
          handleClose();
        } else {
          throw new Error(
            "Failed to save spouse, address, or additional info."
          );
        }
      } catch (error) {
        // In case any API call fails, delete the contact
        if (contactId) {
          try {
            const idToken = localStorage.getItem("Afb");
            const responseDelete = await fetch(
              `${config.API_URL}/contacts/delete/${contactId}/`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${idToken}`,
                },
              }
            );

            if (responseDelete.ok) {
              console.log(
                `Contact with ID ${contactId} has been deleted successfully.`
              );
            } else {
              console.error(`Failed to delete contact with ID ${contactId}.`);
            }
          } catch (deleteError) {
            console.error(
              "Error during contact deletion:",
              deleteError.message
            );
          }
        }

        // Handle any other errors that occurred
        message.error(`Error: ${error.message}`);
        setLoading(false);
      }
    } else {
      message.error("Firstname is required to create a contact.");
      console.log("Firstname is required to create a contact.");
    }
  };

  const formikAdditional = useFormik({
    initialValues: {
      tags: [], // Change this to an empty array
      status: "Active",
    },
    // validationSchema: Yup.object({
    //   tags: Yup.array().min(1, "Please input at least one tag!"), // Update validation
    //   status: Yup.string().required("Please select the status!"),
    // }),
    onSubmit: async (values) => {
      const commaSeparatedString = values.tags ? values.tags.join(",") : ""; // Join array into a string

      try {
        // Set the values and log the previous and updated states
        setAdditionalTags(commaSeparatedString);
        setStatus(values.status);

        await handleCreateContact(commaSeparatedString, values.status);
      } catch (error) {
        message.error("Something went wrong");
      }
    },
  });

  const items = [
    {
      key: "1",
      label: "Primary details",
      children: (
        <Form
          layout="vertical"
          requiredMark={true}
          form={form1}
          onFinish={formikPrimary.handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                // validateStatus={formikPrimary.errors.firstName ? "error" : ""}
                // help={formikPrimary.errors.firstName}
                style={{ marginBottom: "16px" }}
                rules={[
                  {
                    required: true,
                    message: "Enter the first name",
                  },
                ]}
              >
                <Input
                  name="firstName"
                  placeholder="Please enter First Name"
                  value={formikPrimary.values.firstName}
                  onChange={(e) => {
                    const value = e.target.value;
                    formikPrimary.setFieldValue("firstName", value);
                    setFirstname(value); // Update state
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                validateStatus={formikPrimary.errors.lastName ? "error" : ""}
                help={formikPrimary.errors.lastName}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  name="lastName"
                  placeholder="Please enter Last Name"
                  value={formikPrimary.values.lastName}
                  onChange={(e) => {
                    const value = e.target.value;
                    formikPrimary.setFieldValue("lastName", value);
                    setLastname(value); // Update state
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Ranking"
                validateStatus={formikPrimary.errors.ranking ? "error" : ""}
                help={formikPrimary.errors.ranking}
                style={{ marginBottom: "16px" }}
              >
                <Select
                  name="ranking"
                  placeholder="Select Ranking"
                  value={formikPrimary.values.ranking || Ranking}
                  onChange={(value) => {
                    formikPrimary.setFieldValue("ranking", value);
                    setRanking(value); // Update state
                  }}
                >
                  <Option value="A">A</Option>
                  <Option value="B">B</Option>
                  <Option value="C">C</Option>
                  <Option value="D">D</Option>
                  <Option value="E">E</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                validateStatus={formikPrimary.errors.email ? "error" : ""}
                help={formikPrimary.errors.email}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  name="email"
                  type="email"
                  placeholder="Please enter email"
                  value={formikPrimary.values.email}
                  onChange={(e) => {
                    const value = e.target.value;
                    formikPrimary.setFieldValue("email", value);
                    setEmail(value); // Update state
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="home_phone"
                label="Home Phone Number"
                validateStatus={formikPrimary.errors.home_phone ? "error" : ""}
                help={formikPrimary.errors.home_phone}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  addonBefore={selectBefore}
                  placeholder="Enter Home Phone number"
                  type="text" // Keep it as text to allow flexibility
                  value={formikPrimary.values.home_phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikPrimary.setFieldValue("home_phone", value);
                      setHomephone(value); // Update state
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="office_phone"
                label="Office Phone Number"
                validateStatus={
                  formikPrimary.errors.office_phone ? "error" : ""
                }
                help={formikPrimary.errors.office_phone}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  addonBefore={selectBefore2}
                  placeholder="Enter Office Phone number"
                  type="text" // Keep it as text to allow flexibility
                  value={formikPrimary.values.office_phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikPrimary.setFieldValue("office_phone", value);
                      setOfficephone(value); // Update state
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Company"
                validateStatus={formikPrimary.errors.company ? "error" : ""}
                help={formikPrimary.errors.company}
                style={{ marginBottom: "16px" }}
              >
                <Input
                  placeholder="Please enter Company"
                  name="company"
                  value={formikPrimary.values.company}
                  onChange={(e) => {
                    const value = e.target.value;
                    formikPrimary.setFieldValue("company", value);
                    setCompany(value); // Update state
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="Dateofbirth"
                label="Date of Birth"
                validateStatus={
                  formikPrimary.errors.date_of_birth ? "error" : ""
                }
                help={formikPrimary.errors.date_of_birth}
                style={{ marginBottom: "16px" }}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={
                    formikPrimary.values.date_of_birth
                      ? moment(formikPrimary.values.date_of_birth, "YYYY-MM-DD") // Ensure the correct format is used
                      : null
                  }
                  onChange={(date, dateString) => {
                    // Use dateString directly, which is in the correct format already
                    formikPrimary.setFieldValue("date_of_birth", dateString);
                    setDateofbirth(dateString); // Update state
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="source"
                label="Source"
                validateStatus={formikPrimary.errors.source ? "error" : ""}
                help={formikPrimary.errors.source}
                style={{ marginBottom: "16px" }}
              >
                <Select
                  name="source"
                  placeholder="Select source"
                  value={formikPrimary.values.source}
                  onChange={(value) => {
                    formikPrimary.setFieldValue("source", value);
                    setSource(value); // Update state
                  }}
                >
                  <Option value="Client Referral">Client Referral</Option>
                  <Option value="Website">Website</Option>
                  <Option value="Social Media">Social Media</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Flex className="mt-4" justify="flex-end" align="center">
            <Button onClick={() => handleClose()}>Cancel</Button>

            <Button
              // htmlType="submit"
              htmlType="submit"
              onClick={() => formikPrimary.handleSubmit}
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Next
            </Button>
          </Flex>
          {Firstname !== "" && (
            <Flex className="mt-4" justify="flex-end" align="center">
              {/* <Button onClick={() => handleClose()}>Cancel</Button> */}

              <Button
                // htmlType="submit"
                htmlType="submit"
                onClick={() => formikAdditional.handleSubmit()}
                style={{ marginLeft: 10 }}
              >
                Create Contact
              </Button>
            </Flex>
          )}
        </Form>
      ),
    },
    {
      key: "2",
      label: "Spouse Info",
      children: (
        <Form
          layout="vertical"
          onFinish={formikSpouse.handleSubmit}
          form={form2}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"firstName"}
                label="First Name"
                validateStatus={formikSpouse.errors.firstName ? "error" : ""}
                help={formikSpouse.errors.firstName}
                style={{ width: "100%" }}
              >
                <Input
                  name="firstName"
                  placeholder="Please enter First Name"
                  value={formikSpouse.values.firstName}
                  onChange={(e) => {
                    formikSpouse.handleChange(e);
                    setSpouseFirstname(e.target.value); // Additional state
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"lastName"}
                label="Last Name"
                validateStatus={formikSpouse.errors.lastName ? "error" : ""}
                help={formikSpouse.errors.lastName}
              >
                <Input
                  name="lastName"
                  placeholder="Please enter Last Name"
                  value={formikSpouse.values.lastName}
                  onChange={(e) => {
                    formikSpouse.handleChange(e);
                    setSpouseLastname(e.target.value); // Additional state
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"email"}
                label="Email"
                // validateStatus={formikSpouse.errors.email ? "error" : ""}
                // help={formikSpouse.errors.email}
              >
                <Input
                  name="email"
                  type="email"
                  placeholder="Please enter Email"
                  value={formikSpouse.values.email}
                  onChange={(e) => {
                    formikSpouse.handleChange(e);
                    setSpouseEmail(e.target.value); // Additional state
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"phone"}
                label="Phone Number"
                validateStatus={formikSpouse.errors.phone ? "error" : ""}
                help={formikSpouse.errors.phone}
              >
                <Input
                  placeholder="Enter Home Phone number"
                  type="text"
                  value={formikSpouse.values.phone}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers
                    if (/^\d*$/.test(value) || value === "") {
                      formikSpouse.setFieldValue("phone", value);
                      setSpousephone(value); // Additional state
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent entering anything that's not a number
                    if (!/\d/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date of birth"
                name="date_of_birth"
                validateStatus={
                  formikSpouse.errors.date_of_birth ? "error" : ""
                }
                help={formikSpouse.errors.date_of_birth}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Please enter Date of birth"
                  value={
                    formikSpouse.values.date_of_birth
                      ? moment(formikSpouse.values.date_of_birth, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(date, dateString) => {
                    formikSpouse.setFieldValue("date_of_birth", dateString);
                    setSpouseDateofbirth(dateString); // Additional state
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Anniversary"
                name="anniversary"
                validateStatus={formikSpouse.errors.anniversary ? "error" : ""}
                help={formikSpouse.errors.anniversary}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Please enter Anniversary"
                  value={
                    formikSpouse.values.anniversary
                      ? moment(formikSpouse.values.anniversary, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(date, dateString) => {
                    formikSpouse.setFieldValue("anniversary", dateString);
                    setSpouseAniversary(dateString); // Additional state
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>

          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={() => handlePrevious()}>Previous</Button>
            <Button
              style={{ marginLeft: 10 }}
              htmlType="submit"
              type="primary"
              onClick={() => formikSpouse.submitForm()}
            >
              Next
            </Button>
          </div>
          {Firstname !== "" && (
            <Flex className="mt-4" justify="flex-end" align="center">
              {/* <Button onClick={() => handleClose()}>Cancel</Button> */}

              <Button
                // htmlType="submit"
                htmlType="submit"
                onClick={() => formikAdditional.handleSubmit()}
                style={{ marginLeft: 10 }}
              >
                Create Contact
              </Button>
            </Flex>
          )}
        </Form>
      ),
    },
    {
      key: "3",
      label: "Address",
      children: (
        <Form layout="vertical" onSubmit={formikAddress.handleSubmit}>
          {formikAddress.values.addresses.map((address, index) => (
            <div key={index} style={{ marginTop: "15px" }}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Type"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_type
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_type
                        ? formikAddress.errors.addresses[index].address_type
                        : ""
                    }
                  >
                    <Select
                      placeholder="Select Address Type"
                      value={address.address_type}
                      onChange={(value) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_type = value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                    >
                      <Option value="home">Home</Option>
                      <Option value="office">Office</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Line 1"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line1
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line1
                        ? formikAddress.errors.addresses[index].address_line1
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter address line 1"
                      value={address.address_line1}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_line1 = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Address Line 2"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line2
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.address_line2
                        ? formikAddress.errors.addresses[index].address_line2
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter address line 2"
                      value={address.address_line2}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].address_line2 = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Location"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.location
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.location
                        ? formikAddress.errors.addresses[index].location
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter State"
                      value={address.location}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].location = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Zip"
                    validateStatus={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.zip
                        ? "error"
                        : ""
                    }
                    help={
                      formikAddress.touched.addresses &&
                      formikAddress.errors.addresses &&
                      formikAddress.errors.addresses[index]?.zip
                        ? formikAddress.errors.addresses[index].zip
                        : ""
                    }
                  >
                    <Input
                      placeholder="Enter Zip code"
                      type="number"
                      value={address.zip}
                      onChange={(e) => {
                        const updatedAddresses = [
                          ...formikAddress.values.addresses,
                        ];
                        updatedAddresses[index].zip = e.target.value;
                        formikAddress.setFieldValue(
                          "addresses",
                          updatedAddresses
                        );
                      }}
                      onBlur={formikAddress.handleBlur}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="danger"
                style={{ color: "#1A4866" }}
                onClick={() => removeAddress(index)}
              >
                Remove Address
              </Button>
            </div>
          ))}

          <Flex
            className="mt-4"
            justify="space-between"
            align="center"
            style={{ width: "100%" }}
          >
            <Button
              type="secondary"
              onClick={addAddress}
              style={{ marginBottom: 16, color: "#1A4866" }}
            >
              +Add more
            </Button>

            <div>
              <Button onClick={handlePrevious}>Previous</Button>
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                onClick={formikAddress.handleSubmit}
              >
                Next
              </Button>
            </div>
          </Flex>
          {Firstname !== "" && (
            <Flex className="mt-4" justify="flex-end" align="center">
              {/* <Button onClick={() => handleClose()}>Cancel</Button> */}

              <Button
                // htmlType="submit"
                htmlType="submit"
                onClick={() => formikAdditional.handleSubmit()}
                style={{ marginLeft: 10 }}
              >
                Create Contact
              </Button>
            </Flex>
          )}
        </Form>
      ),
    },
    {
      key: "4",
      label: "Additional Info",
      children: (
        <Form
          layout="vertical"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            formikAdditional.handleSubmit(); // Trigger Formik's submit handler
          }}
          form={form4}
          noValidate
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="tags"
                label="Tags"
                validateStatus={formikAdditional.errors.tags ? "error" : ""}
                help={formikAdditional.errors.tags}
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the Tags",
                //   },
                // ]}
              >
                <Select
                  mode="tags"
                  placeholder="Select Tags"
                  name="tags"
                  value={formikAdditional.values.tags} // Keep it as an array
                  onChange={(value) =>
                    formikAdditional.setFieldValue("tags", value)
                  }
                >
                  {/* <Option value="default">Default</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                // name="status"
                label="Status"
                // rules={[
                //   {
                //     required: true,
                //     message: "Enter the Status",
                //   },
                // ]}
              >
                <Select
                  name="status"
                  placeholder="Select Status"
                  value={formikAdditional.values.status}
                  onChange={(value) =>
                    formikAdditional.setFieldValue("status", value)
                  }
                >
                  <Option value="Active">Active</Option>
                  <Option value="Prospective">Prospective</Option>
                  <Option value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Flex className="mt-4" justify="flex-end" align="center">
            <Button onClick={() => handlePrevious()}>Previous</Button>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              loading={loading}
              onClick={() => formikAdditional.handleSubmit()}
            >
              Create Contact
            </Button>
          </Flex>
        </Form>
      ),
    },
  ];

  const handleTabClick = (key) => {
    console.log(`Tab with key ${key} clicked`);
    // Add custom logic here based on the clicked tab key

    if (key == 1) {
      console.log("Im here 1");
    }

    if (key == 2) {
      console.log("Im here 2");
      formikPrimary.handleSubmit();
    }

    if (key == 3) {
      console.log("Im here 3");
      formikSpouse.handleSubmit();
    }

    if (key == 4) {
      console.log("Im here 4");
      formikAddress.handleSubmit();
    }
  };

  return (
    <Drawer
      closeIcon={<img src={left} />}
      title="Create New Contact"
      width={720}
      onClose={() => handleClose()}
      open={open}
      zIndex={1003}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <Space>
          <CloseOutlined onClick={() => handleClose()} />
        </Space>
      }
    >
      <Tabs
        // defaultActiveKey={defaultTabKey}
        onTabClick={handleTabClick}
        activeKey={defaultTabKey}
        items={items}
        onChange={onChange}
      />
    </Drawer>
  );
};

export default MyContactsDrawer;
