import React, { useEffect, useState } from "react";
// import RevenueByLocation from "./RevenueByLocation";
// import LatestTransation from "./LatestTransation";

import { Row, Col, Container } from "reactstrap";
import {
  Button,
  Card,
  Divider,
  Empty,
  Flex,
  Modal,
  Skeleton,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import {
  AiOutlineCheckCircle,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteTask,
  getTasks,
  updatePendingTask,
  updateTasks,
} from "../../store/actions/tasks";
import MyTaskDrawer from "../Scheduling/MyTaskDrawer";
import EditMyTaskDrawer from "../Scheduling/EditMyTaskDrawer";
import { getNote } from "../../store/actions/notes";
import moment from "moment";
import MyNotesDrawer from "../Notes/MyNotesDrawer";
import { getContacts } from "../../store/actions/contacts";
import MyContactsDrawer from "../Contacts/MyContactsDrawer";
import tick from "../../assets/figmaAssets/tick.svg";

import InfiniteScroll from "react-infinite-scroll-component";
import List from "rc-virtual-list";
import edit1 from "../../assets/figmaAssets/edit.svg";
import delete1 from "../../assets/figmaAssets/delete.svg";
import completed from "../../assets/figmaAssets/completed.svg";
import { getAdditionalInfo } from "../../store/actions/additionalinfo";
import { getAddress } from "../../store/actions/address";
import { getSpouse } from "../../store/actions/spouse";

const Dashboard = () => {
  document.title = "Dashboard | ProperlyAI";
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notesDrawer, setNotesDrawerOpen] = useState(false);
  const [contactsDrawer, setContactsDrawer] = useState(false);
  const [isNotification, setIsNotification] = useState(false);

  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.list);
  const notes = useSelector((state) => state.notes.list);
  const contacts = useSelector((state) => state.contacts.list);
  const Spouse = useSelector((state) => state.spouse.list);

  // const emails = useSelector((state) => state.data.emails);
  const loading = useSelector((state) => state.loading);
  const [approvedTask, setApprovedTask] = useState(false);
  const [isCompletd, setIsCompleted] = useState(false);

  const showModal = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const [loadingData, setLoadingData] = useState(false);
  // const [data, setData] = useState([]);
  const googleToken = localStorage.getItem("Gp_TK");
  const unreadMails = localStorage.getItem("Ur_GPt");

  const today = moment().format("YYYY-MM-DD");

  const loadMoreData = () => {
    // if (loadingData) {
    //   return;
    // }
    // setLoadingData(true);
    // fetch(
    //   "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    // )
    //   .then((res) => res.json())
    //   .then((body) => {
    //     setData([...data, ...body.results]);
    //     setLoadingData(false);
    //   })
    //   .catch(() => {
    //     setLoadingData(false);
    //   });
    return;
  };
  // useEffect(() => {
  //   loadMoreData();
  // }, []);

  const handleApprovedTask = async (record) => {
    setSelectedTask(record);
    setApprovedTask(true);
  };

  const handleOk = async () => {
    if (selectedTask) {
      await dispatch(deleteTask(selectedTask.id));
      await dispatch(getTasks());

      setIsModalOpen(false);
    }
  };

  const handleUpdateTask = async () => {
    await dispatch(updatePendingTask(selectedTask.id));
  };

  const handleCompletedTask = async (data) => {
    try {
      if (data.status == "Completed") {
        const payload = {
          status: "Pending",
        };
        await dispatch(updateTasks(payload, data.id));

        message.success("Tasks status changed !");

        setIsCompleted(false);
      }

      if (data.status == "Pending") {
        const payload = {
          status: "Completed",
        };
        await dispatch(updateTasks(payload, data.id));
        message.success("Tasks completed !");
        setIsCompleted(true);
      }

      if (data.status == "Overdue") {
        const payload = {
          status: "Completed",
        };
        await dispatch(updateTasks(payload, data.id));
        message.success("Tasks completed !");
        setIsCompleted(true);
      }

      console.log(data);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelApprovedTask = () => {
    setApprovedTask(false);
  };

  const handleEditClick = (record) => {
    setSelectedTask(record);
    setEditDrawerOpen(true);
  };

  const handleDeleteClick = async (record) => {
    await dispatch(deleteTask(record.id));
    await dispatch(getTasks());
  };

  useEffect(() => {
    const fetchTasks = async () => {
      await dispatch(getTasks());
      await dispatch(getNote());
      await dispatch(getContacts());
      dispatch(getAdditionalInfo());
      dispatch(getAddress());
      dispatch(getSpouse());
    };
    fetchTasks();
  }, [dispatch]);

  const handleTickClick = (record) => {
    handleCompletedTask(record);
  };

  const handleCompletedTickClick = (record) => {
    handleCompletedTask(record);
  };

  // const limitedNotes = notes.slice(0, 4);

  // Merging data
  const newDataNotes = notes.map((record) => {
    const additionalRecord = contacts.find(
      (item) => item.id === record.contactId
    );

    return {
      ...record,
      firstNameAssignee: additionalRecord
        ? additionalRecord.firstName
        : "Unknown",
    };
  });

  const safeNotes = Array.isArray(newDataNotes) ? newDataNotes : [];

  const safeLimitedNotes = safeNotes
    .slice(0, 4)
    .filter(
      (note) =>
        note && note.title && note.content && note.author && note.created_at
    );

  const safeConatacts = Array.isArray(contacts) ? contacts : [];

  const safeLimitedContacts = safeConatacts
    .slice(0, 4)
    .filter(
      (contacts) => contacts && contacts.firstName && contacts.last_interaction
    );

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span style={{ textDecoration: "underline" }}>{text}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const color = text === "Pending" ? "gold" : "green";
        return (
          <Tag
            style={{
              borderRadius: "2vh",
              border: "none",
              fontWeight: "bold",
              padding: "5px 15px",
              fontSize: "13px",
            }}
            color={color}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => {
        // Split the text into an array by commas and trim whitespace
        const categories = text
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean);
        const primaryCategory = categories[0]; // First category
        const additionalCount = categories.length - 1; // Count of additional categories

        const color = primaryCategory === "Follow-up" ? "#F3F3F3" : "#F3F3F3"; // You can customize this logic as needed

        return (
          <span>
            <Tag
              style={{
                borderRadius: "2vh",
                color: "black",
                padding: "5px 15px",
                fontSize: "13px",
              }}
              color={color}
            >
              {primaryCategory}
            </Tag>
            {additionalCount > 0 && (
              <Tag
                style={{
                  borderRadius: "2vh",
                  color: "black",
                  padding: "5px 15px",
                  fontSize: "13px",
                }}
                color={color}
              >
                +{additionalCount}
              </Tag>
            )}
          </span>
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => {
        return (
          <span
            style={{
              color: "black",
              fontSize: "13px",
            }}
          >
            {text.length > 48 ? `${text.slice(0, 48)}...` : text}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <img
            src={delete1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleDeleteClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#ff4d4f",
            }}
          />

          <img
            src={edit1}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling to row click
              handleEditClick(record);
            }}
            style={{
              cursor: "pointer",
              marginRight: 22,
              fontSize: "20px",
              color: "#1890ff",
            }}
          />
          {/* <AiOutlineCheckCircle /> */}
          {record.status == "Completed" ? (
            <img
              src={completed}
              alt="tick"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling to row click
                handleCompletedTickClick(record);
              }}
            />
          ) : (
            <img
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling to row click
                handleCompletedTickClick(record);
              }}
              src={tick}
              alt="tick"
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      ),
    },
  ];

  const data = [
    {
      src: tick,
      alt: "tick",
      notification: ` You have ${
        unreadMails ? unreadMails : ""
      } unread emails.`,
      button: "check now",
      redirectTo: "/contacts",
    },
  ];

  // Check for birthdays and anniversaries in contacts
  contacts.forEach((contact) => {
    // Check if it's the contact's birthday
    if (contact.date_of_birth === today) {
      data.push({
        src: tick,
        alt: "birthday",
        notification: `Consider wishing ${contact.firstName} a happy birthday!`,
        button: "check now",
        redirectTo: `/contacts/`, // Redirect to the contact's page
      });
    }
  });

  // Check for birthdays and anniversaries in spouses
  Spouse.forEach((spouseItem) => {
    // Check if it's the spouse's birthday
    // if (spouseItem.date_of_birth === today) {
    //   data.push({
    //     src: tick,
    //     alt: "birthday",
    //     notification: `Consider wishing ${spouseItem.firstName} a happy birthday!`,
    //     button: "check now",
    //     redirectTo: `/contacts/`, // Redirect to the associated contact's page
    //   });
    // }

    // Check if it's the couple's anniversary
    if (spouseItem.anniversary === today) {
      // Find the contact associated with the spouse
      const associatedContact = contacts.find(
        (contact) => contact.id === spouseItem.contact
      );

      if (associatedContact) {
        data.push({
          src: tick,
          alt: "anniversary",
          notification: `Consider wishing ${associatedContact.firstName} and ${spouseItem.firstName} a happy anniversary!`,
          button: "check now",
          redirectTo: `/contacts`, // Redirect to the contact's page
        });
      }
    }
  });

  const contactcolumns = [
    {
      title: "Contact name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => (
        <span style={{ textDecoration: "underline" }}>{text}</span>
      ),
    },
    {
      title: "Last modified date",
      dataIndex: "last_interaction",
      key: "last_interaction",
      render: (last_interaction) => (
        <span>{moment(last_interaction).format("MM.DD.YYYY")}</span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {loading ? (
            <div style={{ textAlign: "center", padding: "50px 0" }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Card
                title="Notifications"
                bordered={false}
                style={{ width: "auto" }}
              >
                {!googleToken ? (
                  <>
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={
                        <Typography.Text>
                          Sync your <a href="/settings">Google account</a> with
                          ProperlyAI to start receiving notifications
                        </Typography.Text>
                      }
                    >
                      <Button type="primary" href="/settings">
                        Connect Google
                      </Button>
                    </Empty>
                  </>
                ) : (
                  <>
                    {" "}
                    {isNotification ? null : (
                      <div
                        className="notificationBody custom-scrollbar"
                        style={{ maxHeight: "108px", overflowY: "overlay" }}
                      >
                        {/* <List data={data} itemHeight={20} height={50}> */}
                        {data.map((item) => {
                          return (
                            <p>
                              <img
                                alt={item.alt}
                                src={item.src}
                                style={{ marginRight: "4px" }}
                              />{" "}
                              {item.notification}{" "}
                              <a
                                href={item.redirectTo}
                                style={{ color: "#20A0EC" }}
                              >
                                &nbsp;{item.button} <ArrowRightOutlined />
                              </a>
                            </p>
                          );
                        })}
                        {/* </List> */}
                      </div>
                    )}
                  </>
                )}
              </Card>

              <Card
                title={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Upcoming Tasks</span>
                    {/* <a href="/dashboard"> */}
                    <span
                      style={{ cursor: "pointer", color: "#20A0EC" }}
                      onClick={() => setDrawerOpen(true)}
                    >
                      <GoPlus /> Create Tasks
                    </span>
                    {/* </a> */}
                  </div>
                }
                bordered={false}
                style={{ width: "auto", marginTop: 10 }}
              >
                <Table
                  bordered
                  dataSource={tasks}
                  columns={columns}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        // Call a function and pass the record value
                        handleEditClick(record);
                      },
                    };
                  }}
                />
              </Card>
              <Row className="mt-4">
                <Col>
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Recent Contacts</span>
                        <span
                          style={{
                            color: "rgba(32, 160, 236, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() => setContactsDrawer(true)}
                        >
                          <GoPlus /> Add Contact
                        </span>
                      </div>
                    }
                    bordered={false}
                    style={{ width: "auto", marginTop: 10 }}
                  >
                    <Table
                      bordered
                      dataSource={safeLimitedContacts}
                      columns={contactcolumns}
                    />
                  </Card>
                </Col>
                <Col>
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>Recent Notes</span>
                        <span
                          style={{
                            color: "rgba(32, 160, 236, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() => setNotesDrawerOpen(true)}
                        >
                          <GoPlus /> Create Notes
                        </span>
                      </div>
                    }
                    bordered={false}
                    style={{ width: "auto", marginTop: 10 }}
                  >
                    {/* <Table bordered dataSource={datasource} columns={columns} /> */}
                    <div
                      className="custom-scrollbar notesDashboardData"
                      style={{
                        height: "223px",
                        overflowY: "overlay",
                        overflowX: "clip",
                        paddingRight: "17px",
                      }}
                    >
                      {safeLimitedNotes.length > 0 ? (
                        safeLimitedNotes.map((note, index) => (
                          <Row
                            key={index}
                            gutter={16}
                            style={{ marginBottom: "16px" }}
                          >
                            <Col span={24}>
                              <Card
                                className="mb-2"
                                style={{ background: "#F9FAFB" }}
                              >
                                <Flex
                                  justify="space-between"
                                  align="flex-start"
                                  style={{
                                    fontWeight: "medium",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <div>{note.title}</div>
                                  <div>
                                    <Tag
                                      style={{
                                        borderRadius: "2vh",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: "5px 12px",
                                        background: "#F3F3F3",
                                      }}
                                    >
                                      {note.firstNameAssignee}
                                    </Tag>
                                    <Tag
                                      style={{
                                        borderRadius: "2vh",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        padding: "5px 12px",
                                        background: "#F3F3F3",
                                      }}
                                    >
                                      {moment(note.created_at).format(
                                        "MM.DD.YYYY"
                                      )}
                                    </Tag>
                                  </div>
                                </Flex>
                                <div className="mt-2">
                                  {note.content.length > 48
                                    ? `${note.content.slice(0, 48)}...`
                                    : note.content}
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          {/* <img src={tick} alt="No Data" style={{ width: "150px" }} />
    <p>No Data</p>
     */}
                          <div className="emptyDiv">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        </div>
                      )}{" "}
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
      <MyNotesDrawer
        open={notesDrawer}
        onClose={() => setNotesDrawerOpen(false)}
      />
      <MyTaskDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <MyContactsDrawer
        open={contactsDrawer}
        onClose={() => setContactsDrawer(false)}
      />
      <EditMyTaskDrawer
        open={editDrawerOpen}
        selectedTask={selectedTask}
        onClose={() => setEditDrawerOpen(false)}
      />

      <Modal
        style={{ height: 400, width: 400 }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={handleOk}>
            Delete
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Delete Task</h3>
        <div>
          Are you sure you want to delete this task? This cannot be undone.
        </div>
      </Modal>

      <Modal
        style={{ height: 400, width: 400 }}
        open={approvedTask}
        onOk={handleUpdateTask}
        onCancel={handleCancelApprovedTask}
        footer={[
          <Button key="cancel" onClick={handleCancelApprovedTask}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={handleUpdateTask}>
            Approved
          </Button>,
        ]}
      >
        <h3 style={{ marginTop: 20 }}>Approved Task</h3>
        <div>
          Are you sure you want to approved the task? This cannot be undone.
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Dashboard;
