import { message } from "antd";

// Function to fetch emails filtered by a specific email address
export const getEmailsForContact = async (contactEmail, type) => {
  try {
    const accessToken = localStorage.getItem("Gp_TK");
    if (!accessToken) throw new Error("Google Access Token not found.");

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);

    // Construct Gmail API query
    let query = "";
    if (type === "received") {
      query = `from:${contactEmail}`; // Fetch emails where the contact is the sender
    } else if (type === "sent") {
      query = `to:${contactEmail}`; // Fetch emails where the contact is the recipient
    }

    // First, fetch message IDs
    const GMAIL_API_URL = `https://gmail.googleapis.com/gmail/v1/users/me/messages?q=${query}`;
    const response = await fetch(GMAIL_API_URL, { method: "GET", headers });
    const data = await response.json();

    if (data && data.messages) {
      // Now fetch the details for each message using the message ID
      const emailDetails = await Promise.all(
        data.messages.map(async (message) => {
          // Fetch individual message content by its ID
          const messageResponse = await fetch(
            `https://gmail.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
            { method: "GET", headers }
          );
          const messageData = await messageResponse.json();

          // Extract specific email fields
          const email = extractEmailDetails(messageData);
          return email;
        })
      );
      // console.log("=>", emailDetails);
      return emailDetails;
    } else {
      return []; // No emails found
    }
  } catch (error) {
    console.error("Error fetching emails:", error);
    message.error(error ? error : "Something went wrong");
  }
};

// Function to extract specific fields (subject, date, from/to, body, attachments)
const extractEmailDetails = (messageData) => {
  // Extract headers
  const headers = messageData.payload.headers;

  const subject =
    headers.find((header) => header.name === "Subject")?.value || "No Subject";
  const date =
    headers.find((header) => header.name === "Date")?.value || "No Date";
  const from =
    headers.find((header) => header.name === "From")?.value || "No Sender";
  const to =
    headers.find((header) => header.name === "To")?.value || "No Recipient";

  // Extract body (text and HTML parts)
  let body = "";
  if (messageData.payload?.body?.data) {
    // Plain text body
    body = decodeBase64(messageData.payload.body.data);
  } else if (messageData.payload.parts) {
    // Body can be nested in 'parts' if it's a multipart email (with attachments, etc.)
    messageData.payload.parts.forEach((part) => {
      if (part.mimeType === "text/plain" && part.body?.data) {
        body = decodeBase64(part.body.data);
      } else if (part.mimeType === "text/html" && part.body?.data) {
        body = decodeBase64(part.body.data); // You can handle HTML as needed
      }
    });
  }

  // Extract attachments (if any)
  const attachments = [];
  if (messageData.payload.parts) {
    messageData.payload.parts.forEach((part) => {
      if (part.filename && part.body && part.body.attachmentId) {
        // It's an attachment
        attachments.push({
          filename: part.filename,
          mimeType: part.mimeType,
          attachmentId: part.body.attachmentId,
        });
      }
    });
  }

  return {
    subject,
    date,
    from: from, // Will return 'from' or 'to' based on the toggle
    to: to,
    body,
    attachments,
  };
};

// Helper function to decode base64 Gmail body content
const decodeBase64 = (encodedData) => {
  const decodedStr = atob(encodedData.replace(/-/g, "+").replace(/_/g, "/"));
  return decodeURIComponent(escape(decodedStr));
};
