import axios from "../../axios";
import config from "../../config";
import { ADD_CONTACTS, GET_CONTACTS } from "../types";
import { setAlert } from "./alert";

export const addContacts = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");
    const res = await axios.post(`${config.API_URL}/contacts/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: ADD_CONTACTS, payload: res.data });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getContacts = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/contacts/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // console.log("contacts action data:", res.data);
    res.data.reverse();
    await dispatch({ type: GET_CONTACTS, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

// export const getCompany = (id) => async (dispatch) => {
//     try {
//         const res = await axios.get(`company/${id}`);
//         if (res.data.code === 1) {
//             dispatch({type: GET_COMPANY, payload: res.data.data});
//             dispatch(setAlert(res.data.message, 'success'));
//         } else {
//             dispatch(setAlert(res.data.message, "warning"));
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };

// export const clearSingleCompany = () => async (dispatch) => {
//     try {
//         dispatch({type: GET_COMPANY, payload: ''});
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };

// export const updateCompany = (formData) => async (dispatch) => {
//     try {
//         const res = await axios.post("company/update", formData);
//         if (res.data.code === 1) {
//             dispatch({type: UPDATE_COMPANY, payload: res.data.data});
//             dispatch(setAlert(res.data.message, 'success'));
//         } else {
//             dispatch(setAlert(res.data.message, "warning"));
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };

// export const deleteCompany = (id) => async (dispatch) => {
//     try {
//         if (!id) {
//             dispatch(setAlert('Please select any record', 'warning'));
//             return false;
//         }
//         const res = await axios.get(`company/delete/${id}`);
//         if (res.data.code === 1) {
//             dispatch({type: DELETE_COMPANY, payload: {id}});
//             dispatch(setAlert(res.data.message, 'success'));
//         } else {
//             dispatch(setAlert(res.data.message, "warning"));
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, 'danger'));
//     }
// };
