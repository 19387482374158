import { message } from "antd";
import axios from "../../axios";
import config from "../../config";
import {
  ADD_MEETING,
  DELETE_MEETING,
  GET_MEETING,
  UPDATE_MEETING,
  update_Pending_Meeting,
} from "../types";
import { setAlert } from "./alert";

export const addMeeting = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("Afb");

    const res = await axios.post(`${config.API_URL}/meetings/`, formData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    dispatch({ type: ADD_MEETING, payload: res.data });
    // message.success("Meeting created!");
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    // message.error("Something went wrong while creating meeting");
  }
};

export const getMeetings = () => async (dispatch) => {
  try {
    // Retrieve the token from localStorage or any other storage
    const token = localStorage.getItem("Afb");

    const res = await axios.get(`${config.API_URL}/meetings/`, {
      headers: {
        Authorization: `${token}`,
      },
    });

    // Make the API request with the token
    // const res = await axios.get("contacts", config);
    console.log("getMeetings data:", res.data);
    res.data.reverse();
    await dispatch({ type: GET_MEETING, payload: res.data });
  } catch (err) {
    // Dispatch an alert if there's an error
    await dispatch(setAlert(err.message, "danger"));
  }
};

export const updateMeeting = (id, formData) => async (dispatch) => {
  try {
    const res = await axios.patch(`meetings/${id}/`, formData);
    dispatch({ type: UPDATE_MEETING, payload: res.data });

    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deleteMeeting = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }

    const res = await axios.delete(`meetings/${id}/`);
    dispatch({ type: DELETE_MEETING, payload: { id } });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updatePendingMeeting = (id) => async (dispatch) => {
  try {
    if (!id) {
      dispatch(setAlert("Please select any record", "warning"));
      return false;
    }
    const res = await axios.patch(`meetings/${id}`);
    dispatch({ type: update_Pending_Meeting, payload: { id } });
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
